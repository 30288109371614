/**
 * false == '' || undefined || null || 0
 * true  == 167 || 'string' || {} || []
 */
export const toBool = value => !!value;
export const negate = f => (...args) => !f(...args);
export const empty = value => value === undefined || value === null;
export const notEmpty = negate(empty);

export const emptyPlusEmptyString = value =>
    value === undefined || value === null || value === '';
export const toBoolAcceptZero = value => negate(emptyPlusEmptyString)(value);

export const equals = x => y => x === y;
export const isZero = equals(0);

export const lt = x => y => x < y;
export const lte = x => y => x <= y;
export const isPositive = lte(0);

export const gt = x => y => x > y;
export const gte = x => y => x >= y;
export const isNegative = gt(0);

export const between = (lower, upper) => x => x >= lower && x <= upper;

const matchingBooleans = falseCase => (...bools) => {
    for (let i = 0; i < bools.length; i++) {
        if (toBool(bools[i]) === falseCase) {
            return false;
        }
    }
    return true;
};

export const none = matchingBooleans(true);
export const any = negate(none);
export const all = matchingBooleans(false);

export const checkAll = (...predicates) => value => {
    for (let i = 0; i < predicates.length; i++) {
        const predicate = predicates[i];
        if (predicate) {
            const result = predicate(value);
            if (result) {
                return result;
            }
        }
    }
};

export const comparator = f => ({
    gt: (...args) => f(...args) > 0,
    gte: (...args) => f(...args) >= 0,
    lt: (...args) => f(...args) < 0,
    lte: (...args) => f(...args) <= 0,
    equals: (...args) => f(...args) === 0
});
