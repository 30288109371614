import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { includes, groupBy, path } from 'ramda';
import { i18n } from '@i18n/format';
import { toolsByAudience, TOOL_AUDIENCES, TOOL_DOMAIN } from '@constants/tools';
import { referredBy } from '@utilities/navigation';
import Select from '@ui/inputs/dropdowns/Select';
import ToolListHeader from './ToolListHeader';
import {
    useToolFilter,
    filterOptions,
    familyFilterOptions
} from './toolTypeFilters';

const layout = css`
    section .tools {
        width: 100%;
        padding-top: 24px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 348px);
        grid-auto-rows: 284px;
        grid-gap: 24px;
        margin-bottom: 48px;
    }

    ${Select} {
        width: 240px;
    }

    ${Select.ToggleButton} {
        color: var(--theme-white);
        background: var(--theme-blue4);
    }

    ${Select.Chevron} {
        border-left: 1px solid var(--theme-gray1);

        svg {
            color: var(--theme-white);
            background: var(--theme-blue4);
        }
    }
`;

const useAutoscrollToSection = () => {
    const lexileHeader = useRef();
    const quantileHeader = useRef();
    const k2Header = useRef();

    useEffect(() => {
        if (referredBy(TOOL_DOMAIN.LEXILE)) {
            lexileHeader.current &&
                lexileHeader.current.scrollIntoView({ block: 'nearest' });
        }

        if (referredBy(TOOL_DOMAIN.QUANTILE)) {
            quantileHeader.current &&
                quantileHeader.current.scrollIntoView({ block: 'nearest' });
        }

        if (referredBy(TOOL_DOMAIN.K2)) {
            k2Header.current &&
                k2Header.current.scrollIntoView({ block: 'nearest' });
        }
    });

    return [lexileHeader, quantileHeader, k2Header];
};

const useFilteredTools = children => {
    const [filterValue] = useToolFilter();

    if (filterValue === TOOL_AUDIENCES.ALL) {
        return [];
    }

    const { description, tools: toolsForAudience } = toolsByAudience[
        filterValue
    ];

    return [
        description,
        React.Children.toArray(children).filter(toolCard =>
            includes(toolCard.props.tool, toolsForAudience)
        )
    ];
};

const ListSection = ({ header, headerRef, tools, allTools, hideSelect }) => (
    <section>
        <ToolListHeader allTools={allTools}>
            <span>
                <h2 ref={headerRef}>{header}</h2>
            </span>
            {!hideSelect && <ToolTypeSelect />}
        </ToolListHeader>
        <div className="tools" data-testid="tool-list">
            {tools}
        </div>
    </section>
);

const ToolTypeSelect = () => {
    const [filter, setFilter] = useToolFilter();
    const translatedToEs = filter === TOOL_AUDIENCES.PARENTS;

    return (
        <Select
            value={filter}
            a11yLabel={i18n('account.filterByAudience')}
            options={translatedToEs ? familyFilterOptions : filterOptions}
            onSelect={setFilter}
            initialSelectedItem={filter}
            alreadyOrdered
        />
    );
};

const ToolList = ({ className, children }) => {
    const [lexileHeader, quantileHeader, k2Header] = useAutoscrollToSection();
    const [filter] = useToolFilter();
    const [header, filteredTools] = useFilteredTools(children);

    if (filter === TOOL_AUDIENCES.ALL) {
        const {
            Lexile: lexileToolList,
            Quantile: quantileToolList,
            K2: k2ToolList
        } = {
            ...groupBy(
                path(['props', 'tool', 'domain']),
                React.Children.toArray(children)
            )
        };

        return (
            <div className={className}>
                <ListSection
                    headerRef={k2Header}
                    header={i18n('tools.k2')}
                    tools={k2ToolList}
                    allTools
                />
                <ListSection
                    headerRef={lexileHeader}
                    header={i18n('tools.lexileTools')}
                    tools={lexileToolList}
                    allTools
                    hideSelect
                />
                <ListSection
                    headerRef={quantileHeader}
                    header={i18n('tools.quantileTools')}
                    tools={quantileToolList}
                    allTools
                    hideSelect
                />
            </div>
        );
    }

    return (
        <div className={className}>
            <ListSection header={header} tools={filteredTools} />
        </div>
    );
};

export default styled(ToolList)`
    ${layout}
`;
