import React from 'react';
import styled from 'styled-components';
import { whenProvided } from '@utilities/stylingUtils';
import { Link as ReactRouterLink } from 'react-router-dom';
import baseRoutes from '@constants/routes';

const Link = ({ children, to, href, target, onClick, className, ...props }) => {
    if (to) {
        return (
            <ReactRouterLink
                to={to}
                target={target}
                className={className}
                onClick={onClick}
                {...props}
            >
                {children}
            </ReactRouterLink>
        );
    }

    return (
        <a
            href={href}
            target={target}
            onClick={onClick}
            className={className}
            {...props}
        >
            {children}
        </a>
    );
};

const StyledLink = styled(Link)`
    font-weight: 400;
    font-size: ${({ size }) => (size ? size : '1rem')};
    color: var(--theme-blue4);
    text-decoration: underline;
    cursor: pointer;

    ${whenProvided('$noUnderline')`
        text-decoration: none;

        :hover, :focus {
            text-decoration: underline;
        }
    `}
`;

export const RegistrationLink = styled(StyledLink).attrs({
    to: baseRoutes.REGISTRATION
})`
    color: var(--theme-purple1);
`;

export const UpgradeLink = styled(StyledLink).attrs({
    to: baseRoutes.BILLING
})`
    color: var(--theme-orange2);
`;

export default StyledLink;
