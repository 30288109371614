import React from 'react';
import styled, { css } from 'styled-components';
import Button from '@ui/actions/Button';
import languages, {
    languageCodeText,
    currentLanguage,
    setLanguage
} from '@constants/supportedLanguages';

const layout = css`
    height: 40px;
`;

const { en, es } = languages;

const LanguageToggle = ({ className }) => {
    const nextLanguage = currentLanguage === en ? es : en;

    return (
        <Button
            className={className}
            $studentFacing
            onClick={() => setLanguage(nextLanguage)}
            type="button"
        >
            {languageCodeText[nextLanguage]}
        </Button>
    );
};

export default styled(LanguageToggle)`
    ${layout}
`;
