export const id = f => f;

export const compose = (...fns) =>
    fns.reduce(
        (g, f) => (...args) => g(f(...args)),
        x => x
    );

export const flip = f => a => b => f(b)(a);

export const unary = f => a => f(a);

export const toBiFunction = f => (a, b) => f(a)(b);

//K combinator
export const constant = x => () => x;

//P combinator
export const on = f => g => x => y => f(g(x))(g(y));

export const map = f => functor => functor.map(f);

export const asValidator = f => message => value => {
    if (!f(value)) {
        return message;
    }
};

export const tap = f => value => {
    f(value);
    return value;
};

export const run = f => f();

export const defaultValue = whenEmpty => fn => (...args) => {
    const result = fn(...args);
    if (result !== undefined && result !== null) {
        return result;
    }
    return whenEmpty;
};
export const defaultToObject = defaultValue({});

const matched = (f, value) => ({
    on: () => matched(f, value),
    otherwise: () => f(value)
});

//Limited implementation of pattern matching
export const match = value => {
    return {
        on: (predicate, f) =>
            predicate(value) ? matched(f, value) : match(value),
        otherwise: f => f(value)
    };
};
