import React from 'react';

const FADBIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            aria-hidden="true"
        >
            <path d="M8.31424 5.0048L7.47078 3.72482L7.29318 5.24832L8.31424 5.0048Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.84192 1.61895L9.75671 0.210935C10.246 0.09447 10.737 0.396671 10.8534 0.88592L12.2615 6.80071C12.3779 7.28996 12.0757 7.78099 11.5865 7.89745L5.67169 9.30546C5.18244 9.42193 4.69141 9.11973 4.57494 8.63048L3.16693 2.71569C3.05047 2.22644 3.35267 1.73541 3.84192 1.61895ZM7.1922 6.08755L8.77664 5.70972L9.18058 6.32609L10.1974 6.08407L7.77026 2.64879L6.71164 2.90126L6.0918 7.06085L7.10862 6.81883L7.1922 6.08755Z"
            />
            <path d="M3.49981 12.4915H4.07265V12.4932C4.26417 12.4932 4.42088 12.6656 4.42088 12.8519C4.42088 13.0382 4.26592 13.2106 4.07265 13.2106H3.49981V12.4915Z" />
            <path d="M4.25025 13.9906C4.45222 13.9906 4.60892 14.1612 4.60892 14.3493V14.351C4.60892 14.5426 4.45396 14.7097 4.25025 14.7097H3.50155V13.9906H4.25025Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.910617 9.73875H6.99069C7.49361 9.73875 7.9013 10.1464 7.9013 10.6494V16.7294C7.9013 17.2324 7.49361 17.64 6.99069 17.64H0.910617C0.407697 17.64 0 17.2324 0 16.7294V10.6494C0 10.1464 0.407697 9.73875 0.910617 9.73875ZM4.39476 15.5524C5.05639 15.5524 5.577 15.1816 5.577 14.4747H5.57874C5.57874 13.9854 5.33324 13.6998 4.95889 13.5588C5.22354 13.4039 5.39069 13.1427 5.39069 12.7474C5.39069 12.0388 4.86487 11.6488 4.19279 11.6488H2.53174V15.5524H4.39476Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4717 8.3176L17.2164 10.3091C17.6915 10.4738 17.9432 10.9926 17.7785 11.4678L15.787 17.2124C15.6222 17.6876 15.1035 17.9393 14.6283 17.7745L8.88366 15.783C8.40848 15.6183 8.15682 15.0995 8.32155 14.6244L10.3131 8.87971C10.4778 8.40453 10.9965 8.15287 11.4717 8.3176ZM13.9794 11.1543C12.933 10.7904 11.7943 11.3423 11.4304 12.3905L11.4321 12.3922C11.0682 13.4386 11.6219 14.5773 12.6683 14.9412C13.2882 15.1571 13.9411 15.0527 14.446 14.7097L13.9219 13.9331C13.6608 14.1125 13.3178 14.1647 12.9782 14.0463C12.4072 13.8495 12.1268 13.2732 12.3253 12.7021C12.5238 12.131 13.0984 11.8507 13.6695 12.0492C14.009 12.1676 14.2458 12.4201 14.3398 12.723L15.2313 12.4375C15.0467 11.8542 14.5993 11.3684 13.9794 11.1543Z"
            />
        </svg>
    );
};

export default FADBIcon;
