import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import qs from 'qs';
import baseRoutes from '@constants/routes';
import { confirmRegistration } from '@api/session';
import { getIn } from '@utilities/dataTypes/objects';
import { success, alert } from '@utilities/alerts';
import { pushTo } from '@utilities/navigation';
import { upgradedToBasicPlus } from '@utilities/analytics/analyticsEvents';
import { upgradedToBasicPlus as upgradedToBasicPlusGA } from '@utilities/analytics/googleAnalytics';

@inject('loadingManager')
@withRouter
class Confirmation extends React.Component {
    getConfirmationData = () =>
        getIn('location.search')(this.props).map(queryParams =>
            qs.parse(queryParams, { ignoreQueryPrefix: true })
        );

    verifyUserCreation = ({ code, email, username }) => {
        /**
         * We can either get an email address or username for this,
         * going forward we should only be getting username, but some emails
         * still will trickle through so we have to handle those as well
         */

        const useUsername = username && username.trim() !== '';
        const userID = useUsername ? username : email;
        this.props.loadingManager
            .load(() => confirmRegistration(userID, code))
            .then(() => {
                success('Confirmation Successful! Please sign in.');
                upgradedToBasicPlus(); // Remove eventually, we're moving to GA. Keep both for now for population and in case of panic
                upgradedToBasicPlusGA();
            })
            .catch(error => {
                if (error.code === 'ExpiredCodeException') {
                    let queryParams = `?code=${code}&expired=true&`;
                    queryParams += useUsername
                        ? `username=${username}`
                        : `email=${encodeURIComponent(email)}`;

                    return queryParams;
                } else {
                    alert((error && error.message) || error);
                }
            })
            .then((navParams = '') =>
                pushTo(`${baseRoutes.SIGN_IN}${navParams}`)
            );
    };

    componentDidMount() {
        this.getConfirmationData().map(this.verifyUserCreation);
    }

    render() {
        return null;
    }
}

export default Confirmation;
