import React from 'react';
import styled from 'styled-components';
import { i18n } from '@i18n/format';
import { stateCCAndCCROptions } from '@constants/states';
import { getStateStandards } from '@api/quantile';
import { whenProvided } from '@utilities/stylingUtils';
import { propEquals } from '@utilities/dataTypes/objects';
import { isEmptyArray } from '@utilities/dataTypes/arrays';
import { queryParams } from '@utilities/navigation';
import Button from '@ui/actions/Button';
import LinkButton from '@ui/actions/LinkButton';
import Select from '@ui/inputs/dropdowns/Select';
import ChevronDown from '@ui/icons/ChevronDown';
import StateStandardResults from './StateStandardResults';
import QSCCard from './QSCCard';

const FormLayout = styled.div`
    display: flex;
    margin-bottom: 0.75rem;

    ${Button}, .form_description {
        margin: 0 0.5rem;
        height: 38px;
    }

    .form_description {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ${Select} {
        width: 300px;
    }
`;

const Chevron = styled(ChevronDown)`
    margin: 0 8px;
    transition: transform 150ms ease-out;

    ${whenProvided('open')`
        transform: rotate(180deg);
    `}
`;

const stateLabel = stateName => {
    if (!stateName) {
        return null;
    }

    return stateCCAndCCROptions.byAbbreviation.find(
        propEquals('value', stateName.toUpperCase())
    )?.label;
};

class StateStandards extends React.Component {
    constructor(props) {
        super(props);

        const { state } = queryParams();

        this.state = {
            isOpen: false,
            standards: [],
            state: stateLabel(state) || null,
            stateInput: state || null
        };
    }

    fetchNewStandards = async () => {
        if (!this.state.stateInput) {
            return;
        }

        const standards = await getStateStandards(
            this.props.id,
            this.state.stateInput
        );

        this.setState({
            state: stateLabel(this.state.stateInput),
            standards,
            isOpen: true
        });
    };

    onSubmit = e => {
        e.preventDefault();
        this.fetchNewStandards();
    };

    componentDidMount() {
        this.fetchNewStandards();
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.fetchNewStandards();
        }
    }

    render() {
        const { state, isOpen, standards, stateInput } = this.state;
        return (
            <QSCCard>
                <h2>
                    <span>
                        {i18n(
                            'mathSkillsDB.stateStandards',
                            state ? state : ''
                        )}
                    </span>
                    {!isEmptyArray(standards) && (
                        <LinkButton
                            onClick={() =>
                                this.setState(prevState => ({
                                    isOpen: !prevState.isOpen
                                }))
                            }
                        >
                            {i18n(
                                isOpen
                                    ? 'mathSkillsDB.hideStandards'
                                    : 'mathSkillsDB.showStandards',
                                standards.length
                            )}
                            <Chevron open={isOpen} />
                        </LinkButton>
                    )}
                </h2>
                <form onSubmit={this.onSubmit}>
                    <FormLayout>
                        <p className="form_description">
                            {state
                                ? i18n('mathSkillsDB.notYourState')
                                : i18n('mathSkillsDB.noState')}
                        </p>
                        <Select
                            name="state"
                            label={i18n('common.state')}
                            a11yLabel
                            placeholder={i18n('common.select')}
                            options={stateCCAndCCROptions.byAbbreviation}
                            initialSelectedItem={stateInput}
                            onSelect={value =>
                                this.setState({ stateInput: value })
                            }
                        />
                        <Button type="submit">{i18n('common.submit')}</Button>
                    </FormLayout>
                </form>
                {isOpen && (
                    <StateStandardResults
                        standards={standards}
                        state={state.label}
                        qscId={this.props.id}
                    />
                )}
            </QSCCard>
        );
    }
}

export default StateStandards;
