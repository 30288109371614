import { Auth } from 'aws-amplify';
import { compose } from '@utilities/dataTypes/functions';
import {
    getIn,
    mapEntry,
    mapProp,
    renameProps,
    deletePropsNotIn
} from '@utilities/dataTypes/objects';
import { secToMs } from '@utilities/dataTypes/numbers';
import { strToBool } from '@utilities/dataTypes/strings';

export const register = newUser =>
    Auth.signUp({
        username: newUser.email,
        password: newUser.password,
        attributes: {
            email: newUser.email,
            'custom:role': newUser.role,
            'custom:country': newUser.country,
            'custom:state': newUser.state
        },
        clientMetadata: {
            subscribeToMailList: `${newUser.subscribeToMailList}`
        }
    });

export const confirmRegistration = (username, code) =>
    Auth.confirmSignUp(username, code, {
        forceAliasCreation: true
    });

export const login = ({ email, password }) => Auth.signIn(email, password);

export const decodePayload = jwtToken => {
    const payload = jwtToken.split('.')[1];
    try {
        return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
    } catch (err) {
        return {};
    }
};

export const getMsUntilSessionExpiration = async () => {
    try {
        const data = await Auth.currentSession();
        // Set expiration 5 seconds earlier so we don't run into
        // race conditions with refreshToken being used.
        const expiration = data?.idToken?.payload?.exp - 5;
        // QA Line 120s
        // return 120000;

        // TODO: Prod Line calculated from JWT
        return secToMs(expiration) - Date.now();
    } catch {
        return null;
    }
};

export const refresh = async () => {
    const [user, { refreshToken }] = await Promise.all([
        Auth.currentAuthenticatedUser(),
        Auth.currentSession()
    ]);

    return new Promise((resolve, reject) => {
        user.refreshSession(refreshToken, (error, session) => {
            if (session) {
                resolve();
            } else {
                reject(error);
            }
        });
    });
};

export const resendConfirmationCode = username => Auth.resendSignUp(username);

export const signOut = () => Auth.signOut();

const noCurrentUser = error => {
    if (error !== 'No current user') {
        throw error;
    }
};

const userDoesNotExist = error => {
    if (error?.message !== 'User does not exist.') {
        throw error;
    }
};

export const userInfo = () =>
    Auth.currentSession()
        .catch(noCurrentUser)
        .catch(userDoesNotExist)
        .then(getIn('idToken.payload'))
        .then(payload => payload.map(parseIdTokenPayload).orElse({}));

export const jwt = async (fromToken = 'idToken') =>
    Auth.currentSession()
        .catch(noCurrentUser)
        .catch(userDoesNotExist)
        .then(getIn(`${fromToken}.jwtToken`))
        .then(token => token.orElse(null));

export const sendResetPasswordEmail = emailAddress =>
    Auth.forgotPassword(emailAddress);

export const resetPassword = ({ username, code, password }) =>
    Auth.forgotPasswordSubmit(username, code, password);

export const changePassword = (currentPassword, newPassword) =>
    Auth.currentAuthenticatedUser().then(user =>
        Auth.changePassword(user, currentPassword, newPassword)
    );

export const injectJwt = (fromToken = 'idToken') => f => jwt(fromToken).then(f);

const publicUserAttributes = [
    'cognito:groups',
    'custom:state',
    'custom:country',
    'custom:role',
    'custom:stripe_customer',
    'email',
    'email_verified',
    'given_name',
    'family_name',
    'name',
    'firstLogin',
    'free-trial-expiration',
    'sub',
    'identities',
    'agreedToTerms'
];

const ensurePropExists = mapEntry((prop, value) => [prop, value || '']);

const parseIdTokenPayload = compose(
    ensurePropExists('given_name'),
    ensurePropExists('family_name'),
    mapProp(strToBool)('firstLogin'),
    mapProp(strToBool)('agreedToTerms'),
    mapProp(groups => groups || [])('groups'),
    renameProps([
        ['sub', 'uuid'],
        ['cognito:groups', 'groups'],
        ['custom:state', 'state'],
        ['custom:country', 'country'],
        ['custom:role', 'role'],
        ['custom:stripe_customer', 'customerId'],
        ['email_verified', 'emailVerified'],
        ['free-trial-expiration', 'freeTrialExpiration']
    ]),
    deletePropsNotIn(publicUserAttributes)
);
