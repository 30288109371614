import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MakeshiftButton } from 'donut-ui';
import { whenNotProvided } from '@utilities/stylingUtils';
import ChevronDoubleLeftIcon from '@ui/icons/ChevronDoubleLeftIcon';
import HamburgerIcon from '@ui/icons/HamburgerIcon';
import { i18n } from '@i18n/format';
import { drawerConstants, appBarHeight } from '@ui/navigation/navConstants';
import { useViewport } from '@utilities/hooks/useViewport';
import { breakpoints } from '@utilities/stylingUtils';

const MenuHeaderLayout = styled.div`
    height: ${appBarHeight};
    flex: 0 0 ${drawerConstants.openWidth};
    position: relative;
    background-color: var(--theme-violet1);
    color: var(--theme-gray4);
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: bold;
    justify-content: flex-end;
    padding: 0 16px;
    z-index: 100;

    > span:first-child {
        position: absolute;
        left: 16px;
    }

    ${whenNotProvided('isOpen')`
        padding: 0 calc((${drawerConstants.closedWidth} - ${drawerConstants.menuIconSize};)/2) 0 0;
        flex: 0 0 ${drawerConstants.closedWidth};
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
        > span:first-child {
            transform: translatex(-180px);
        }
    `}

    .icon-container {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: ${drawerConstants.menuIconSize};
        flex: 0 0 ${drawerConstants.menuIconSize};
        border-radius: 50%;
        position: relative;
    }

    .icon-container:hover,
    .icon-container:focus {
        background-color: var(--theme-violet2);
        outline: 0;
    }

    svg {
        width: 20px;
        height: 20px;
        fill: var(--theme-gray4);
    }
`;

const MenuHeader = ({ className, isOpen, setContentVisible, toggleOpen }) => {
    return (
        <MenuHeaderLayout className={className} isOpen={isOpen}>
            <span>{i18n('common.menu')}</span>
            <MakeshiftButton>
                {getButtonProps => (
                    <span
                        aria-expanded={isOpen}
                        aria-label="Navigation"
                        {...getButtonProps({
                            className: 'icon-container',
                            onClick: () => {
                                setContentVisible(true);
                                toggleOpen(!isOpen);
                            }
                        })}
                    >
                        {isOpen ? (
                            <ChevronDoubleLeftIcon
                                title={i18n('acc.closeMenu')}
                            />
                        ) : (
                            <HamburgerIcon title={i18n('acc.openMenu')} />
                        )}
                    </span>
                )}
            </MakeshiftButton>
        </MenuHeaderLayout>
    );
};

const MobileMenuHeader = styled(MenuHeader)`
    flex: 0 0 100%;

    ${whenNotProvided('isOpen')`
        flex: 0 0 ${drawerConstants.closedWidth};
    `}
`;

const MobileMenuContent = styled.div`
    height: 100%;
    flex: 0 0 100%;
    background-color: var(--theme-violet2);

    ${whenNotProvided('isOpen')`
        position: absolute;
        transform: translatex(-300px);
        flex: 0 0 ${drawerConstants.closedWidth};
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    `}
`;

const DesktopMenuContent = styled.div`
    height: 100%;
    flex: 0 0 ${drawerConstants.openWidth};
    background-color: var(--theme-violet2);

    ${whenNotProvided('isOpen')`
        position: absolute;
        transform: translatex(-300px);
        height: ${appBarHeight};
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    `}
`;

export const CollapsibleSidebarHeader = ({
    className,
    isOpen,
    toggleOpen,
    setContentVisible
}) => {
    const { width } = useViewport();
    const breakpoint = breakpoints.phone;

    useEffect(() => {
        toggleOpen(width >= breakpoint);
        setContentVisible(width >= breakpoint);
    }, [width, breakpoint, toggleOpen, setContentVisible]);

    return width < breakpoint ? (
        <MobileMenuHeader
            className={className}
            isOpen={isOpen}
            setContentVisible={setContentVisible}
            toggleOpen={toggleOpen}
        />
    ) : (
        <MenuHeader
            className={className}
            isOpen={isOpen}
            setContentVisible={setContentVisible}
            toggleOpen={toggleOpen}
        />
    );
};

export const CollapsibleSidebarContent = ({
    className,
    children,
    isOpen,
    toggleOpen,
    isContentVisible,
    setContentVisible
}) => {
    const { width } = useViewport();
    const breakpoint = breakpoints.phone;

    const collapseSidebar = () => {
        toggleOpen(false);
        setContentVisible(false);
    };

    const childrenWithProps = React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
            collapseSidebar: collapseSidebar
        });
    });

    useEffect(() => {
        toggleOpen(width >= breakpoint);
        setContentVisible(width >= breakpoint);
    }, [width, breakpoint, toggleOpen, setContentVisible]);

    return width < breakpoint ? (
        <MobileMenuContent
            className={className}
            isOpen={isOpen}
            onTransitionEnd={() => {
                !isOpen && setContentVisible(isOpen);
            }}
        >
            {isContentVisible && childrenWithProps}
        </MobileMenuContent>
    ) : (
        <DesktopMenuContent
            className={className}
            isOpen={isOpen}
            onTransitionEnd={() => {
                !isOpen && setContentVisible(isOpen);
            }}
        >
            {isContentVisible && children}
        </DesktopMenuContent>
    );
};
