import React from 'react';
import styled from 'styled-components';
import { MakeshiftButton } from 'donut-ui';
import { i18n } from '@i18n/format';
import { TOOL_AUDIENCES, TOOL_DOMAIN } from '@constants/tools';
import { pushTo } from '@utilities/navigation';
import { branchStyles } from '@utilities/stylingUtils';
import { truthyValue } from '@utilities/dataTypes/objects';
import HiddenKeyboardLink from '@ui/actions/HiddenKeyboardLink';
import StarIcon from '@ui/icons/StarIcon';
import LaunchIcon from '@ui/icons/LaunchIcon';
import { useFavorites } from '@features/dashboard/Favorites';
import BrandedCard from '@ui/data/BrandedCard';
import { useTier, tiers } from '@ui/layouts/Tier';
import { useToolFilter } from './toolTypeFilters';
import { brands } from '@constants/brands';

const ToolCardLayout = styled(BrandedCard)`
    padding: 0;
    box-shadow: var(--elevated-dark);

    .icon-and-quicklink {
        height: 70px;
        background: var(--theme-white2);
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        align-items: center;

        .save-quicklink {
            display: flex;
            align-items: center;

            span {
                font-size: 0.625rem;
                font-weight: bold;
                text-transform: uppercase;
                color: var(--theme-blue5);
            }
        }

        .quicklink-icon-container {
            margin-left: 8px;
            height: 44px;
            width: 44px;
            border: 1px solid var(--theme-blue5);
            border-radius: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .tool-data {
        padding: 16px 24px;
        cursor: pointer;
    }
`;

const ProductIcon = styled.img`
    height: 75%;
    cursor: pointer;
`;

const FavoriteStarIcon = styled(StarIcon)`
    width: 21px;
    height: 21px;

    color: ${branchStyles(truthyValue('favorite'))`
        var(--theme-yellow1)
    `.else`
        var(--theme-blue5)`};
`;

const ToolHeader = styled.div`
    margin: 0;
    display: flex;
    align-items: flex-end;

    h3 {
        font-size: 1.125rem;
        font-weight: bold;
    }

    ${HiddenKeyboardLink} {
        ${LaunchIcon} {
            fill: var(--theme-gray3);
            height: 0.875rem;
            width: 0.875rem;
        }
    }
`;

const ToolFilters = styled.p`
    margin: 4px 0;
    font-size: 0.875rem;
    color: var(--theme-gray3);
`;

const ToolDescription = styled.p`
    margin: 0;
`;

export const renderRoleString = (audience, translatedToEs) =>
    i18n(`tools.${translatedToEs ? 'home.' : ''}${audience}`);

const ToggleFavoriteButton = ({ tool }) => {
    const userTier = useTier();

    const { isFavorite, toggleFavorite } = useFavorites();
    const toolFavorited = isFavorite(tool.id);

    return (
        <div className="save-quicklink">
            <MakeshiftButton>
                {getButtonProps => (
                    <div
                        {...getButtonProps({
                            className: 'quicklink-icon-container',
                            title: i18n(
                                userTier === tiers.FREE
                                    ? 'favorites.registerForQuickLinks'
                                    : toolFavorited
                                    ? 'favorites.removeQuickLink'
                                    : 'favorites.saveQuickLink'
                            ),
                            onClick: () => toggleFavorite(tool.id),
                            'aria-label': i18n(
                                toolFavorited
                                    ? 'account.removeFavorite'
                                    : 'account.addToFavorites'
                            )
                        })}
                    >
                        <FavoriteStarIcon favorite={toolFavorited} />
                    </div>
                )}
            </MakeshiftButton>
        </div>
    );
};

const ToolCard = ({ tool, logo, audience, description }) => {
    const [filter] = useToolFilter();

    const goToTool = () => {
        const path = tool.path || `/${tool.id}`;
        pushTo(path);
    };

    description =
        typeof description === 'string'
            ? description
            : description[filter]
            ? description[filter]
            : description[Object.keys(description)[0]];

    const translatedToEs = filter === TOOL_AUDIENCES.PARENTS;

    return (
        <ToolCardLayout
            brand={
                tool.domain === TOOL_DOMAIN.LEXILE ||
                tool.domain === TOOL_DOMAIN.K2
                    ? brands.LEXILE
                    : brands.QUANTILE
            }
            aria-labelledby={tool.id}
        >
            <div className="icon-and-quicklink">
                <ProductIcon
                    src={logo}
                    alt={tool.toolName}
                    onClick={goToTool}
                />
                <ToggleFavoriteButton tool={tool} />
            </div>
            <div className="tool-data" onClick={goToTool}>
                <ToolHeader>
                    <h3 id={tool.id}>{tool.toolName}</h3>
                    <HiddenKeyboardLink to={tool.path || `/${tool.id}`}>
                        <LaunchIcon
                            title={i18n('account.goToTool', tool.toolName)}
                        />
                    </HiddenKeyboardLink>
                </ToolHeader>
                <ToolFilters>
                    {renderRoleString(audience, translatedToEs)}
                </ToolFilters>
                <ToolDescription>{description}</ToolDescription>
            </div>
        </ToolCardLayout>
    );
};

export default ToolCard;
