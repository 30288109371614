import { makeEndpoint } from './httpRequest';
import { formatApiDate } from '@utilities/dataTypes/dates';

const NAME = process.env.REACT_APP_MMM_NAME;
const ENDPOINT = process.env.REACT_APP_MMM_URL;

const mmmApi = makeEndpoint(NAME, ENDPOINT);
export const mmmRoot = mmmApi.configure();

export const stateTestsForGrade = ({
    measureType: measure_type,
    grade,
    state
}) =>
    mmmApi.post('/get_tests/', {
        measure_type,
        grade,
        state
    });

export const measurementRange = ({
    firstTest: first_test_id,
    firstMeasure: first_measure,
    firstTestDate: first_test_date,
    secondTest: second_test_id,
    secondMeasure: second_measure,
    secondTestDate: second_test_date
}) =>
    mmmApi.post('/get_range/', {
        first_test_id,
        first_measure,
        first_test_date: formatApiDate(first_test_date),
        second_test_id,
        second_measure,
        second_test_date: formatApiDate(second_test_date)
    });
