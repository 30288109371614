import React from 'react';
import styled, { css } from 'styled-components';
import { visuallyHidden, MakeshiftButton } from 'donut-ui';
import { productHeaderHeight } from '@ui/navigation/navConstants';
import { whenProvided } from '@utilities/stylingUtils';
import LanguageToggle from '@features/global/LanguageToggle';

const layout = css`
    height: ${productHeaderHeight};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: var(--theme-gray4);
    border-bottom: 1px solid var(--theme-gray2);

    ${whenProvided('$studentFacing')`
        background-color: var(--theme-white2);
        border-bottom: none;
    `}

    .product-logo img {
        height: 60px;
        ${whenProvided('onClick')`
            cursor: pointer;
        `}
    }

    > h1 {
        ${visuallyHidden}
    }

    .right-decoration {
        display: flex;
        align-items: center;

        > * {
            margin-left: 16px;
        }
    }
`;

const ProductHeader = React.forwardRef(
    (
        {
            className,
            productBranding,
            productName,
            onClick,
            rightDecoration,
            middleDecoration,
            addHeader, //This is a temporary fix until we can fix the entire header mess.
            translatedToEs
        },
        ref
    ) => {
        return (
            <div ref={ref} className={className}>
                {productName && addHeader && <h1>{productName}</h1>}
                <MakeshiftButton>
                    {getButtonProps => (
                        <span
                            className="product-logo"
                            {...getButtonProps({ onClick })}
                        >
                            <img src={productBranding} alt={productName} />
                        </span>
                    )}
                </MakeshiftButton>
                {middleDecoration && <div>{middleDecoration}</div>}
                <div className="right-decoration">
                    {rightDecoration && rightDecoration}
                    {translatedToEs && <LanguageToggle />}
                </div>
            </div>
        );
    }
);

export default styled(ProductHeader)`
    ${layout}
`;
