import React from 'react';

const WordListsIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13.12 16"
            aria-hidden="true"
        >
            <path
                d="M3.58 13.8a.13.13 0 0 1-.12-.13V3.19 2.04h7.67c.07 0 .24.06.24.14v1.53h.78V1.14A.13.13 0 0 0 12 1H2.75a.12.12 0 0 0-.12.13v13.46a.13.13 0 0 0 .13.14h3.47v-.93z"
                transform="translate(-2.63 -1)"
            />
            <path
                d="M9.52 7V5h1.85V3.71h-3L6.22 5.89v7.91h1V7z"
                transform="translate(-2.63 -1)"
            />
            <path
                d="M15.74 4.19a.46.46 0 0 0-.44-.48h-3.15V5h2.64v11H7.26v-1.28h-1v1.79a.46.46 0 0 0 .44.48h8.64a.46.46 0 0 0 .44-.48z"
                transform="translate(-2.63 -1)"
            />
            <path
                d="M7.26 13.8h-1v.93h1zM12.15 3.71h-.78V5h.78z"
                transform="translate(-2.63 -1)"
            />
        </svg>
    );
};

export default WordListsIcon;
