import { isNegative, empty } from '@utilities/predicates';
import { onEnter as onEnterPress } from '@utilities/keyboardEvents';

const whenProvided = (prop, f) => (prop ? f : undefined);

const autocompleteInputEvents = (props, downshift) => {
    const openMenu = () => {
        downshift.setState({
            isOpen: true
        });
    };

    const selectInputText = () => {
        downshift.selectItem(downshift.inputValue);
    };

    return {
        onFocus: whenProvided(props.openOnFocus, openMenu),
        onClick: whenProvided(props.openOnFocus, openMenu),
        onChange: () => {
            if (downshift.selectedItem) {
                downshift.clearSelection();
            }
        },
        onBlur: () => {
            if (props.allowFreeText && !downshift.selectedItem) {
                selectInputText();
            }
            props.onBlur && props.onBlur();
        },
        onKeyUp: whenProvided(
            props.onEnter,
            onEnterPress(event => {
                const notHighlighted =
                    isNegative(downshift.highlightedIndex) ||
                    empty(downshift.highlightedIndex);

                if (
                    notHighlighted &&
                    props.allowFreeText &&
                    !downshift.selectedItem
                ) {
                    event.preventDefault();
                    selectInputText();
                    props.onEnter(downshift.inputValue);
                }
            }, true)
        )
    };
};

export default autocompleteInputEvents;
