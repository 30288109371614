import styled from 'styled-components';
import { visuallyHidden, whenNotProvided } from 'donut-ui';

const ToolListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    width: 100%;
    border-bottom: 1px solid var(--theme-gray2);
    padding: 12px 0;

    h2 {
        text-transform: uppercase;
        color: var(--theme-gray3);
        font-size: 1rem;
        font-weight: normal;
        white-space: nowrap;

        ${whenNotProvided('allTools')`
            ${visuallyHidden}
        `}
    }
`;

export default ToolListHeader;
