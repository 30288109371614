import styled from 'styled-components';
import { Dialog as DonutDialog } from 'donut-ui';
import { lexileTheme, quantileTheme } from '~/theme';

const Dialog = styled(DonutDialog)`
    ${DonutDialog.Close} {
        height: unset;
        color: var(--theme-gray3);

        :focus,
        :active {
            outline: 0;
            box-shadow: var(--outline);
        }
    }

    ${DonutDialog.Title} {
        color: var(--theme-blue4);
    }
`;

export const LexileDialog = styled(Dialog)`
    ${lexileTheme}
`;

export const QuantileDialog = styled(Dialog)`
    ${quantileTheme}
`;

export default Dialog;
