import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const layout = css`
    display: block;
`;

const toolLinkStyle = css`
    text-decoration: none;
    color: var(--theme-white);
    font-size: 0.875rem;
    display: flex;
    align-items: center;

    :hover {
        color: var(--theme-blue-quantile);
    }

    &.${({ activeClassName }) => activeClassName} {
        color: var(--theme-blue-quantile);
    }

    svg {
        height: 18px;
        flex: 0 0 18px;
        margin-right: 16px;
        fill: currentColor;
    }
`;

const ToolLink = styled(NavLink)`
    ${toolLinkStyle}
`;

export const ExternalToolLink = styled.a`
    ${toolLinkStyle}
`;

ToolLink.defaultProps = {
    activeClassName: 'active'
};

const ToolListItem = ({
    className,
    name,
    url,
    icon: Icon,
    collapseSidebar
}) => {
    return (
        <li className={className}>
            <ToolLink
                to={url}
                onClick={collapseSidebar ? collapseSidebar : null}
            >
                <Icon />
                {name}
            </ToolLink>
        </li>
    );
};

export default styled(ToolListItem)`
    ${layout}
`;
