import { toggle, contains } from '@utilities/dataTypes/arrays';

export const checkbox = {
    onChange: (form, name, value, onChange) => () => {
        const formValue = form.values[name];
        const isArray = Array.isArray(formValue);
        let newValue;
        if (isArray) {
            newValue = toggle(formValue, value);
        } else {
            newValue = !formValue;
        }
        form.setFieldValue(name, newValue);
        onChange && onChange(newValue, form);
    },
    checked: (field, value) => {
        const isArray = Array.isArray(field.value);
        if (isArray) {
            return contains(field.value, value);
        }

        return field.value;
    }
};

export const radio = {
    onChange: (form, name, value, onChange) => () => {
        form.setFieldValue(name, value);
        onChange && onChange(form, name, value);
    },
    checked: (field, value) => field.value === value
};
