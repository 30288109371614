import { generateSessionEndData } from '@utilities/analytics/agent';
import baseRoutes from '@constants/routes';
import usageTracker from '@utilities/analytics/usageTracker';
import stopwatch from '@utilities/stopwatch';

export let sessionTimer = stopwatch.start();

export const resetSessionTimer = () => {
    sessionTimer = stopwatch().start();
};

const checkIsBounce = () => {
    const disabledPaths = [baseRoutes.START_SSO];

    const bounceDisabled = disabledPaths.some(path =>
        window.location.pathname.startsWith(path)
    );

    return usageTracker.pagesVisited === 1 && !bounceDisabled;
};

export const onSessionEnd = () => {
    const timeElapsed = sessionTimer.timeElapsed();

    // Discard sessions under 2 seconds.
    // Anything less is likely a redirect or outlier data.
    if (timeElapsed < 2000) {
        return;
    }

    fetch(`${process.env.REACT_APP_HUBSTATS_URL}/analytics_event`, {
        method: 'post',
        keepalive: true,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_HUBSTATS_API_KEY
        },
        body: generateSessionEndData({
            duration: timeElapsed,
            bounce: checkIsBounce() // returns bool
        })
    }).catch(error => {
        console.log(error);
    });
};
