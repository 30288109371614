import React, { useState } from 'react';
import styled from 'styled-components';
import { i18n, i18nWithHTML } from '@i18n/format';
import { privacyPolicy } from '~/constants/externalLinks';
import {
    getCookieConsent,
    setCookieConsent
} from '@utilities/analytics/cookieConsent';
import Button from '@ui/actions/Button';
import Link from '@ui/actions/Link';

const Layout = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1000;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 20px;
    background: var(--theme-white);
    box-shadow: 0px -8px 12px 0px rgba(0, 0, 0, 0.15);

    p {
        margin-right: 20px;
    }
`;

const CookieConsent = ({ onSave }) => {
    const storedCookieConsent = getCookieConsent();
    const [open, setOpen] = useState(storedCookieConsent === null);

    if (!open) {
        return null;
    }

    return (
        <Layout
            role="dialog"
            aria-label={i18n('account.cookieConsent')}
            aria-describedby="cookie-consent-dialog"
        >
            <p id="cookie-consent-dialog">
                {i18nWithHTML('account.cookieDescription', pp => (
                    <Link
                        href={privacyPolicy}
                        target="_blank"
                        rel="noreferrer noopener"
                        $noUnderline
                    >
                        {pp}
                    </Link>
                ))}
            </p>
            <Button
                onClick={() => {
                    setOpen(false);
                    onSave && onSave(true);
                    setCookieConsent(true);
                }}
                $studentFacing
            >
                {i18n('common.confirm')}
            </Button>
        </Layout>
    );
};

export default CookieConsent;
