import React from 'react';

const TextAnalyzerIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.57 15"
            aria-hidden="true"
        >
            <path d="M14.57 14a1.56 1.56 0 0 0-.34-.6l-4.09-4.26a5.67 5.67 0 1 0-4.48 2.19 5.72 5.72 0 0 0 3.17-1l4.06 4.28a1.26 1.26 0 0 0 .72.35 1.2 1.2 0 0 0 .7-.3 1 1 0 0 0 .26-.66zM5.63 9.86a4.19 4.19 0 1 1 4.19-4.19 4.18 4.18 0 0 1-4.19 4.19z" />
        </svg>
    );
};

export default TextAnalyzerIcon;
