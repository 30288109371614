import React from 'react';
import styled, { css } from 'styled-components';
import { i18n } from '@i18n/format';
import { rcNavigatedToFromTool } from '@utilities/analytics/analyticsEvents';
import { pushTo } from '@utilities/navigation';
import baseRoutes from '@constants/routes';
import { tiers, useTier } from '@ui/layouts/Tier';
import Button from '@ui/actions/Button';
import ResourceCenterLogo from '@ui/icons/QuantileResourceCenterLogo';
import { TooltipWrapper } from '@ui/Tooltip';

//prettier-ignore
const layout = css`
    display: inline-block;

    ${Button} {
        width: 30px;
        padding: 4px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: transparent;
        border: none;
        text-align: center;
        color: var(--theme-gray3);
        white-space: pre-wrap;
    }
    ${Button}:not(:disabled):hover {
        outline: 0;
        box-shadow: var(--outline);
    }

    ${Button}:disabled {
        opacity: 1;
    }

    svg {
        height: 28px;
        width: 28px;
        margin-bottom: 4px;
    }
    
    ${TooltipWrapper.Content} {
        bottom: 20px;
        right: 100%;
        left: unset;
    }

    ${TooltipWrapper.Triangle} {
        right: -6px;
        left: auto;
        top: 12px;
        z-index: -1;
    }
`;

const ToResourceCenter = ({ className, currentTool, translatedToEs }) => {
    const userTier = useTier();
    const isFreeUser = userTier === tiers.FREE;
    return (
        <span className={className}>
            <Button
                onClick={() => {
                    rcNavigatedToFromTool({ linkedFrom: currentTool });
                    pushTo(baseRoutes.RESOURCE_CENTER);
                }}
                disabled={isFreeUser}
            >
                <TooltipWrapper
                    disabled={!isFreeUser}
                    text={i18n(
                        translatedToEs
                            ? 'quantileResourceCenter.mathAtHome.signInToViewResources'
                            : 'quantileResourceCenter.signInToViewResources'
                    )}
                >
                    <ResourceCenterLogo disabled={isFreeUser} />
                    <span>
                        {i18n(
                            translatedToEs
                                ? 'quantileResourceCenter.mathAtHome.shortName'
                                : 'quantileResourceCenter.shortName'
                        )}
                    </span>
                </TooltipWrapper>
            </Button>
        </span>
    );
};

export default styled(ToResourceCenter)`
    ${layout}
`;
