import React from 'react';
import styled, { css } from 'styled-components';
import { ScreenReaderText } from 'donut-ui';
import { i18n } from '@i18n/format';
import BookOpen from '@ui/icons/BookOpen';
import {
    layout,
    contentStyles,
    Circle
} from '@features/dashboard/home/whatAreMeasures/measuresArtStyles';
import CareerReadiness from '@features/dashboard/home/whatAreMeasures/CareerReadiness';
import {
    EasyLexileSection,
    HardLexileSection,
    OnTargetLexileSection,
    LexileSection
} from '@features/dashboard/home/whatAreMeasures/DifficultySections';
import lexileArt from '~/images/Lexile_Popup.png';

const lexileLayout = css`
    ${layout};
    ${contentStyles};

    ${LexileSection.Book} {
        display: flex;
        flex-direction: column;

        > :first-child {
            font-size: 1.125rem;
            font-weight: bold;
            font-style: italic;
        }

        > :nth-child(2) {
            margin: 0 0 4px;
            text-transform: uppercase;
            font-size: 0.825rem;
        }
    }

    .student-measure {
        bottom: 64px;
        left: 36%;
        transform: rotate(-45deg);
    }

    ${OnTargetLexileSection} {
        ${LexileSection.Book} {
            align-items: center;

            > :first-child {
                background-color: #c9f4d5;
                font-size: 1.125rem;
                margin: 10px 0;

                padding: 3px 8px;
                border-radius: 4px;
            }

            > :last-child {
                width: fit-content;
                background-color: #c9f4d5;
                text-transform: uppercase;
                font-size: 0.825rem;

                padding: 3px 8px;
                border-radius: 4px;
            }
        }

        ${Circle} {
            ${BookOpen} {
                height: 30px;
            }

            span {
                font-size: 1.325rem;
            }
        }
    }

    ${CareerReadiness.CareerCircle} {
        background-color: #018745;
        border: solid 8px #00ce01;
    }

    ${BookOpen} {
        height: 24px;
        margin-top: 4px;
    }
`;

export const LexilePopupArt = styled(({ className }) => (
    <>
        <ScreenReaderText>{i18n('home.lexileGraphicAlt')}</ScreenReaderText>
        <div className={className} aria-hidden={true}>
            <span className="title">{i18n('home.findJustRightBooks')}</span>
            <CareerReadiness measure="1300L" />
            <EasyLexileSection
                measure="160L"
                title="Biscuit Goes to School"
                author="Alyssa Satin Capucilli"
                difficulty={i18n('common.findABook.easier')}
            />
            <OnTargetLexileSection
                measure="880L"
                title="Harry Potter and the Sorcerer's Stone"
                author="J. K. Rowling"
                difficulty={i18n('home.onTarget')}
            />
            <HardLexileSection
                measure="1390L"
                title="Hamlet"
                author="William Shakespeare"
                difficulty={i18n('common.findABook.harder')}
            />
            <img src={lexileArt} alt="" role="presentation" />
            <span className="student-measure">900L</span>
        </div>
    </>
))`
    ${lexileLayout}
`;
