import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { whenProvided } from '@utilities/stylingUtils';
import LinkButton from '@ui/actions/LinkButton';

const drawerLinkCSS = css`
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--theme-cool-gray);

    :visited {
        color: var(--theme-cool-gray);
    }

    :hover {
        color: var(--theme-blue-quantile);
    }

    ${whenProvided('activeClassName')`
        &.${({ activeClassName }) => activeClassName} {
            color: var(--theme-blue-quantile);
        }
    `}
`;

export const DrawerLink = styled(NavLink)`
    ${drawerLinkCSS}
`;

export const ExternalDrawerLink = styled.a`
    ${drawerLinkCSS}
`;

export const DrawerLinkButton = styled(LinkButton)`
    ${drawerLinkCSS}
`;
