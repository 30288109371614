import styled from 'styled-components';
import { whenProvided } from '@utilities/stylingUtils';

const ToolContent = styled.div`
    position: relative;
    padding: 1rem 2rem;
    background-color: var(--theme-white);

    ${whenProvided('$studentFacing')`
        background-color: var(--theme-white2);
    `};
`;

export default ToolContent;
