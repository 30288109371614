import React from 'react';

const QuantileLogo = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="150 150 1200 1200"
            height={36}
            aria-hidden="true"
            {...props}
        >
            <path
                fill={props.fill || '#fff'}
                d="M331.25 828.22c-9.65 6.76-21 8.95-30.34 5.83-3.4-1.13-6.23-2.89-8.35-5.15 32.92-9.97 56.03-40.48 56.03-75.49 0-43.42-35.32-78.74-78.74-78.74-43.42 0-78.74 35.32-78.74 78.74 0 39.76 28.84 72.56 67.78 78.03 4.04 13.8 14.1 24.85 28.15 30.73 7.19 3.01 14.91 4.49 22.75 4.49 13.1 0 26.55-4.12 38.54-12.14l4.93-3.3-16.75-26.68-5.26 3.68zm-61.4-120.4c25.67 0 45.78 20.03 45.78 45.59 0 25.57-20.11 45.59-45.78 45.59-25.57 0-45.59-20.03-45.59-45.59-.01-25.56 20.02-45.59 45.59-45.59zM472.24 774.36c0 14.94-11.88 25.78-28.24 25.78-16.25 0-28.05-10.84-28.05-25.78v-97.8h-33.34v101.57c0 30.8 26.39 54.02 61.38 54.02s61.38-23.23 61.38-54.02V676.56h-33.15v97.8zM591.01 676.56l-61.69 153.71h36.24l11.7-30.57h59.52l11.7 30.57h36.05l-61.69-153.71h-31.83zm-1.71 91.88l17.72-46.12 17.72 46.12H589.3zM815.41 772.07l-71.6-95.51H713.9v153.71h33.34v-95.65l71.4 95.65h29.92V676.56h-33.15zM880.64 708.57h37.92v121.7h33.15v-121.7h37.73v-32.01h-108.8zM1026.95 676.56h33.34v153.71h-33.34zM1136.57 676.56h-33.33v153.71h83.71v-32.02h-50.38zM1308.89 708.2v-31.64h-92.57v153.71h92.57v-31.64h-59.24v-29.3h51.32V737.5h-51.32v-29.3zM1252.21 1264.04c0 22.9-18.68 41.58-41.58 41.58s-41.58-18.68-41.58-41.58 18.69-41.58 41.58-41.58c22.89 0 41.58 18.68 41.58 41.58zm-6.37 0c0-19.55-15.55-34.99-35.21-34.99-19.55 0-34.99 15.44-34.99 34.99 0 19.55 15.44 34.99 34.99 34.99 19.65.01 35.21-15.44 35.21-34.99zm-27.98 2.59l10.04 18.36h-6.8l-9.72-17.5h-5.94v17.5h-5.94v-41.91h13.82c6.91 0 12.42 5.4 12.42 12.2.01 5.2-3.23 9.63-7.88 11.35zm-12.42-5.07h7.88c3.56 0 6.37-2.81 6.37-6.27 0-3.46-2.81-6.26-6.37-6.26h-7.88v12.53zM622.34 595.19H191.11C258.93 348.24 485.01 166.8 753.5 166.8c190.31 0 359.28 91.18 465.72 232.2-62.13-36.4-134.45-57.3-211.66-57.3-172.67 0-320.95 104.39-385.22 253.49zm-129.9 563.11c-77.21 0-149.53-20.89-211.66-57.3 106.44 141.03 275.42 232.2 465.72 232.2 268.49 0 494.57-181.45 562.39-428.39H877.66c-64.27 149.1-212.55 253.49-385.22 253.49z"
            />
        </svg>
    );
};

export default QuantileLogo;
