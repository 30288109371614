import React from 'react';
import styled, { css } from 'styled-components';
import { ScreenReaderText, media } from 'donut-ui';
import { i18n, i18nWithHTML } from '@i18n/format';
import { TOOL_AUDIENCES } from '~/constants/tools';
import {
    lqAcademy,
    lqAcademyLexileCourse,
    lqAcademyQuantileCourse
} from '@constants/externalLinks';
import EducatorAcademyPromo from '~/images/EducatorAcademy.png';
import Link from '@ui/actions/Link';
import Button from '@ui/actions/Button';
import LaunchIcon from '@ui/icons/LaunchIcon';
import { clickedLQAcademyCard } from '@utilities/analytics/analyticsEvents';
import ToolListHeader from './ToolListHeader';
import { useToolFilter } from './toolTypeFilters';

const layout = css`
    ${ToolListHeader} {
        margin: 2rem 3rem;
        width: calc(100% - 6rem);
    }

    .professional-learning-body {
        padding: 0 3rem;
        background-color: var(--theme-white2);
    }
`;

const LQAcademy = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    max-width: 1080px;

    .academy-logo {
        width: 348px;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;

        .keith-image {
            width: 348px;
            object-fit: fill;
            margin-bottom: -4px;
        }

        .new-sash {
            width: 95px;
            position: absolute;
            left: -14px;
            top: 42px;
        }

        ${LaunchIcon} {
            position: absolute;
            top: 8px;
            right: 8px;
            height: 24px;
            width: 24px;

            color: var(--theme-white);

            :focus,
            :hover {
                box-shadow: 0 0 3px var(--theme-white);
            }
        }
    }

    .academy-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: 20px 32px;

        h3 {
            ${media.largeDesktop`
                font-size: 1.125rem;
            `}

            font-size: 1.25em;
            font-weight: bold;
            margin-bottom: 16px;
        }

        ul {
            margin: 0 0 16px;
        }

        p {
            margin-bottom: 16px;
        }

        ${Button} {
            align-self: flex-end;
        }
    }
`;

const CourseLink = ({ name, url }) => (
    <Link
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={clickedLQAcademyCard}
    >
        {name}
    </Link>
);

const courseLinks = [
    lexile => <CourseLink name={lexile} url={lqAcademyLexileCourse} />,
    quantile => <CourseLink name={quantile} url={lqAcademyQuantileCourse} />
];

const ProfessionalLearning = ({ className }) => {
    const [filter] = useToolFilter();

    if (filter !== TOOL_AUDIENCES.ALL && filter !== TOOL_AUDIENCES.EDUCATORS) {
        return null;
    }

    return (
        <section className={className}>
            <ToolListHeader allTools>
                <h2>{i18n('tools.professionalDevelopment')}</h2>
            </ToolListHeader>
            <div className="professional-learning-body">
                <LQAcademy>
                    <div className="academy-logo">
                        <Link
                            href={lqAcademy}
                            target="_blank"
                            rel="noreferrer noopener"
                            onClick={clickedLQAcademyCard}
                        >
                            <ScreenReaderText>
                                {i18n('educatorAcademy.title')}
                            </ScreenReaderText>
                            <img
                                className="keith-image"
                                src={EducatorAcademyPromo}
                                alt="Educator Academy"
                                role="presentation"
                            />
                            <LaunchIcon />
                        </Link>
                    </div>
                    <div className="academy-description">
                        <h3>{i18n('educatorAcademy.title')}</h3>
                        <p>{i18n('educatorAcademy.description')}</p>
                        <p>{i18n('educatorAcademy.becomeCertified')}</p>
                        <ul>
                            <li>{i18n('educatorAcademy.lexileCourse')}</li>
                            <li>{i18n('educatorAcademy.quantileCourse')}</li>
                        </ul>
                        <h3>{i18n('educatorAcademy.readingAndMath')}</h3>
                        <p>
                            {i18nWithHTML(
                                'educatorAcademy.dualCertification',
                                courseLinks
                            )}
                        </p>
                        <Button
                            as="a"
                            href={lqAcademy}
                            target="_blank"
                            rel="noreferrer noopener"
                            onClick={clickedLQAcademyCard}
                            $studentFacing
                        >
                            {i18n('educatorAcademy.learnMore')}
                        </Button>
                    </div>
                </LQAcademy>
            </div>
        </section>
    );
};

export default styled(ProfessionalLearning)`
    ${layout}
`;
