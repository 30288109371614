import styled, { css } from 'styled-components';

export const visuallyHidden = css`
    position: absolute !important;
    display: block;
    visibility: visible;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    white-space: nowrap;
`;

const ScreenReaderText = styled.span`
    ${visuallyHidden}
`;

export default ScreenReaderText;
