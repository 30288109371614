import { differenceInMilliseconds } from 'date-fns';

const stopwatch = startTime => {
    return {
        start: () => stopwatch(new Date()),
        timeElapsed: () => differenceInMilliseconds(new Date(), startTime),
        reset: () => [
            differenceInMilliseconds(new Date(), startTime),
            stopwatch(new Date())
        ]
    };
};

stopwatch.start = () => stopwatch().start();
stopwatch.timeElapsed = watch => watch.timeElapsed();
stopwatch.reset = watch => watch.reset();

export default stopwatch;
