import React from 'react';
import styled, { css } from 'styled-components';
import { i18n } from '@i18n/format';
import { whenProvided } from '@utilities/stylingUtils';
import { length } from '@utilities/dataTypes/arrays';
import Date from '@ui/data/Date';
import IconButton from '@ui/actions/IconButton';
import CloseIcon from '@ui/icons/CloseIcon';
import { parseEmojiCode } from '@ui/Emoji';
import { media } from '@utilities/stylingUtils';

const layout = css`
    width: 300px;
    z-index: 1;
    background: var(--theme-white);
    border-bottom: 5px solid var(--theme-blue4);
    box-shadow: var(--elevated-dark);

    ${media.phone`
        width: 200px;
    `}

    &:after {
        content: '';
        position: absolute;
        top: -6px;
        right: 24px;
        transform: rotate(45deg);
        height: 14px;
        width: 14px;
        background: var(--theme-blue4);
    }

    h2 {
        padding: 12px 16px;
        background-color: var(--theme-blue4);
        color: var(--theme-white);
        font-size: 0.875rem;
        font-weight: normal;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: calc(100vh - 120px);
        overflow-x: hidden;
        overflow-y: scroll;
        border-left: 1px solid var(--theme-gray2);
        border-right: 1px solid var(--theme-gray2);

        ::-webkit-scrollbar {
            width: 6px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #88888875;
            border-radius: 6px;
        }
    }
`;

const Notification = styled.li`
    display: flex;
    justify-content: space-between;

    line-height: 1.5;
    font-size: 0.875rem;
    color: var(--theme-less-dark-gray);
    padding: 12px 8px 8px 12px;
    margin: 0 4px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--theme-gray2);
    }

    ${Date} {
        display: block;
        font-style: normal;
        color: var(--theme-gray3);
        margin-top: 12px;
    }

    ${IconButton} {
        height: 18px;
        width: 18px;
        padding: 2px;
        box-sizing: content-box;

        svg {
            height: 18px;
            width: 18px;
            color: var(--theme-gray3);
        }
    }

    ${whenProvided('onClick')`
        cursor: pointer;
        
        :hover {
            background: var(--theme-white2);
        }
    `}
`;

const EmptyNotifications = styled.li`
    padding: 40px 0;
    text-align: center;
    font-style: italic;
    font-size: 0.875rem;
    color: var(--theme-less-dark-gray);
`;

const NotificationList = ({
    className,
    notifications,
    deleteNotification,
    onNotificationClick
}) => {
    const notificationCount = length(notifications);
    return (
        <div className={className}>
            <h2>{i18n('account.notifications')}</h2>
            <ul>
                {notificationCount <= 0 ? (
                    <EmptyNotifications>
                        {i18n('account.notificationsEmpty')}
                    </EmptyNotifications>
                ) : (
                    notifications.map(notification => {
                        return (
                            <Notification
                                key={notification.id}
                                onClick={() => {
                                    onNotificationClick &&
                                        onNotificationClick(notification);
                                }}
                            >
                                <div className="notification-content">
                                    <p className="notification-body">
                                        {parseEmojiCode(
                                            notification.content.body
                                        )}
                                    </p>
                                    <Date
                                        timestamp={notification.created}
                                        secondsTimestamp
                                    />
                                </div>
                                <IconButton
                                    onClick={event => {
                                        event.stopPropagation();
                                        deleteNotification(notification);
                                    }}
                                    aria-label={i18n(
                                        'account.notificationClose'
                                    )}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Notification>
                        );
                    })
                )}
            </ul>
        </div>
    );
};

export default styled(NotificationList)`
    ${layout}
`;
