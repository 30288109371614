import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { downloadWordList } from '@api/wordLists';
import baseRoutes from '@constants/routes';
import RegistrationEmail from '@features/dashboard/registration/RegistrationEmail';
import SignInForm from '@features/dashboard/signIn/SignInForm';
import Confirmation from '@features/dashboard/registration/Confirmation';
import DashboardTabs from '@features/dashboard/account/DashboardTabs';
import AllTools from '@features/dashboard/home/AllTools';
import FamilyHomeRedirect from '@features/dashboard/home/FamilyHomeRedirect';
import QuantileTool from '@features/quantile/QuantileTool';
import QSCDetails from '@features/mathSkillsDB/qscDetails/QSCDetails';
import StripeWrapper from '@features/dashboard/billing/StripeWrapper';
import ToolRedirects from '@ui/navigation/ToolRedirects';
import { Lexile, Quantile } from '@ui/Scale';
import Loader from '@ui/Loader';
import MainErrorBoundary from './MainErrorBoundary';
import SMCNotFound from '@features/SMCNotFound';

const Account = lazy(() => import('@features/dashboard/account/Account'));
const RegistrationForm = lazy(() =>
    import('@features/dashboard/registration/RegistrationForm')
);
const RequestPasswordForm = lazy(() =>
    import('@features/dashboard/signIn/RequestPasswordForm')
);
const ResetPasswordForm = lazy(() =>
    import('@features/dashboard/signIn/ResetPasswordForm')
);
const MembershipOptions = lazy(() =>
    import('@features/dashboard/membershipOptions/MembershipOptions')
);
const Welcome = lazy(() => import('@features/dashboard/welcome/Welcome'));
const BillingForm = lazy(() =>
    import('@features/dashboard/billing/BillingForm')
);

const MeasuresManager = lazy(() => import('@features/mmm/MeasuresManager'));
const Analyzer = lazy(() => import('@features/textAnalyzer/TextAnalyzer'));
const CareerDB = lazy(() => import('@features/careerDatabase/CareerDB'));
const FindABook = lazy(() => import('@features/findABook/FindABook'));
const FindADecodableBook = lazy(() =>
    import('@features/findADecodableBook/FindADecodableBook')
);
const WordLists = lazy(() => import('@features/wordLists/WordLists'));
const MathSkillsDB = lazy(() => import('@features/mathSkillsDB/MathSkillsDB'));
const MathAtHome = lazy(() => import('@features/mathAtHome/MathAtHome'));
const ResourceCenter = lazy(() =>
    import('@features/quantileResourceCenter/ResourceCenter')
);
const QuantileTeacherAssistant = lazy(() =>
    import('@features/quantileTeacherAssistant/QuantileTeacherAssistant')
);
const GrowthPlanner = lazy(() =>
    import('@features/growthPlanner/GrowthPlanner')
);
const Norms = lazy(() => import('@features/norms/Norms'));
const DecodablePassages = lazy(() =>
    import('@features/decodablePassages/DecodablePassages')
);

const ToolRoutes = () => {
    return (
        <MainErrorBoundary>
            <Suspense fallback={<Loader individualState />}>
                <Switch>
                    <Route
                        exact
                        path={baseRoutes.HOME_PAGE}
                        render={({ location }) => (
                            <React.Fragment>
                                <DashboardTabs />
                                <AllTools location={location} />
                            </React.Fragment>
                        )}
                    />
                    <Route
                        path={baseRoutes.ACCOUNT}
                        render={() => <Account />}
                    />
                    <Route
                        path={baseRoutes.FEDERATED_SIGN_IN}
                        render={() => <Redirect to={baseRoutes.HOME_PAGE} />}
                    />
                    <Route
                        path={baseRoutes.SIGN_IN}
                        render={() => <SignInForm />}
                    />
                    <Route
                        path={baseRoutes.VERIFY_EMAIL}
                        render={() => <Redirect to={baseRoutes.HOME_PAGE} />}
                    />
                    <Route
                        path={baseRoutes.FAMILY_HOME}
                        render={() => <FamilyHomeRedirect />}
                    />
                    <Route
                        path={baseRoutes.REQUEST_PASSWORD}
                        render={() => <RequestPasswordForm />}
                    />
                    <Route
                        path={baseRoutes.RESET_PASSWORD}
                        render={({ location }) => (
                            <ResetPasswordForm location={location} />
                        )}
                    />
                    <Route
                        path={baseRoutes.REGISTRATION}
                        render={() => <RegistrationForm />}
                    />
                    <Route
                        path={baseRoutes.REGISTRATION_EMAIL}
                        render={() => <RegistrationEmail />}
                    />
                    <Route
                        path={baseRoutes.CONFIRM}
                        render={() => <Confirmation />}
                    />
                    <Route
                        path={baseRoutes.WELCOME}
                        render={() => <Welcome />}
                    />
                    <Route
                        path={baseRoutes.MEMBERSHIP_OPTIONS}
                        render={() => <MembershipOptions />}
                    />
                    <Route
                        path={baseRoutes.BILLING}
                        render={() => (
                            <StripeWrapper>
                                <BillingForm />
                            </StripeWrapper>
                        )}
                    />
                    <Route
                        path={baseRoutes.TEXT_ANALYZER}
                        render={() => <Analyzer />}
                    />
                    <Route
                        path={baseRoutes.FIND_A_DECODABLE_BOOK}
                        render={({ history, location }) => (
                            <FindADecodableBook
                                history={history}
                                location={location}
                            />
                        )}
                    />
                    <Route
                        path={baseRoutes.FIND_A_BOOK}
                        render={({ history, location }) => (
                            <FindABook history={history} location={location} />
                        )}
                    />
                    <Route
                        path={baseRoutes.WORDLISTS}
                        render={() => <WordLists onSubmit={downloadWordList} />}
                    />
                    <Route
                        path={baseRoutes.CAREER_DATABASE_LEXILE}
                        render={() => (
                            <Lexile>
                                <CareerDB />
                            </Lexile>
                        )}
                    />
                    <Route
                        path={baseRoutes.CAREER_DATABASE_QUANTILE}
                        render={() => (
                            <Quantile>
                                <CareerDB />
                            </Quantile>
                        )}
                    />
                    <Route
                        path={baseRoutes.MMM_LEXILE}
                        render={() => (
                            <Lexile>
                                <MeasuresManager />
                            </Lexile>
                        )}
                    />
                    <Route
                        path={baseRoutes.MMM_QUANTILE}
                        render={() => (
                            <Quantile>
                                <MeasuresManager />
                            </Quantile>
                        )}
                    />
                    <Route
                        path={baseRoutes.GROWTH_PLANNER_LEXILE}
                        render={() => (
                            <Lexile>
                                <GrowthPlanner />
                            </Lexile>
                        )}
                    />
                    <Route
                        path={baseRoutes.GROWTH_PLANNER_QUANTILE}
                        render={() => (
                            <Quantile>
                                <GrowthPlanner />
                            </Quantile>
                        )}
                    />
                    <Route
                        path={baseRoutes.NORMS_LEXILE}
                        render={() => (
                            <Lexile>
                                <Norms />
                            </Lexile>
                        )}
                    />
                    <Route
                        path={baseRoutes.NORMS_QUANTILE}
                        render={() => (
                            <Quantile>
                                <Norms />
                            </Quantile>
                        )}
                    />
                    <Route
                        path={baseRoutes.MATH_SKILLS_DATABASE}
                        render={() => (
                            <QuantileTool>
                                <MathSkillsDB />
                            </QuantileTool>
                        )}
                    />
                    <Route
                        path={baseRoutes.MATH_AT_HOME}
                        render={() => (
                            <QuantileTool>
                                <MathAtHome />
                            </QuantileTool>
                        )}
                    />
                    <Route
                        path={baseRoutes.SUMMER_MATH_CHALLENGE}
                        render={() => <SMCNotFound />}
                    />
                    <Route
                        path={baseRoutes.QUANTILE_TEACHER_ASSISTANT}
                        render={() => <QuantileTeacherAssistant />}
                    />
                    <Route
                        path={`${baseRoutes.QSC_DETAIL}/:id`}
                        render={({ match, location }) => {
                            const qscId = match.params.id;
                            if (qscId === '998' || qscId === '999') {
                                return <Redirect to={baseRoutes.NOT_FOUND} />;
                            }

                            return (
                                <QuantileTool>
                                    <QSCDetails
                                        qscId={match.params.id}
                                        location={location}
                                    />
                                </QuantileTool>
                            );
                        }}
                    />
                    <Route
                        path={baseRoutes.RESOURCE_CENTER}
                        render={({ location }) => (
                            <QuantileTool forceRefresh key="resources">
                                <ResourceCenter location={location} />
                            </QuantileTool>
                        )}
                    />
                    <Route
                        path={baseRoutes.DECODABLE_PASSAGES}
                        render={() => (
                            <Lexile>
                                <DecodablePassages />
                            </Lexile>
                        )}
                    />
                    <ToolRedirects />
                </Switch>
            </Suspense>
        </MainErrorBoundary>
    );
};

export default ToolRoutes;
