import React from 'react';
import styled, { css } from 'styled-components';
import { i18n } from '@i18n/format';

const layout = css`
    font-size: 0.875rem;
    margin-bottom: 6px;
`;

const RequiredFieldLabel = ({ className }) => {
    return <p className={className}>{i18n('common.requiredFields')}</p>;
};

export default styled(RequiredFieldLabel)`
    ${layout}
`;
