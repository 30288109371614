import React from 'react';
import styled from 'styled-components';
import baseRoutes from '@constants/routes';
import { NavLink } from 'react-router-dom';
import Tier, { IdentityProvider, tiers } from '@ui/layouts/Tier';
import { providers } from '@constants/identityProviders';
import { i18n } from '@i18n/format';
import { routes } from './accountRoutes';

const TabContainer = styled.nav`
    width: 100%;
    border-bottom: 1px solid var(--theme-gray2);
`;

const Tab = styled(NavLink)`
    display: inline-block;
    text-decoration: none;
    color: var(--theme-less-dark-gray);
    padding: 16px 24px 12px;
    font-size: 0.875rem;

    &.active,
    :hover {
        color: var(--theme-dark-gray);
    }

    &.active {
        font-weight: bold;
        border-bottom: 3px solid var(--theme-blue4);
    }
`;

const DashboardTabs = () => {
    return (
        <Tier level={[tiers.BASIC, tiers.PRO]}>
            <TabContainer>
                <Tab exact to={baseRoutes.HOME_PAGE}>
                    {i18n('tools.all')}
                </Tab>
                <Tab to={routes.PROFILE}>{i18n('common.profile')}</Tab>
                <IdentityProvider provider={providers.COGNITO}>
                    <Tab to={routes.CHANGE_PASSWORD}>
                        {i18n('account.changePwd')}
                    </Tab>
                </IdentityProvider>
                <Tab to={routes.MAILING_LISTS}>
                    {i18n('account.mailingLists')}
                </Tab>
                <Tab to={routes.MEMBERSHIP_PLAN}>
                    {i18n('account.membershipPlan')}
                </Tab>
            </TabContainer>
        </Tier>
    );
};

export default DashboardTabs;
