/*
    Analytics utilities for Google Tag Manager (a Google Analytics
    product). We've worked with an outside SME (Cardinal Path, winter
    2021/22) on usage analytics for setting that platform up to our
    liking. In-code analytics logic should be relatively minimal
    using this approach.
*/
const createGAEvent = (eventName, body = {}) => {
    window['dataLayer'] &&
        window['dataLayer'].push({
            event: eventName,
            ...body
        });
};

export const setGoogleAnalyticsUserInfo = user =>
    createGAEvent('e_userDetails', {
        user_id: user?.uuid,
        provider: user?.identityProvider || 'none',
        role: user?.role
    });

export const upgradedToBasicPlus = () => createGAEvent('upgradedToBasicPlus');

export const upgradedToPremium = () => createGAEvent('upgradedToPremium');

export const performedFADBSearch = vowelSounds =>
    createGAEvent('performedFADBSearch', {
        fadb_vowel_sounds: vowelSounds
    });

export const upgradedFromDecodablePassagesSplash = () =>
    createGAEvent('upgradedFromDecodablePassagesSplash');

export const signedInFromDecodablePassagesSplash = () =>
    createGAEvent('signedInFromDecodablePassagesSplash');

export const conductedSoundSpellingSearch = phonemeAndRole =>
    createGAEvent('conductedSoundSpellingSearch', phonemeAndRole);

export const clickedAuthorLinkDecodablePassages = authorAndRole =>
    createGAEvent('clickedAuthorLinkDecodablePassages', authorAndRole);

export const printedDecodablePassage = idAndRole =>
    createGAEvent('printedDecodablePassage', idAndRole);
