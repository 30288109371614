export const isEmpty = value =>
    value === undefined || value === null || value === '';

const NullableValue = ({ placeholder, children }) => {
    if (isEmpty(children)) {
        return placeholder;
    }

    return children;
};

export default NullableValue;
