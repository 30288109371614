import React from 'react';
import styled, { css } from 'styled-components';
import NewHorizontal from '~/images/NewHorizontalSash.png';
import Button from '@ui/actions/Button';
import { i18n } from 'i18n/format';
import { phraseListPDF } from '@constants/externalLinks';

const layout = css`
    background: var(--theme-white2);
    position: relative;
    padding: 48px 30px 24px;
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 1.125rem;
        font-weight: bold;
    }

    p {
        margin-top: 12px;
    }

    img {
        position: absolute;
        top: 0;
        left: -15px;
        height: 62px;
    }

    a {
        align-self: flex-end;
        margin-top: auto;

        ${Button} {
            padding: 12px 16px;
            align-self: flex-end;
        }
    }
`;

const ResourceCard = ({ className, title, description, newBanner = false }) => (
    <div className={className}>
        <h3>{title}</h3>
        <p>{description}</p>
        {newBanner && <img src={NewHorizontal} alt="" />}
        <a href={phraseListPDF} rel="noreferrer noopener" target="_blank">
            <Button>{i18n('common.downloadPDF')}</Button>
        </a>
    </div>
);

export default styled(ResourceCard)`
    ${layout}
`;
