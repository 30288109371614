import { makeEndpoint, tiered } from './httpRequest';
import createStaticResource from '@utilities/staticResources';
import { concat } from '@utilities/dataTypes/arrays';
import { update } from '@utilities/dataTypes/objects';
import { stringsToLabelValue } from '@utilities/forms';

const NAME = process.env.REACT_APP_CDB_NAME;
const ENDPOINT = process.env.REACT_APP_CDB_URL;

const cdbApi = makeEndpoint(NAME, ENDPOINT);
export const careerDbRoot = cdbApi.configure();

const configureFieldDataEndpoint = queryParams =>
    createStaticResource(() => {
        return cdbApi.get('/fields-data', queryParams).then(res => {
            const careersBySOC = {};

            const careersByFieldName = res.data.reduce(
                (acc, { field_name, career_list }) => {
                    //Parse through the career list to format the careers for the autocomplete

                    let formattedCareerList = [];
                    career_list.forEach(({ career_name, soc_code }) => {
                        const career = careersBySOC[soc_code] || {
                            label: career_name,
                            value: soc_code
                        };
                        career.fields = concat(career.fields, field_name);
                        formattedCareerList.push(career);
                        careersBySOC[soc_code] = career;
                    });

                    return {
                        fields: concat(acc.fields, field_name),
                        careers: update(
                            acc.careers,
                            field_name,
                            formattedCareerList
                        )
                    };
                },
                { fields: [], careers: {} }
            );

            return {
                careersByField: careersByFieldName.careers,
                fieldOptions: stringsToLabelValue(careersByFieldName.fields),
                allCareerOptions: Object.values(careersBySOC)
            };
        });
    });

export const fetchAllFieldsAndCareers = configureFieldDataEndpoint();

export const fetchLexileFieldsAndCareers = configureFieldDataEndpoint({
    scale: 'lexile'
});

export const fetchQuantileFieldsAndCareers = configureFieldDataEndpoint({
    scale: 'quantile'
});

export const fetchCareerDetails = socCode => {
    return tiered(cdbApi.get)(`/careers/${socCode}`);
};

export const getRelatedCareers = ({ scale, field, socCode }) =>
    tiered(cdbApi.get)('/career-cluster', { scale, field, soc: socCode });

export const fetchCareerDBVersion = () => cdbApi.get('/version');

export const demandQuotients = (socCode, useNationalDomain) =>
    tiered(cdbApi.get)(`/career-demand/${socCode}`, {
        domain: useNationalDomain ? 'national' : 'msa'
    });

export const stateProjections = (socCode, state) =>
    tiered(cdbApi.get)(`/career-state/${socCode}`, { state });

export const getSearchCount = () => cdbApi.get('/search-count');
