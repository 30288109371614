import React from 'react';

const ChevronDown = props => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.9959 4.35L6.26516 9.12693C6.1267 9.26539 5.89593 9.26539 5.75747 9.12693L1.0267 4.35C0.888236 4.21154 0.888236 3.98077 1.0267 3.84231L1.53439 3.33462C1.67285 3.19616 1.90362 3.19616 2.04208 3.33462L5.75747 7.09616C5.89593 7.23462 6.1267 7.23462 6.26516 7.09616L9.98054 3.3577C10.119 3.21924 10.3498 3.21924 10.4882 3.3577L10.9959 3.86539C11.1113 4.00385 11.1113 4.21154 10.9959 4.35V4.35Z"
            fill="currentColor"
        />
        <mask
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="3"
            width="12"
            height="7"
        >
            <path
                d="M10.9959 4.35L6.26516 9.12693C6.1267 9.26539 5.89593 9.26539 5.75747 9.12693L1.0267 4.35C0.888236 4.21154 0.888236 3.98077 1.0267 3.84231L1.53439 3.33462C1.67285 3.19616 1.90362 3.19616 2.04208 3.33462L5.75747 7.09616C5.89593 7.23462 6.1267 7.23462 6.26516 7.09616L9.98054 3.3577C10.119 3.21924 10.3498 3.21924 10.4882 3.3577L10.9959 3.86539C11.1113 4.00385 11.1113 4.21154 10.9959 4.35V4.35Z"
                fill="white"
            />
        </mask>
    </svg>
);

export default ChevronDown;
