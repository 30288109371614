import { useState, useEffect } from 'react';

//Each entry in subscribers is a Set of setState functions
//to let React know to update local component state
const subscribers = {};

//Don't collide with any other sessionStorage
const namespace = prop => `__mm-${prop}`;

const sessionStorageProxy = new Proxy(sessionStorage, {
    get(_, prop) {
        //override default 'get' to pull from sessionStorage
        return sessionStorage.getItem(namespace(prop));
    },
    set(_, prop, value) {
        //override default set (obj.prop = 5) to add to sessionStorage
        //and notify listeners
        sessionStorage.setItem(namespace(prop), value);

        subscribers[prop] &&
            subscribers[prop].forEach(setState => setState(value));

        return value;
    }
});

export const useSessionState = (name, initialValue) => {
    const [value, setValue] = useState(
        sessionStorageProxy[name] || initialValue
    );

    useEffect(() => {
        //set this value in sessionStorage if it doesn't exist yet
        if (!sessionStorageProxy[name]) {
            sessionStorageProxy[name] = initialValue;
        }

        //Create subscription set for 'name'
        if (!subscribers[name]) {
            //Set works here becaues setValue from useState will refer to the exact same fn
            //between renders and we wouldn't want to notify the same component
            //multiple times
            subscribers[name] = new Set();
        }

        subscribers[name].add(setValue);

        return () => subscribers[name].delete(setValue);
    }, [name, initialValue]);

    return [
        value,
        value => {
            sessionStorageProxy[name] = value;
        }
    ];
};
