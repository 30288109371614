const numberFormat = new Intl.NumberFormat();

const currencyNumberFormat = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const formatWithLocale = number => numberFormat.format(number);

export const currencyFormatWithLocale = number =>
    currencyNumberFormat.format(number);

export const secToMs = secondsStr => {
    let parsedInt = parseInt(secondsStr);
    return parsedInt ? parsedInt * 1000 : '';
};
