import styled, { css } from 'styled-components';

export const Circle = styled.div`
    border: solid 2px var(--theme-white);
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
        font-weight: bold;
        color: var(--theme-white);
    }

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
`;

export const contentStyles = css`
    .content {
        display: flex;
        justify-content: center;

        > :last-child {
            background: var(--theme-white);
            width: fit-content;
            padding: 2px 8px;
            border-radius: 4px;
            text-transform: uppercase;
            font-weight: bold;
            color: initial;
        }
    }
`;

export const layout = css`
    background-color: #f2f0ee;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 100px;

    position: relative;

    z-index: 1;

    .title {
        width: 35%;
        font-weight: bold;
        font-size: 1.625rem;

        position: absolute;
        top: 10px;
        left: 20px;
    }

    .student-measure {
        position: absolute;
        color: var(--theme-white);
        font-weight: bold;
        font-size: 1.5rem;
    }

    img {
        width: 90%;
        filter: drop-shadow(0 -2px 5px rgba(0, 0, 0, 0.25));
        z-index: -1;
    }
`;
