import styled from 'styled-components';

const ValidationWarning = styled.p`
    padding: 0;
    margin: 0;
    font-size: 0.75rem;
    color: var(--theme-red);
`;

export default ValidationWarning;
