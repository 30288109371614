import React from 'react';
import { Router } from 'react-router-dom';
import qs from 'qs';
import { pluck } from '@utilities/dataTypes/objects';

let history;

export const isInitialized = () => !!history;

export const initialize = historyInstance => (history = historyInstance);

export const listen = f => history.listen(f);

export const block = f => history.block(f);

export const pushTo = (path, state) =>
    history.push(
        !state
            ? path
            : {
                  pathname: path,
                  state
              }
    );

export const goBack = () => history.goBack();

export const origin = () => {
    const loc = window.location;
    return `${loc.protocol}//${loc.host}`;
};

export const location = () => history.location;

export const locationState = () => history.location.state;

export const queryParams = () =>
    pluck('search')(history.location)
        .map(queryParams => qs.parse(queryParams, { ignoreQueryPrefix: true }))
        .orElse({});

export const pathStartsWith = str => history.location.pathname.startsWith(str);

export const getReferrer = () => locationState()?.referrer;

export const referredBy = expectedReferrer =>
    getReferrer() === expectedReferrer;

export const AppRouter = ({ children }) => (
    <Router history={history}>{children}</Router>
);
