import React from 'react';
import styled, { css } from 'styled-components';
import { Circle } from '@features/dashboard/home/whatAreMeasures/measuresArtStyles';
import BookOpen from '@ui/icons/BookOpen';

const easyLayout = css`
    ${Circle} {
        background-color: #00408b;
        height: 80px;
        width: 80px;
    }

    display: flex;
    align-items: center;

    position: absolute;
    bottom: 65px;
    left: 5px;

    .content {
        margin-left: 8px;
        flex-direction: column;
    }
`;

const onTargetLayout = css`
    position: absolute;
    top: 22px;
    width: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;

    ${Circle} {
        background-color: #1c2534;
        height: 105px;
        width: 105px;
        position: absolute;
    }

    .content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        position: absolute;
        top: 90px;

        // on target text
        > :last-child {
            background-color: var(--theme-white);
            font-size: 1.325rem;
            text-transform: uppercase;

            position: relative;

            padding: 3px 8px;
            border-radius: 4px;

            // bracket styles
            &:before,
            &:after {
                content: '';
                width: 20px;
                height: 40px;
                background-color: transparent;

                position: absolute;
                top: -5px;

                border-top: solid 2px rgba(255, 255, 255, 0.7);
                border-bottom: solid 2px rgba(255, 255, 255, 0.7);
            }

            // left bracket
            &:before {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                left: -5px;
                border-left: solid 2px rgba(255, 255, 255, 0.7);
            }

            // right bracket
            &:after {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                right: -5px;
                border-right: solid 2px rgba(255, 255, 255, 0.7);
            }
        }
    }
`;

const hardLayout = css`
    ${Circle} {
        background-color: #1c2534;
        height: 80px;
        width: 80px;
    }

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    position: absolute;
    bottom: 65px;
    right: 5px;

    .content {
        color: var(--theme-white);
        margin-right: 8px;

        flex-direction: column;
        align-items: flex-end;
        text-align: right;
    }
`;

const Book = styled.div``;

export const LexileSection = ({
    className,
    measure,
    title,
    author,
    difficulty
}) => (
    <div className={className}>
        <Circle>
            <span>{measure}</span>
            <BookOpen />
        </Circle>
        <div className="content">
            <Book>
                <span>{title}</span>
                <span>{author}</span>
            </Book>
            <span>{difficulty}</span>
        </div>
    </div>
);

LexileSection.Book = Book;

export const EasyLexileSection = styled(LexileSection)`
    ${easyLayout}
`;

export const OnTargetLexileSection = styled(LexileSection)`
    ${onTargetLayout}
`;

export const HardLexileSection = styled(LexileSection)`
    ${hardLayout}
`;

const QSC = styled.div``;

export const QuantileSection = ({
    className,
    measure,
    qsc,
    difficulty,
    Icon
}) => (
    <div className={className}>
        <Circle>
            <Icon />
        </Circle>
        <div className="content">
            <QSC>
                <span>{measure}</span>
                <span>{qsc}</span>
            </QSC>
            <span>{difficulty}</span>
        </div>
    </div>
);

QuantileSection.QSC = QSC;

export const EasyQuantileSection = styled(QuantileSection)`
    ${easyLayout}
`;

export const OnTargetQuantileSection = styled(QuantileSection)`
    ${onTargetLayout}
`;

export const HardQuantileSection = styled(QuantileSection)`
    ${hardLayout}
`;
