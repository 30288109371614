import React from 'react';

const CareerDBIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 13.6"
            aria-hidden="true"
        >
            <path d="M4.69 1.31C4 .74 3.4.65 2.78 1.37c-.42.48-.82 1-1.23 1.46s-.77.9-1.14 1.36C-.28 5-.07 5.56.82 6.14c.67.43.67.25 1.11-.23L3.4 4.29l1.44-1.58c.09-.1.32-.26.36-.4.12-.31-.26-.8-.51-1zM14.34 8.55c0-.21-.24-.7-.49-.77s-.11 0-.32.13c-.71.28-1 .1-1.7-.27a18.45 18.45 0 0 0-1.76-.78c-.28-.13-.24-.4-.55-.33-.62.14-1.42.93-2 1.11A1.6 1.6 0 0 1 5.87 7a1.45 1.45 0 0 1-.25-1.09l.18-.6a9.93 9.93 0 0 0 .55-1.63c.07-.4-.32-.3-.38-.74L4 5.09 2.66 6.53c-.38.42-.55.4-.21.88a21.66 21.66 0 0 0 3.16 3.86c.84.76 3.52 3.52 4.65 1.75.26-.41.13-.28.6-.28a1.38 1.38 0 0 0 .81-.27 1.94 1.94 0 0 0 .44-.63c.05-.07 0-.25.07-.3s.4-.08.5-.13a1.29 1.29 0 0 0 .54-.47c.16-.25.07-.65.2-.84s.51-.26.66-.45a1.35 1.35 0 0 0 .26-1.1z" />
            <path d="M11.46 1.8a4 4 0 0 0-2.68.78A4.6 4.6 0 0 0 7.69 4c-.36.57-1 1.43-.32 2.05S9 6 9.55 5.6s.84-.63 1.39-.31l1.06.65a5 5 0 0 0 1.55.87 1 1 0 0 0 .93-.33 1.93 1.93 0 0 0 .39-.63c0-.22-.58-.69-.71-.85-.91-1.07-1.81-2.13-2.7-3.2zM16.26 4.88c.38-.32.87-.4.71-1a1.91 1.91 0 0 0-.47-.72l-1.31-1.58c-.34-.41-.67-.84-1-1.22s-.5-.46-.93-.23a12.83 12.83 0 0 0-1 1l1.86 2.21 1.18 1.4c.29.45.38.63.96.14z" />
        </svg>
    );
};

export default CareerDBIcon;
