import { makeEndpoint } from './httpRequest';

const NAME = process.env.REACT_APP_FOUNDATIONAL_READING_NAME;
const ENDPOINT = process.env.REACT_APP_FOUNDATIONAL_READING_URL;

const decodablePassagesApi = makeEndpoint(NAME, ENDPOINT);
export const decodablePassagesRoot = decodablePassagesApi.configure();

export const getAllStories = () => {
    return decodablePassagesApi.get('/api/decodable-passages');
};

export const getPassagesByPhoneme = phoneme => {
    if (phoneme === '') {
        return new Promise((resolve, reject) => {
            return resolve([]);
        });
    }
    return decodablePassagesApi.get(`/api/decodable-passages/${phoneme}`);
};

export const downloadPassagePdf = async passageID => {
    const url = await decodablePassagesApi.get(`/api/passage-pdf/${passageID}`);

    if (url) {
        window.open(url);
    }
    return;
};
