import rand from 'random-key';
import { makeEndpoint } from './httpRequest';
import { injectJwt } from './session';
import { pluckOrFallback, propEquals } from '@utilities/dataTypes/objects';
import {
    asArray,
    concat,
    sortByProp,
    filter
} from '@utilities/dataTypes/arrays';
import { markAllAsRead } from '@utilities/notification';
import { lowercase } from '@utilities/dataTypes/strings';

const NAME = process.env.REACT_APP_UAP_NAME;
const ENDPOINT = process.env.REACT_APP_UAP_URL;

const uapApi = makeEndpoint(NAME, ENDPOINT);
export const uapRoot = uapApi.configure();

export const verifyUpdatedEmail = confirmation_code =>
    injectJwt('accessToken')(access_token =>
        uapApi.post('/accounts/confirm_email_updated', {
            confirmation_code,
            access_token
        })
    );

export const updatePaymentOptions = (autorenew, token) => {
    const request = { autorenew, idempotency_key: rand.generate() };
    if (token) {
        request.stripe_token = token;
    }

    return uapApi.post('/payments/create_or_renew_pro_subscription', request);
};

export const updateUserProfile = async values =>
    uapApi.patch('/accounts/update_profile', {
        'custom:country': values.country,
        'custom:state': values.state,
        'custom:role': values.role,
        given_name: values.givenName,
        family_name: values.familyName,
        email: lowercase(values.email)
    });

export const emailAddressUpdated = () =>
    uapApi.post('/mailing/update_email_info');

export const fetchMailingLists = () => uapApi.get('/mailing/get_all_lists');

export const fetchSubscribedLists = () =>
    uapApi.get('/mailing/get_lists_for_user');

export const updateMailingListSubscriptions = (subscriptions = []) =>
    uapApi.post('/mailing/update_lists_for_user', subscriptions);

export const fetchCardAndSubscription = () =>
    uapApi.get('/payments/get_card_and_subscription_info/');

export const deleteAccount = () => uapApi.patch('/accounts/cancel');

export const stateProEligible = ({ state, email, role }) =>
    uapApi.get('/alerts/state_pro_eligible', {
        state,
        email,
        role
    });

export const fetchFavoriteTools = () => uapApi.get('/favorites');

const toggleFavorite = operation => toolIds =>
    uapApi.patch('/favorites', {
        [operation]: asArray(toolIds)
    });

export const favoriteTools = toggleFavorite('add');
export const unfavoriteTools = toggleFavorite('remove');

export const loadNotifications = () =>
    uapApi.get('/messages').then(messages => {
        const lowPriority = pluckOrFallback('low', [])(messages);
        const highPriority = pluckOrFallback('high', [])(messages);

        return sortByProp('created')(
            concat(lowPriority, markAllAsRead(highPriority)),
            false
        );
    });

export const loadUrgentMessages = () =>
    uapApi
        .get('/messages')
        .then(pluckOrFallback('high', []))
        .then(filter(propEquals('read', false)));

export const markAsRead = id =>
    uapApi.patch(`/messages/${id}`, {
        read: true
    });

export const deleteNotification = id => uapApi.del(`/messages/${id}`);

export const ssoAgreeToTerms = ({ agreedToTerms, subscribeToMailList }) => {
    return uapApi.patch('/user/terms', {
        agreedToTerms,
        subscribeToMailList
    });
};
