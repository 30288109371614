import { useEffect } from 'react';

class UsageTracker {
    constructor() {
        this.reset();
    }

    markToolUsed = toolName => {
        this.toolsUsed = new Set([...this.toolsUsed, toolName]);
    };

    incPageCount = () => {
        this.pagesVisited++;
    };

    reset = () => {
        this.toolsUsed = new Set([]);
        this.pagesVisited = 1;
    };
}

const usageTracker = new UsageTracker();

export default usageTracker;

export const useToolTracking = toolName => {
    useEffect(() => {
        usageTracker.markToolUsed(toolName);
    }, [toolName]);
};
