import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';
import { prepend, identity } from 'ramda';
import { MakeshiftButton, visuallyHidden, useUniqueId } from 'donut-ui';
import { i18n } from '@i18n/format';
import ArrowDropDown from '@ui/icons/ArrowDropDown';
import { useTier } from '@ui/layouts/Tier';
import { pluckOrFallback, propEquals } from '@utilities/dataTypes/objects';
import { sortByProp } from '@utilities/dataTypes/arrays';
import { whenProvided } from '@utilities/stylingUtils';
import InputLabel from '@ui/messages/InputLabel';
import OptionList from './OptionList';
import { SelectableOption } from './Options';
import { notEmpty } from '@utilities/predicates';
import { TooltipWrapper } from '@ui/Tooltip';
import { onEscape } from 'utilities/keyboardEvents';
import useTooltip from 'utilities/hooks/useTooltip';

const layout = css`
    position: relative;

    ${InputLabel} {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 4px;

        ${whenProvided('a11yLabel')`
            ${visuallyHidden}
        `}
    }

    .input-container {
        position: relative;
        width: 100%;
    }

    .label-container {
        display: flex;
        align-items: baseline;
    }

    ${whenProvided('openUpwards')`
         ${OptionList}{
            top: auto;
            bottom: calc(100% + 4px);
         }
    `}
`;

const ToggleButton = styled.div`
    color: var(--theme-dark-gray);
    height: 40px;
    background: var(--theme-white);
    border: 1px solid var(--theme-gray1);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 24px 10px 12px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${whenProvided('disabled')`
        color: var(--theme-gray3);
        cursor: default;
    `}

    .placeholder {
        color: var(--theme-gray3);
        user-select: none; /* Standard */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        -webkit-user-select: none; /* Safari */
    }
`;

const Chevron = styled.div`
    height: 100%;
    width: 32px;
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        display: inline-block;
        background: var(--theme-white);
        height: 5px;
        color: var(--theme-gray3);

        ${whenProvided('disabled')`
            color: var(--theme-gray2);
        `}
    }
`;

const renderOptions = ({
    options,
    highlightedIndex,
    testId,
    getItemProps,
    selectedItem,
    emptyOption,
    userTier
}) => {
    if (emptyOption) {
        options = prepend({ label: emptyOption, value: '' })(options);
    }

    return options.map((item, index) => {
        if (typeof item === 'string') {
            item = { label: item, value: item };
        }

        const isDisabled =
            item.disabledTiers !== undefined &&
            item.disabledTiers.includes(userTier);

        return (
            <SelectableOption
                data-testid={`${testId}-${item.label}`}
                highlighted={highlightedIndex === index && !isDisabled}
                {...getItemProps({
                    refKey: 'innerRef',
                    item,
                    index,
                    key: item.value.key || item.value
                })}
                selected={
                    selectedItem === item.value ||
                    selectedItem?.value === item.value
                }
                disabled={isDisabled}
            >
                {item.label}
            </SelectableOption>
        );
    });
};

const itemToString = item => item?.label || item || '';

const findSelectedOption = (options, value) => {
    const selectedOption = itemToString(
        options.find(propEquals('value', value?.value || value))
    );

    return selectedOption
        ? selectedOption
        : pluckOrFallback('label', value)(value);
};

const addEmptyOption = label =>
    label ? prepend({ label, value: '' }) : identity;
const sortOptions = alreadyOrdered =>
    alreadyOrdered ? identity : sortByProp('label');

const Select = props => {
    const {
        a11yLabel,
        label,
        options = [],
        control = {},
        placeholder = i18n('common.selectAnOption'),
        disabled,
        required,
        onSelect,
        onBlur,
        inputRef,
        alreadyOrdered,
        initialSelectedItem,
        emptyOption,
        className,
        testId,
        tooltip,
        tooltipLeft = false,
        tooltipIcon = true,
        fullWidth
    } = props;

    const { tooltipId, tooltipRef, showTooltip, hideTooltip } = useTooltip();

    const items = useMemo(
        () => addEmptyOption(emptyOption)(sortOptions(alreadyOrdered)(options)),
        [alreadyOrdered, options, emptyOption]
    );

    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps
    } = useSelect({
        items,
        itemToString,
        initialSelectedItem,
        onSelectedItemChange: ({ selectedItem }) => {
            let value = selectedItem;
            if (typeof selectedItem === 'object') {
                value = selectedItem && selectedItem.value;
            }
            onSelect &&
                onSelect(notEmpty(value) ? value : undefined, selectedItem);

            tooltipRef?.current &&
                setTimeout(() => tooltipRef.current.setHidden(true), 0);
        },
        onIsOpenChange: () => {
            tooltipRef?.current &&
                setTimeout(() => tooltipRef.current.setHidden(true), 0);
        },
        ...control
    });

    const userTier = useTier();
    const labelId = useUniqueId('select_label');
    const menuId = useUniqueId('combobox');

    return tooltip ? (
        <div className={className} data-testid={testId}>
            <TooltipWrapper
                id={tooltipId}
                ref={tooltipRef}
                text={tooltip}
                tooltipLeft={tooltipLeft}
                tooltipIcon={tooltipIcon}
                fullWidth={fullWidth}
            >
                {(label || a11yLabel) && (
                    <InputLabel
                        {...getLabelProps()}
                        disabled={disabled}
                        required={required}
                        a11yLabel={!!a11yLabel}
                        as="span"
                        id={labelId}
                    >
                        {label || a11yLabel} {required ? <span>*</span> : null}
                    </InputLabel>
                )}
                <div className="input-container">
                    <MakeshiftButton>
                        {getButtonProps => (
                            <ToggleButton
                                {...getButtonProps(
                                    getToggleButtonProps({
                                        disabled,
                                        ref: inputRef
                                    })
                                )}
                                aria-describedby={tooltipId}
                                onFocus={showTooltip}
                                onBlur={hideTooltip}
                                onKeyUp={onEscape(hideTooltip)}
                                role="combobox"
                                aria-required={required}
                                aria-labelledby={labelId}
                                aria-owns={menuId}
                            >
                                {findSelectedOption(options, selectedItem) || (
                                    <span className="placeholder">
                                        {placeholder}
                                    </span>
                                )}
                                <Chevron disabled={disabled}>
                                    <ArrowDropDown />
                                </Chevron>
                            </ToggleButton>
                        )}
                    </MakeshiftButton>
                </div>
            </TooltipWrapper>
            <OptionList
                isOpen={isOpen && options.length > 0}
                {...getMenuProps({ onBlur })}
                aria-labelledby={labelId}
                id={menuId}
            >
                {renderOptions({
                    options: alreadyOrdered
                        ? options
                        : sortByProp('label')(options || []),
                    highlightedIndex,
                    getItemProps,
                    testId,
                    selectedItem,
                    emptyOption,
                    userTier
                })}
            </OptionList>
        </div>
    ) : (
        <div className={className} data-testid={testId}>
            {(label || a11yLabel) && (
                <div className="label-container">
                    <InputLabel
                        {...getLabelProps()}
                        disabled={disabled}
                        required={required}
                        a11yLabel={!!a11yLabel}
                        as="span"
                        id={labelId}
                    >
                        {label || a11yLabel} {required ? <span>*</span> : null}
                    </InputLabel>
                </div>
            )}
            <div className="input-container">
                <MakeshiftButton>
                    {getButtonProps => (
                        <ToggleButton
                            {...getButtonProps(
                                getToggleButtonProps({
                                    disabled,
                                    ref: inputRef
                                })
                            )}
                            role="combobox"
                            aria-required={required}
                            aria-labelledby={labelId}
                            aria-owns={menuId}
                        >
                            {findSelectedOption(options, selectedItem) || (
                                <span className="placeholder">
                                    {placeholder}
                                </span>
                            )}
                            <Chevron disabled={disabled}>
                                <ArrowDropDown />
                            </Chevron>
                        </ToggleButton>
                    )}
                </MakeshiftButton>
                <OptionList
                    isOpen={isOpen && options.length > 0}
                    {...getMenuProps({ onBlur })}
                    aria-labelledby={labelId}
                    id={menuId}
                >
                    {renderOptions({
                        options: alreadyOrdered
                            ? options
                            : sortByProp('label')(options || []),
                        highlightedIndex,
                        getItemProps,
                        testId,
                        selectedItem,
                        emptyOption,
                        userTier
                    })}
                </OptionList>
            </div>
        </div>
    );
};

Select.ToggleButton = ToggleButton;
Select.Chevron = Chevron;

export default styled(Select)`
    ${layout};
`;
