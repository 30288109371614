import React from 'react';
import { Helmet } from 'react-helmet';
import { i18n } from '@i18n/format';

const SEO = ({ children, ...props }) => {
    return (
        <Helmet
            titleTemplate={`%s | ${i18n('common.hubTitle')}`}
            defaultTitle={i18n('common.hubTitle')}
            {...props}
        >
            {children}
        </Helmet>
    );
};

export default SEO;
