import { createGlobalStyle } from 'styled-components';
import { baseTheme } from './theme';

const GlobalStyle = createGlobalStyle`
    :root {
        ${baseTheme}
        --theme-primary-font: Cabin,sans-serif;
    }

    html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
    }

    *, *::before, *::after {
        box-sizing: inherit;
    }

    html, body, #root {
        overflow: hidden;
        height: 100%;
    }

    body {
        font-family: var(--theme-primary-font);
        color: var(--theme-dark-gray);
    }
    
    * {
        font-family: inherit;
    }
    
    html, body, div, span, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, em, img, strong, b, u, i, form, fieldset, label, table, audio, video {
        border: 0;
        padding: 0;
        margin: 0;
    }
`;

export default GlobalStyle;
