import React from 'react';
import styled from 'styled-components';
import { ScreenReaderText } from 'donut-ui';

const CheckmarkIcon = ({
    fillColor = 'currentColor',
    height = '10px',
    width = '10px',
    title,
    className,
    ariaLabel,
    focusable,
    ariaHidden
}) => {
    return (
        <svg
            role="img"
            fill="none"
            viewBox="0 0 10 7"
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            width={width}
            className={className}
            aria-label={ariaLabel}
            focusable={focusable}
            aria-hidden={ariaHidden}
        >
            {title && <title>{title}</title>}
            <ScreenReaderText>{title}</ScreenReaderText>
            <path
                fill={fillColor}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.0939457 3.51775c-.1252609-.12527-.1252609-.33403 0-.45929l.4592903-.4593c.125261-.12526.334029-.12526.459294 0l2.58872 2.60961c.08351.08351.22965.08351.31316 0L8.98747.0939457c.12526-.1252609.33403-.1252609.45929 0l.45929.4592903c.12525.125261.12525.334029 0 .459294L3.99791 6.9833c-.12526.14614-.33403.14614-.45929 0L.0939457 3.51775z"
            />
        </svg>
    );
};

export default styled(CheckmarkIcon)``;
