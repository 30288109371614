import styled from 'styled-components';
import { whenNotProvided } from '@utilities/stylingUtils';
import { visuallyHidden } from '@ui/messages/ScreenReaderText';

const OptionList = styled.ul`
    position: absolute;
    z-index: 1;
    width: 100%;
    margin: 4px 0 0;

    max-height: 300px;
    overflow-y: scroll;

    background-color: var(--theme-white);

    list-style: none;
    padding: 5px 0;

    border: 1px solid var(--theme-gray1);
    border-radius: 4px;
    box-shadow: var(--elevated-dark);

    ${whenNotProvided('isOpen')`
        ${visuallyHidden}
    `}
`;

export default OptionList;
