import React from 'react';
import styled from 'styled-components';

const BookOpen = ({ className }) => {
    return (
        <svg
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 492 176"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            fill="currentColor"
        >
            <path
                d="M264.442,145.345l215.175,-83.225c4.645,-1.797 9.876,0.516 11.673,5.161c1.796,4.646 -0.516,9.876 -5.162,11.673l-213.337,82.514c-3.106,7.999 -15.941,14.001 -31.283,14.001c-17.638,-0 -31.963,-7.933 -32,-17.714l-203.737,-78.801c-4.646,-1.797 -6.959,-7.027 -5.162,-11.673c1.797,-4.645 7.027,-6.958 11.673,-5.161l210.236,81.315c5.314,-2.178 11.883,-3.466 18.99,-3.466c8.99,-0 17.118,2.06 22.934,5.376Z"
                fill="currentColor"
            />
            <path
                d="M241.437,154.395l-232.524,-89.723l6.48,-16.794c84.348,11.902 160.306,40.398 227.502,86.115c63.452,-43.195 141.004,-70.206 229.096,-86.115l5.966,15.462l-235.976,91.055l-0.07,0.183l-0.202,-0.078l-0.201,0.078l-0.071,-0.183Z"
                fill="#fff"
            />
            <path
                d="M245.464,139.239c-0,0 38.168,-59.644 223.036,-96.541"
                fill="none"
                stroke="#fff"
                strokeWidth="4.17px"
            />
            <path
                d="M244.615,134.818c0,0 36.773,-60.515 220.733,-101.699"
                fill="none"
                stroke="#fff"
                strokeWidth="4.17px"
            />
            <path
                d="M241.114,132.953c0,-0 35.027,-61.542 217.735,-107.968"
                fill="none"
                stroke="#fff"
                strokeWidth="4.17px"
            />
            <path
                d="M238.238,133.854c0,0 28.518,-64.815 205.488,-129.771"
                fill="none"
                stroke="#fff"
                strokeWidth="4.17px"
            />
            <path
                d="M241.275,137.239c-0,0 -38.169,-59.644 -223.037,-96.541"
                fill="none"
                stroke="#fff"
                strokeWidth="4.17px"
            />
            <path
                d="M242.123,132.818c-0,0 -36.772,-60.515 -220.733,-101.699"
                fill="none"
                stroke="#fff"
                strokeWidth="4.17px"
            />
            <path
                d="M245.624,130.953c-0,-0 -35.027,-61.542 -217.735,-107.968"
                fill="none"
                stroke="#fff"
                strokeWidth="4.17px"
            />
            <path
                d="M248.5,131.854c0,0 -28.517,-64.815 -205.487,-129.771"
                fill="none"
                stroke="#fff"
                strokeWidth="4.17px"
            />
        </svg>
    );
};

export default styled(BookOpen)`
    color: var(--theme-green1);
`;
