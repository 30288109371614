import { where, either } from 'ramda';
import { Lexile, SpanishLexile } from 'toastezy';
import { i18n } from '@i18n/format';
import {
    textBounds,
    studentBounds,
    spanishBounds,
    spanishRangeBounds
} from '@utilities/dataTypes/LexileRange';
import { onRange } from '@utilities/dataTypes/ranges';
import { isQSC, uppercase } from '@utilities/dataTypes/strings';
import { isNumber } from '@utilities/dataTypes/typeChecks';
import { asValidator } from '@utilities/dataTypes/functions';
import { between } from '@utilities/mathUtils';
import { isNegative, notEmpty } from '../predicates';
import { clamp } from '../mathUtils';

const UNITS = {
    lexile: {
        prefix: 'BR',
        suffix: 'L'
    },
    quantile: {
        prefix: 'EM',
        suffix: 'Q'
    }
};

const difficultyMeasureToInt = (prefix, suffix) => measure => {
    if (typeof measure !== 'string') {
        return measure;
    }

    measure = measure.toUpperCase();

    if (measure.startsWith(prefix)) {
        measure = measure.replace(prefix, '-');
    }
    if (measure.endsWith(suffix)) {
        measure = measure.replace(suffix, '');
    }
    return Number(measure);
};

export const lexileMeasureToInt = difficultyMeasureToInt(
    UNITS.lexile.prefix,
    UNITS.lexile.suffix
);
export const quantileMeasureToInt = difficultyMeasureToInt(
    UNITS.quantile.prefix,
    UNITS.quantile.suffix
);

const formatDifficultyMeasure = (negativePrefix, suffix) => (
    measure = 0,
    code = ''
) => {
    if (measure === undefined || measure === null) {
        return '';
    }

    const intMeasure = difficultyMeasureToInt(negativePrefix, suffix)(measure);
    const prefix = isNegative(intMeasure) ? negativePrefix : code;

    return `${prefix}${Math.abs(intMeasure)}${suffix}`;
};

export const formatLexileMeasure = (measure, code, isSpanish) => {
    const Measure = isSpanish ? SpanishLexile : Lexile;

    return Measure(measure, code).toString();
};

export const formatQuantileMeasure = formatDifficultyMeasure(
    UNITS.quantile.prefix,
    UNITS.quantile.suffix
);

export const BOUNDS = {
    quantile: {
        student: {
            start: -400,
            end: 1650
        }
    }
};

//These should just be deleted and have the bounds used directly,
//but there are tests for this right now
export const boundTextLexile = textBounds.coerce;
export const isWithinTextLexileBounds = textBounds.containsMeasure;

export const boundStudentLexile = studentBounds.coerce;
export const isWithinStudentLexileBounds = studentBounds.containsMeasure;

export const boundSpanishLexile = spanishBounds.coerce;
export const isWithinSpanishLexileBounds = spanishBounds.containsMeasure;

export const boundSpanishRangeLexile = spanishRangeBounds.coerce;

export const boundStudentQuantile = clamp(
    BOUNDS.quantile.student.start,
    BOUNDS.quantile.student.end
);

export const isWithinStudentQuantileBounds = value =>
    between(BOUNDS.quantile.student.start)(BOUNDS.quantile.student.end)(
        quantileMeasureToInt(value)
    );

export const isValidLexileMeasure = value => Lexile(value).isValid;
export const isValidSpanishLexile = value => SpanishLexile(value).isValid;

export const isValidQuantileMeasure = measure => {
    if (isNumber(measure) || Number(measure)) {
        return true;
    }

    const intMeasure = quantileMeasureToInt(measure);
    if (typeof measure === 'string') {
        return !isNaN(intMeasure);
    }

    return false;
};

export const isValidLexileIncrement = value => Lexile(value).isValidIncrement;

export const isValidQuantileIncrement = value => {
    if (value === null) {
        return false;
    }

    return quantileMeasureToInt(value) % 5 === 0;
};

const isValidQSCId = qscId => {
    if (!qscId) {
        return true;
    }

    if (isNumber(qscId) || Number(qscId)) {
        return true;
    }

    if (typeof qscId === 'string') {
        return isQSC(qscId);
    }

    return false;
};

const validateMeasure = (validate, formatError, spanish = false) => (
    bounds,
    boundsError
) => measure => {
    if (spanish && measure && uppercase(measure) === 'BR') {
        return;
    }
    if (!validate(measure) && measure) {
        return formatError;
    }
    if (!bounds(measure) && measure) {
        return boundsError;
    }
};

export const validateStudentLexileMeasure = validateMeasure(
    isValidLexileMeasure,
    i18n('validation.lexileMeasureError')
)(isWithinStudentLexileBounds, i18n('validation.studentLexileOutOfBounds'));

export const validateStudentQuantileMeasure = validateMeasure(
    isValidQuantileMeasure,
    i18n('validation.validQuantilePlease')
)(isWithinStudentQuantileBounds, i18n('validation.studentQuantileOutOfBounds'));

export const validateQSCId = asValidator(isValidQSCId)(
    i18n('validation.validQSCId')
);

export const validateLexileIncrement = asValidator(isValidLexileIncrement)(
    i18n('validation.measureIncrement')
);

export const validateQuantileIncrement = asValidator(isValidQuantileIncrement)(
    i18n('validation.measureIncrement')
);

/**
 * Formats ranges given as an array
 * @example
 * formatLexileRange([-200, 300]) // returns 'BR200L - 300L'
 */
const formatDifficultyRange = formatMeasure => range => {
    if (Array.isArray(range) && range.length >= 2 && (range[0] || range[1])) {
        if (range[0] === null && range[1]) {
            return i18n('common.rangeMin', formatMeasure(range[1]));
        } else if (range[1] === null && range[0]) {
            return i18n('common.rangeMax', formatMeasure(range[0]));
        }

        return onRange(formatMeasure)(range[0])(range[1]);
    }
    return '-';
};

export const formatLexileRange = formatDifficultyRange(formatLexileMeasure);
export const formatQuantileRange = formatDifficultyRange(formatQuantileMeasure);

export const brIndicatorsExist = either(
    where({
        decoding_demand_percentile: notEmpty,
        semantic_demand_percentile: notEmpty,
        syntactic_demand_percentile: notEmpty,
        structure_demand_percentile: notEmpty
    }),
    where({
        decoding_demand: notEmpty,
        semantic_demand: notEmpty,
        syntactic_demand: notEmpty,
        structure_demand: notEmpty
    })
);

export const upperLevelIndicatorsExist = where({
    msl: notEmpty,
    mlf: notEmpty
});

export const isUpperLevelLexile = lexile => lexileMeasureToInt(lexile) >= 660;

export const areUpperLevelIndicators = indicators =>
    !!indicators && upperLevelIndicatorsExist(indicators);
