import React from 'react';
import styled from 'styled-components';

const LQAcademyIcon = ({ className }) => {
    return (
        <svg
            aria-hidden="true"
            className={className}
            viewBox="0 0 24 17"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="currentColor"
                d="M12.2788 0L0 5.66283L3.90706 7.58113L4 13.244C4 13.244 5.67286 17.1803 11.7212 16.9885C17.7695 16.8005 19.8141 13.1519 19.8141 13.1519V7.39314L24 5.47484L12.2788 0ZM12.0743 15.6111C8.23048 15.8951 5.28625 12.937 5.28625 12.937L5.32714 8.11826L12.1561 10.8307L15.6097 11.1952V14.84C15.6059 14.84 15.9219 15.3272 12.0743 15.6111ZM12.1524 9.45723L2.73606 5.65132L12.3123 1.31596L21.5316 5.44798L12.1524 9.45723Z"
            />
            <path
                fill="currentColor"
                d="M22.4722 7.47375V11.2413C22.4722 11.2413 22.4722 11.8436 23.0967 11.8436C23.7212 11.8436 23.6878 11.1991 23.6878 11.1991L23.6915 7.4891C23.6915 7.4891 23.6915 7.03638 23.119 7.02487C22.5502 7.01336 22.4722 7.47375 22.4722 7.47375Z"
            />
        </svg>
    );
};

export default styled(LQAcademyIcon)``;
