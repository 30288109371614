import styled from 'styled-components';

/**
 * LinkButton exists to allow for actionable elements in the UI
 * that are supposed to look like links, but behave like buttons.
 * LinkButtons don't change navigation, but rather just
 * trigger some action.
 */
const LinkButton = styled.button.attrs({ type: 'button' })`
    font-weight: ${({ weight }) => (weight ? weight : '400')};
    font-size: ${({ size }) => (size ? size : '0.75rem')};
    color: var(--theme-blue4);
    text-decoration: ${({ $noUnderline }) =>
        $noUnderline ? 'none' : 'underline'};

    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    margin: 0;
    padding: 0;
    background: none;
    border: none;
`;

export default LinkButton;
