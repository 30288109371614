import styled from 'styled-components';
import Button from '@ui/actions/Button';

const ActionBar = styled.div`
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    background-color: var(--theme-gray4);

    ${Button} {
        min-width: 100px;
    }
`;

export default ActionBar;
