import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDialog } from 'donut-ui';
import { Formik } from 'formik';

import { resendConfirmationCode } from '@api/session';
import baseRoutes from '@constants/routes';
import { loadingManager } from '@features/global/LoadingManager';
import SEO from '@features/global/SEO';
import { i18n } from '@i18n/format';
import Button, { RegisterButton } from '@ui/actions/Button';
import Link from '@ui/actions/Link';
import ConfirmationDialog from '@ui/dialogs/ConfirmationDialog';
import { PrimaryHeader } from '@ui/headers/PrimaryHeaders';
import TextInputField from '@ui/inputs/formik/TextInputField';
import RestrictedTo from '@ui/layouts/RestrictedTo';
import RequiredFieldLabel from '@ui/text/RequiredFieldLabel';
import { alert } from '@utilities/alerts';
import { lowercaseEmail, useUser } from '@utilities/hooks/useUser';
import { pushTo, queryParams } from '@utilities/navigation';
import { checkAll } from '@utilities/predicates';
import { required, validEmail } from '@utilities/validators';
import { contactUsLink } from 'constants/externalLinks';

import { i18nWithHTML } from 'i18n/format';

const PageLayout = styled.div`
    padding: 30px;

    h1,
    h2 {
        margin-bottom: 12px;
    }

    h2 {
        font-weight: normal;
        font-size: 1rem;

        ${Link} {
            font-size: inherit;
            color: var(--theme-blue4);
        }
    }

    form {
        margin-top: 40px;
        display: flex;
    }

    form > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 400px;
    }

    .sign-in {
        min-height: 275px;
        padding-right: 40px;
        border-right: 1px solid var(--theme-gray1);

        > div {
            width: 100%;
            margin-bottom: 20px;
        }

        ${TextInputField}:first-of-type {
            margin-bottom: 20px;
        }

        .recover-account-links {
            display: flex;
            justify-content: space-between;

            ${Link} {
                display: block;
                text-decoration: none;
                margin-top: 8px;

                :hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .register {
        padding-left: 40px;

        h2 {
            margin-bottom: 20px;
            font-size: 1.125rem;
        }

        button {
            margin-top: 10px;
        }
    }

    .corporate-partner-redirect {
        display: flex;

        h2 {
            margin-right: 4px;
        }
    }

    .stale-session-warning {
        padding: 10px 0px;
        color: var(--theme-red);
    }
`;

const ResendConfirmationLayout = styled.div`
    max-width: 440px;

    p {
        margin-bottom: 8px;
    }
`;

const handleLoginErrors = openDialog => loginError => {
    if (loginError && loginError.code === 'UserNotConfirmedException') {
        openDialog();
    } else {
        alert((loginError && loginError.message) || loginError);
    }
};

const validateEmail = checkAll(
    required(i18n('validation.emailRequired')),
    validEmail
);

const validatePassword = required(i18n('validation.pwdRequired'));

const onResendClick = username => () => {
    pushTo(baseRoutes.REGISTRATION_EMAIL);
    resendConfirmationCode(username);
};

const SignInForm = () => {
    const { email, username, expired, timeout } = queryParams();
    const emailExpiredDialog = useDialog({ isOpen: !!expired });
    const resendEmailDialog = useDialog();
    const { logIn } = useUser();
    const signInHeaderRef = useRef();

    useEffect(() => {
        signInHeaderRef.current.focus();
    }, []);

    return (
        <>
            <SEO>
                <title>Sign In</title>
                <meta name="description" content={i18n('seo.signIn')} />
            </SEO>
            <PageLayout>
                <RestrictedTo notSignedIn />
                <PrimaryHeader ref={signInHeaderRef} tabIndex="-1">
                    {i18n('account.signIn')}
                </PrimaryHeader>
                <p className="stale-session-warning">
                    {timeout && i18n('account.staleSessionWarning')}
                </p>
                <div className="corporate-partner-redirect">
                    <h2 id="corporate-partner-header">
                        {i18nWithHTML('account.corporatePartner', contactUs => (
                            <Link
                                href={contactUsLink}
                                target="_blank"
                                aria-describedby="corporate-partner-header"
                            >
                                {contactUs}
                            </Link>
                        ))}
                    </h2>
                </div>
                <Formik
                    initialValues={{ email: '', password: '' }}
                    onSubmit={credentials => {
                        loadingManager
                            .load(() => logIn(lowercaseEmail(credentials)))
                            .catch(
                                handleLoginErrors(resendEmailDialog.openDialog)
                            );
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="sign-in">
                                <RequiredFieldLabel />
                                <div>
                                    <TextInputField
                                        name="email"
                                        label={i18n('common.emailAddr')}
                                        required
                                        maxLength={255}
                                        validate={validateEmail}
                                    />
                                    <TextInputField
                                        name="password"
                                        label={i18n('common.password')}
                                        type="password"
                                        required
                                        validate={validatePassword}
                                    />
                                    <div className="recover-account-links">
                                        <Link to={baseRoutes.REQUEST_PASSWORD}>
                                            {i18n('account.forgotPwd')}
                                        </Link>
                                    </div>
                                </div>
                                <Button type="submit" $studentFacing>
                                    {i18n('account.signIn')}
                                </Button>
                            </div>
                            <div className="register">
                                <div>
                                    <h2>{i18n('account.noAccount')}</h2>
                                    <p>{i18n('account.whyCreateAcct')}</p>
                                </div>
                                <RegisterButton $studentFacing>
                                    {i18n('common.register')}
                                </RegisterButton>
                            </div>
                            <ConfirmationDialog
                                title={i18n('account.verifyEmail')}
                                confirmationLabel={i18n(
                                    'account.resendConfirmEmailTitle'
                                )}
                                dialogState={resendEmailDialog}
                                onConfirm={onResendClick(values.email)}
                                onCancel={resendEmailDialog.closeDialog}
                            >
                                <ResendConfirmationLayout>
                                    {i18n('account.notConfirmed')}
                                </ResendConfirmationLayout>
                            </ConfirmationDialog>
                            <ConfirmationDialog
                                title={i18n('account.emailExpired')}
                                confirmationLabel={i18n(
                                    'account.resendConfirmEmailTitle'
                                )}
                                dialogState={emailExpiredDialog}
                                onConfirm={onResendClick(
                                    username ? username : email
                                )}
                                onCancel={emailExpiredDialog.closeDialog}
                            >
                                <ResendConfirmationLayout>
                                    <p>{i18n('account.pleaseConfirm')}</p>
                                    <p>{i18n('account.notConfirmedResend')}</p>
                                </ResendConfirmationLayout>
                            </ConfirmationDialog>
                        </form>
                    )}
                </Formik>
            </PageLayout>
        </>
    );
};

export default SignInForm;
