import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { whenProvided, useUniqueId } from 'donut-ui';
import { i18n } from '@i18n/format';
import IconButton from '@ui/actions/IconButton';
import SuccessIcon from '@ui/icons/SuccessAlert';
import BlockIcon from '@ui/icons/BlockIcon';
import CloseIcon from '@ui/icons/CloseIcon';
import { useAlertHandler } from '@utilities/alerts';
import { branchStyles } from '@utilities/stylingUtils';

const slideInFromTop = keyframes`
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
`;

const slideOutToTop = keyframes`
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
`;

const ToastLayout = styled.div`
    position: fixed;
    z-index: 1000000;
    left: calc(50% - 250px);
    right: calc(50% - 250px);
    top: 0;
    width: 500px;
    display: flex;

    box-shadow: var(--elevated-dark);
    background-color: var(--theme-white);
    border-radius: 4px;
    font-size: 1rem;

    .status-message {
        padding: 12px;
        align-self: center;
        flex-grow: 1;
    }

    ${IconButton} {
        margin: 0 8px;
        align-self: center;
    }
    ${IconButton} svg {
        color: var(--theme-gray3);
    }

    ${branchStyles(({ alertType }) => alertType === 'success')`
        border: 1px solid var(--theme-green2);
        color: var(--theme-green2);

        .icon-container {
            background-color: var(--theme-green2);
        }

    `.else`
        border: 1px solid var(--theme-red);
        color: var(--theme-red);

        .icon-container {
            background-color: var(--theme-red);
        }
    `}

    animation: ${slideInFromTop} 250ms cubic-bezier(0.57, 0.18, 0.29, 0.98);

    ${whenProvided('isHiding')`
        animation: ${slideOutToTop} 250ms cubic-bezier(0.57, 0.18, 0.29, 0.98);
    `}

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        margin-right: 12px;

        color: var(--theme-white);

        svg {
            height: 24px;
            width: 24px;
        }
    }
`;

const Toast = ({ alert, clear }) => {
    const [hidingToast, startHidingToast] = useState(false);
    const toast = useRef(null);
    const dismiss = useRef(null);
    const alertId = useUniqueId('__toast__');

    useEffect(() => {
        toast.current && toast.current.focus();
    }, [alert, clear]);

    return (
        <ToastLayout
            ref={toast}
            isHiding={hidingToast}
            onAnimationEnd={hidingToast ? clear : undefined}
            alertType={alert.type}
            tabIndex="0"
            onBlur={({ relatedTarget }) => {
                if (
                    relatedTarget !== dismiss.current &&
                    relatedTarget !== toast.current
                ) {
                    setTimeout(() => startHidingToast(true), 2000);
                }
            }}
            aria-label={i18n('common.notification')}
            aria-describedby={alertId}
        >
            <span className="icon-container">
                {alert.type === 'success' ? (
                    <SuccessIcon title={i18n('common.success')} />
                ) : (
                    <BlockIcon title={i18n('common.error')} />
                )}
            </span>
            <span className="status-message" id={alertId}>
                {alert.message}
            </span>
            <IconButton
                ref={dismiss}
                onClick={startHidingToast}
                label={i18n('common.close')}
                onBlur={({ relatedTarget }) => {
                    if (relatedTarget !== toast.current) {
                        startHidingToast();
                    }
                }}
            >
                <CloseIcon />
            </IconButton>
        </ToastLayout>
    );
};

const UserAlert = () => {
    const { alert, clear } = useAlertHandler();

    if (!alert) {
        return null;
    }

    return <Toast alert={alert} clear={clear} />;
};

export default UserAlert;
