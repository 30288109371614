import rand from 'random-key';

/**
 * The purpose of this file is to keep data we retrieve from the backend
 * that doesn't change in a simple cache (i.e., data object). This
 * keeps us from making multiple HTTP requests for the same data,
 * and allows components to load faster.
 */

const resources = {};

const createResource = f => {
    const key = rand.generateBase30(5);

    return async () => {
        if (resources[key]) {
            return resources[key];
        }

        const result = await f();
        resources[key] = result;
        return result;
    };
};

export default createResource;
