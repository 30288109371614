import baseRoutes from '@constants/routes';

const root = baseRoutes.ACCOUNT;

export const routes = {
    CHANGE_PASSWORD: `${root}/change-password`,
    MAILING_LISTS: `${root}/mailing-lists`,
    MEMBERSHIP_PLAN: `${root}/membership-plan`,
    PROFILE: `${root}/profile`
};
