import { equals } from './predicates';

const keyListener = (...keys) => handler => event => {
    if (event.key && keys.some(equals(event.key))) {
        handler(event);
    }
};

//Enter and Space keys are supposed to act like button click events;
//It's also expected that default behavior for button triggers don't fire default behavior
//(i.e., submitting a form on Enter, and scrolling the page on Space)
export const onButtonTrigger = handler =>
    keyListener(
        'Enter',
        ' '
    )(event => {
        event.preventDefault();
        handler(event);
    });

export const onEscape = handler =>
    keyListener(
        'Escape',
        'Esc'
    )(event => {
        event.preventDefault();
        handler(event);
    });

const keyHandler = key => (handler, allowDefault) =>
    keyListener(key)(event => {
        if (!allowDefault) {
            event.preventDefault();
        }
        handler(event);
    });

export const onEnter = keyHandler('Enter');

export const onSpace = keyHandler(' ');
