import React from 'react';

const FABIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            aria-hidden="true"
        >
            <path d="M15 14.31V1.61a4.22 4.22 0 0 1-3.49 0v12.7a2.54 2.54 0 0 0 3.49 0zM2.83 14.55L5.54 2.83A3.23 3.23 0 0 1 2.71 2L0 13.68a2 2 0 0 0 2.83.87zM9.87 14.23V0a3.67 3.67 0 0 1-3.41 0v14.23a2.27 2.27 0 0 0 3.41 0z" />
        </svg>
    );
};

export default FABIcon;
