import React from 'react';

import { alert, httpError, success } from '@utilities/alerts';
import {
    awsFederatedAuthorize,
    clearPastSSOSessionURL,
    federatedSignIn,
    signOutURL
} from '@api/federatedSession';
import { daysUntil, isPast } from '@utilities/dataTypes/dates';
import { tap } from 'ramda';

import {
    initializeUserContext,
    setUserContext
} from '@utilities/analytics/agent';
import {
    location,
    pathStartsWith,
    pushTo,
    queryParams
} from '@utilities/navigation';
import { login, refresh, signOut, userInfo } from '@api/session';
import {
    onSessionEnd,
    resetSessionTimer
} from '@utilities/analytics/sessionAnalytics';
import { setGoogleAnalyticsUserInfo } from '@utilities/analytics/googleAnalytics';
import SessionExpiration from '@ui/dialogs/SessionExpiration';
import baseRoutes from '@constants/routes';
import { manualLogoutTrigger, timeOutLogoutTrigger } from '@constants/session';
import { i18n } from '@i18n/format';
import { isEmpty } from '@utilities/dataTypes/objects';
import { onSignIn } from '@utilities/analytics/analyticsEvents';
import { UserContext } from '@utilities/contexts';
import { remove } from '@utilities/dataTypes/arrays';
import { resourceCenter } from '@features/quantile/ResourceStore';
import usageTracker from '@utilities/analytics/usageTracker';
import { verifyUpdatedEmail } from '@api/uap';
import {
    isHubSessionEvent,
    getLogoutTrigger,
    setLogoutTrigger
} from '@utilities/sessionStorage';
import { inactiveSessionQueryParam } from '@constants/session';

const secToMs = secondsStr => (secondsStr ? parseInt(secondsStr) * 1000 : '');

const getProvider = identities => {
    // We're assuming here that the user would only ever have 1 identity provider source.
    if (Array.isArray(identities) && identities.length > 0) {
        return identities[0]?.providerName;
    }
};

const emailVerificationCode = () => {
    if (
        pathStartsWith(baseRoutes.VERIFY_EMAIL) ||
        pathStartsWith(baseRoutes.SIGN_IN)
    ) {
        return queryParams().code;
    }
};

const staleSessionError = error => {
    if (
        error?.code === 'NotAuthorizedException' &&
        error.message === 'Refresh Token has expired'
    ) {
        alert(i18n('account.staleSessionWarning'));
    } else {
        throw error;
    }
};

const federatedSignInParams = () => {
    if (pathStartsWith(baseRoutes.FEDERATED_SIGN_IN)) {
        return queryParams();
    }
};

const federatedSignInProvider = () => {
    if (pathStartsWith(baseRoutes.FEDERATED_SIGN_IN)) {
        const pathArray = location().pathname.split('/');
        if (pathArray.length > 2) {
            return pathArray[2];
        }
    }
};

const startSSOProvider = () => {
    if (pathStartsWith(baseRoutes.START_SSO)) {
        const pathArray = location().pathname.split('/');
        if (pathArray.length > 2) {
            return pathArray[2];
        }
    }
};

const getSSOProviderFromError = error => {
    const errorWordsArray = error.trim().split(' ');
    // The last word in the error will include the provider name, ie "GASSO_{otherstuff}"
    const providerAndCode = errorWordsArray[errorWordsArray.length - 1];
    // We return what comes before the "_", which will be the provider name
    return providerAndCode.split('_')[0];
};

//TODO: Fix updating to state pro account
// This isn't the exhaustive list of user info we get
// back from cognito. Things like country also come back from
// userInfo()
const initialState = {
    loggedIn: false,
    confirmed: false,
    email: '',
    state: '',
    daysLeftInTrial: 0,
    groups: []
};

export class UserProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...initialState,
            verificationCode: emailVerificationCode(),
            federatedSignInParams: federatedSignInParams(),
            federatedSignInProvider: federatedSignInProvider(),
            startSSOProvider: startSSOProvider(),
            logIn: this.logIn,
            logOut: this.logOut,
            update: this.update,
            refresh: this.refreshUser
        };
    }

    componentDidMount() {
        userInfo()
            .then(user => {
                if (!isEmpty(user)) {
                    this.update(user);
                }
            })
            .then(this.attemptEmailVerification)
            .then(this.attemptFederatedSignIn)
            .catch(error => {
                if (this.state.verificationCode) {
                    pushTo(
                        `${baseRoutes.SIGN_IN}?code=${this.state.verificationCode}`
                    );
                }

                if (error !== 'No current user') {
                    throw error;
                }
            })
            .catch(staleSessionError)
            .catch(httpError);

        const userInfoUpdater = event => {
            if (
                isHubSessionEvent(event) &&
                (event.key.endsWith('idToken') ||
                    event.key.endsWith('LastAuthUser')) &&
                event.oldValue !== event.newValue
            ) {
                if (event.newValue == null) {
                    if (getLogoutTrigger() === timeOutLogoutTrigger) {
                        this.logOut(
                            `${baseRoutes.SIGN_IN}/?${inactiveSessionQueryParam}`
                        );
                    } else {
                        this.logOut(baseRoutes.SIGN_IN);
                    }
                } else {
                    userInfo().then(user => {
                        if (!isEmpty(user)) {
                            this.update(user);
                        }
                    });
                }
            }
        };
        window.removeEventListener('storage', userInfoUpdater);
        window.addEventListener('storage', userInfoUpdater);
    }

    logIn = credentials =>
        login(credentials)
            .then(userInfo)
            .then(tap(this.attemptEmailVerification))
            .then(tap(this.update))
            .then(tap(onSignIn))
            .then(({ firstLogin }) =>
                pushTo(firstLogin ? baseRoutes.WELCOME : baseRoutes.HOME_PAGE)
            );

    logOut = redirectRoute =>
        signOut()
            .then(() => {
                if (
                    !(
                        redirectRoute &&
                        redirectRoute.endsWith(inactiveSessionQueryParam)
                    )
                ) {
                    setLogoutTrigger(manualLogoutTrigger);
                }
            })
            .then(() => this.setState(initialState))
            //TODO: Once resourceCenter doesn't use mobx, have a provider that will useUser
            //and when user.loggedIn changes, reset state
            .then(() => resourceCenter.reset())
            .then(() => {
                onSessionEnd();
                initializeUserContext();
                resetSessionTimer();
            })
            .then(() => {
                if (this.state.identityProvider) {
                    window.location.href = signOutURL(
                        this.state.identityProvider
                    );
                }

                pushTo(redirectRoute || baseRoutes.SIGN_IN);
            });

    update = userModifications => {
        const user = this.formatUser(userModifications);
        this.setState(user);
        setUserContext(user);

        setGoogleAnalyticsUserInfo(user);
    };

    refreshUser = () => refresh().then(userInfo).then(this.update);

    attemptFederatedSignIn = () => {
        // Attempting to sign in with a linked account always fails the first time
        // so we do a retry if this specific error occurs
        // https://blog.ilearnaws.com/2020/08/06/error-cognito-auth-flow-fails-with-already-found-an-entry-for-username/
        if (this.state.startSSOProvider) {
            window.location.href = clearPastSSOSessionURL(
                this.state.startSSOProvider
            );
        } else if (this.state.federatedSignInParams?.code) {
            return (
                federatedSignIn(this.state.federatedSignInParams.code)
                    .then(refresh)
                    .then(userInfo)
                    .then(this.update)
                    // Due to the redirects involved in SSO sign in the usageTracker
                    // was incrementing pagesVisited counter unnecessarily.
                    // Resetting tracker and starting fresh after SSO sign in.
                    .then(usageTracker.reset)
            );
        } else if (this.state.federatedSignInProvider) {
            window.location.href = awsFederatedAuthorize(
                this.state.federatedSignInProvider
            );
        } else if (
            this.state.federatedSignInParams?.error_description?.includes(
                'Already found an entry for username'
            )
        ) {
            const provider = getSSOProviderFromError(
                this.state.federatedSignInParams.error_description
            );
            window.location.href = awsFederatedAuthorize(provider);
        }
    };

    attemptEmailVerification = () => {
        if (this.state.verificationCode) {
            return verifyUpdatedEmail(this.state.verificationCode)
                .then(refresh)
                .then(userInfo)
                .then(this.update)
                .then(() => {
                    success(i18n('account.emailVerified'));
                });
        }
    };

    formatUser = userAttributes => {
        const expirationDate = secToMs(userAttributes.freeTrialExpiration);
        const hasExpired = isPast(expirationDate);
        const purchaseOrderExpiration = secToMs(
            userAttributes.purchaseOrderExpiration
        );

        if (hasExpired) {
            userAttributes.groups = remove(userAttributes.groups, 'FreeTrial');
        }

        return {
            ...userAttributes,
            daysLeftInTrial:
                expirationDate && !hasExpired ? daysUntil(expirationDate) : 0,
            loggedIn: true,
            identityProvider: getProvider(userAttributes?.identities),
            purchaseOrder: {
                expiration: purchaseOrderExpiration,
                active:
                    purchaseOrderExpiration && !isPast(purchaseOrderExpiration)
            }
        };
    };

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
                <SessionExpiration />
            </UserContext.Provider>
        );
    }
}
