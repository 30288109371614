import React from 'react';
import styled, { css } from 'styled-components';
import { whenProvided } from 'donut-ui';
import { useSelect } from 'downshift';
import { i18n } from '@i18n/format';
import { accountMenuOptions } from '@constants/accountMenuOptions';
import { providers } from '@constants/identityProviders';
import { useUser } from '@utilities/hooks/useUser';
import Button from '@ui/actions/Button';
import ArrowDropDown from '@ui/icons/ArrowDropDown';
import Profile from '@ui/icons/Profile';
import { IdentityProvider } from '@ui/layouts/Tier';
import { useViewport } from '@utilities/hooks/useViewport';
import { appBarHeight } from '@ui/navigation/navConstants';
import ScreenReaderText from 'ui/messages/ScreenReaderText';

const layout = css`
    position: relative;
`;

const CognitoWrapper = ({ needsCognitoWrapper, children }) =>
    needsCognitoWrapper ? (
        <IdentityProvider provider={providers.COGNITO}>
            {children}
        </IdentityProvider>
    ) : (
        children
    );

const DesktopAccountButton = styled(Button)`
    display: flex;
    align-items: center;

    svg:last-child {
        height: 4px;
        width: 8px;
        transition: transform ease 200ms;
        margin-left: 12px;
        fill: var(--theme-white);

        ${whenProvided('isOpen')`
            transform: rotate(180deg);
        `}
    }
`;

const MobileAccountButton = styled(DesktopAccountButton)`
    min-width: 0px;
    padding: 12px;
`;

const PopoutMenu = styled.ul`
    display: none;
    z-index: 1000;
    color: var(--theme-dark-gray);
    background-color: var(--theme-white);
    border-bottom: 4px solid var(--theme-blue1);
    outline: none;
    box-shadow: var(--elevated-dark);
    overflow-y: auto;

    ${whenProvided('isOpen')`
        display: inline; 
    `}

    li {
        list-style: none;
    }
`;

const DesktopPopoutMenu = styled(PopoutMenu)`
    position: absolute;
    top: 60%;
    min-width: 160px;
    max-height: calc(100vh - 53px);
    right: 0;
    padding: 8px 0;
`;

const MobilePopoutMenu = styled(PopoutMenu)`
    position: fixed;
    top: ${appBarHeight};
    left: 0;
    width: 100vw;
    height: calc(100vh - ${appBarHeight});
    padding: 0 0 8px;
    margin: unset;
`;

const MenuLink = styled.a`
    width: 100%;
    padding: 16px 12px;
    cursor: pointer;
    text-decoration: none;
    color: var(--theme-dark-gray);
    display: block;

    ${whenProvided('highlighted')`
        background-color: var(--theme-gray4);
    `}
`;

const PopoutMenuContent = ({
    isOpen,
    items,
    getItemProps,
    highlightedIndex
}) => {
    return (
        isOpen &&
        items.map((item, index) => (
            <CognitoWrapper
                isChangePwd={item.label === i18n('account.changePwd')}
                key={`${item.label}-${index}`}
            >
                <li>
                    <MenuLink
                        {...getItemProps({ item: item, index })}
                        href={item.value || '#'}
                        highlighted={highlightedIndex === index}
                        key={`${item.label}-${index}`}
                        role="menuitem"
                        aria-selected={null}
                    >
                        {item.label}
                    </MenuLink>
                </li>
            </CognitoWrapper>
        ))
    );
};

const AppBarMenu = ({ className }) => {
    const items = accountMenuOptions;
    const itemToString = item => item.label;
    const { logOut } = useUser();
    const { width } = useViewport();
    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps
    } = useSelect({
        items,
        itemToString,
        defaultHighlightedIndex: -1,
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem?.label === i18n('account.logout')) {
                logOut();
            } else {
                window.location.href = selectedItem?.value;
            }
        }
    });

    return (
        <span className={className}>
            {width < 392 ? (
                <MobileAccountButton
                    $studentFacing
                    {...getToggleButtonProps()}
                    isOpen={isOpen}
                    aria-haspopup="true"
                >
                    <ScreenReaderText>
                        {i18n('account.myAccount')}
                    </ScreenReaderText>
                    <Profile />
                    <ArrowDropDown />
                </MobileAccountButton>
            ) : (
                <DesktopAccountButton
                    $studentFacing
                    {...getToggleButtonProps()}
                    isOpen={isOpen}
                    aria-haspopup="true"
                    className="profile-button"
                >
                    {i18n('account.myAccount')}
                    <ArrowDropDown />
                </DesktopAccountButton>
            )}
            {width < 392 ? (
                <MobilePopoutMenu
                    isOpen={isOpen}
                    {...getMenuProps()}
                    role="menu"
                >
                    <PopoutMenuContent
                        isOpen={isOpen}
                        items={items}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                    />
                </MobilePopoutMenu>
            ) : (
                <DesktopPopoutMenu
                    isOpen={isOpen}
                    {...getMenuProps()}
                    role="menu"
                >
                    <PopoutMenuContent
                        isOpen={isOpen}
                        items={items}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                    />
                </DesktopPopoutMenu>
            )}
        </span>
    );
};

export default styled(AppBarMenu)`
    ${layout}
`;
