import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useDialog, Checkbox as DonutCheckbox } from 'donut-ui';
import { ssoAgreeToTerms, updateUserProfile } from '@api/uap';
import { i18n, i18nWithHTML } from '@i18n/format';
import { loadingManager } from '@features/global/LoadingManager';
import { privacyPolicy, termsOfUse } from '~/constants/externalLinks';
import { labelValueStatesAndTerritories } from '~/constants/states';
import { getCountryLabel } from '~/constants/countries';
import ConfirmationDialog from '@ui/dialogs/ConfirmationDialog';
import Checkbox from '@ui/inputs/toggles/Checkbox';
import Link from '@ui/actions/Link';
import AutocompleteField from '@ui/inputs/formik/AutocompleteField';
import CheckboxField from '@ui/inputs/formik/toggles/CheckboxField';
import { httpError } from '@utilities/alerts';
import { useUser } from '@utilities/hooks/useUser';
import { required } from '@utilities/validators';
import { mapObj, pluckOrFallback } from '@utilities/dataTypes/objects';

const Layout = styled.div`
    max-width: 480px;
    line-height: 1.25rem;
    padding: 0 12px;

    .legal-agreement {
        margin-bottom: 24px;
    }

    ${AutocompleteField} {
        margin-bottom: 16px;
        width: auto;
    }

    ${Checkbox} {
        label span {
            min-width: 16px;
        }
    }

    ${DonutCheckbox.Label} {
        font-size: 1rem;
        color: var(--theme-dark-gray);
    }
`;

const submitConfirmation = (values, user) => {
    loadingManager.load(() =>
        ssoAgreeToTerms({
            agreedToTerms: true,
            subscribeToMailList: values.subscribeToMailList
        })
            .then(
                () =>
                    !user.state &&
                    updateUserProfile({
                        ...user,
                        ...mapObj(pluckOrFallback('value'))(values),
                        country:
                            values.country ||
                            getCountryLabel('United States of America')
                    })
            )
            .then(user.refresh)
            .catch(httpError)
    );
};

const SSOAcceptTermsDialog = () => {
    const ssoTermsDialog = useDialog();
    const user = useUser();
    const { loggedIn, agreedToTerms, state } = user;

    useEffect(() => {
        if (loggedIn && (!agreedToTerms || !state)) {
            ssoTermsDialog.openDialog();
        }
    }, [ssoTermsDialog, agreedToTerms, state, loggedIn]);

    if (agreedToTerms && state) {
        return null;
    }

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={{ state: state || '', subscribeToMailList: true }}
        >
            {({ values }) => (
                <ConfirmationDialog
                    title={i18n('sso.welcome')}
                    confirmationLabel={i18n('sso.agree')}
                    dialogState={ssoTermsDialog}
                    onConfirm={() => submitConfirmation(values, user)}
                    confirmationOnly
                    forceDecision
                >
                    <form onSubmit={() => {}}>
                        <Layout>
                            <p className="legal-agreement">
                                {i18nWithHTML(
                                    'registration.legalAgreement',
                                    privacy => (
                                        <Link
                                            href={privacyPolicy}
                                            target="_blank"
                                            $noUnderline
                                        >
                                            {privacy}
                                        </Link>
                                    )
                                )}
                                {i18nWithHTML(
                                    'registration.andTerms',
                                    terms => (
                                        <Link
                                            href={termsOfUse}
                                            target="_blank"
                                            $noUnderline
                                        >
                                            {terms}
                                        </Link>
                                    )
                                )}
                            </p>
                            {!state && (
                                <AutocompleteField
                                    name="state"
                                    label={i18n('common.state')}
                                    options={labelValueStatesAndTerritories}
                                    validate={required(
                                        i18n('validation.stateRequired')
                                    )}
                                    inputSize="full"
                                    openOnFocus
                                    required
                                />
                            )}
                            <CheckboxField
                                label={i18n(
                                    'account.subscribeToDefaultMailingLists'
                                )}
                                name="subscribeToMailList"
                            />
                        </Layout>
                    </form>
                </ConfirmationDialog>
            )}
        </Formik>
    );
};

export default SSOAcceptTermsDialog;
