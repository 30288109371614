import React, { useContext } from 'react';
import { groupBy } from 'ramda';
import { fetchFavoriteTools, favoriteTools, unfavoriteTools } from '@api/uap';
import { loadingManager } from '@features/global/LoadingManager';
import { useUser } from '@utilities/hooks/useUser';
import { contains, toggle, isEmptyArray } from '@utilities/dataTypes/arrays';
import { propEquals } from '@utilities/dataTypes/objects';
import { httpError } from '@utilities/alerts';
import * as tools from '~/constants/tools';
import { favorites } from '@constants/favorites';

const isLexileTool = toolId => tools.lexileTools.some(propEquals('id', toolId));

const isQuantileTool = toolId =>
    tools.quantileTools.some(propEquals('id', toolId));

const emptyFavoritesByDomain = {
    lexileFavorites: [],
    quantileFavorites: [],
    k2Favorites: []
};

const getToolCategory = toolId => {
    if (isLexileTool(toolId)) {
        return favorites.LEXILE;
    } else if (isQuantileTool(toolId)) {
        return favorites.QUANTILE;
    } else {
        return favorites.K2;
    }
};

const defaultK2Favorites = [
    tools.findADecodableBook.id,
    tools.decodablePassages.id
];

const defaultLexileFavorites = [
    tools.findABook.id,
    tools.textAnalyzer.id,
    tools.lexileWordList.id
];

const defaultQuantileFavorites = [
    tools.quantileTeacherAssistant.id,
    tools.mathAtHome.id,
    tools.mathSkillsDatabase.id
];

const FavoritesContext = React.createContext();

class Favorites extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lexileFavorites: [],
            quantileFavorites: [],
            k2Favorites: [],
            toggleFavorite: this.toggleFavorite,
            isFavorite: this.isFavorite
        };
    }

    componentDidMount() {
        this.loadFavorites();
    }

    componentDidUpdate(prevProps) {
        if (this.props.featureOn !== prevProps.featureOn) {
            this.setDefaultFavorites();
        }

        if (prevProps.loggedIn !== this.props.loggedIn) {
            this.loadFavorites();
        }
    }

    setDefaultFavorites = () => {
        this.setState({
            lexileFavorites: defaultLexileFavorites,
            quantileFavorites: defaultQuantileFavorites,
            k2Favorites: defaultK2Favorites
        });
    };

    loadFavorites = () => {
        if (this.props.loggedIn) {
            loadingManager
                .load(() => fetchFavoriteTools())
                .then(favorites => {
                    if (isEmptyArray(favorites)) {
                        this.setDefaultFavorites();
                        favoriteTools([
                            ...defaultLexileFavorites,
                            ...defaultQuantileFavorites,
                            ...defaultK2Favorites
                        ]);
                    } else {
                        const orderedFavorites = tools.toolList
                            .filter(tool => favorites.includes(tool.id))
                            .map(tool => tool.id);

                        const {
                            lexileFavorites,
                            quantileFavorites,
                            k2Favorites
                        } = {
                            ...emptyFavoritesByDomain,
                            ...groupBy(getToolCategory)(orderedFavorites)
                        };

                        this.setState({
                            lexileFavorites,
                            quantileFavorites,
                            k2Favorites
                        });
                    }
                });
        } else {
            this.setDefaultFavorites();
        }
    };

    toggleFavorite = toolId => {
        if (!this.props.loggedIn) {
            return;
        }

        const category = getToolCategory(toolId);

        loadingManager
            .load(() =>
                this.isFavorite(toolId)
                    ? unfavoriteTools(toolId)
                    : favoriteTools(toolId)
            )
            .then(() => {
                this.setState({
                    [category]: toggle(this.state[category], toolId)
                });
            })
            .catch(httpError);
    };

    isFavorite = toolId => {
        return (
            contains(this.state.lexileFavorites, toolId) ||
            contains(this.state.quantileFavorites, toolId) ||
            contains(this.state.k2Favorites, toolId)
        );
    };

    render() {
        return (
            <FavoritesContext.Provider value={this.state}>
                {this.props.children}
            </FavoritesContext.Provider>
        );
    }
}

export const FavoritesProvider = ({ children }) => {
    const { loggedIn } = useUser();
    return <Favorites loggedIn={loggedIn}>{children}</Favorites>;
};

export const useFavorites = () => useContext(FavoritesContext);
