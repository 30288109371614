import React from 'react';
import styled from 'styled-components';
import { useDialog, whenProvided } from 'donut-ui';
import { i18n, i18nWithHTML } from '@i18n/format';
import { bold } from '@i18n/renderers';
import ConfirmationDialog from '@ui/dialogs/ConfirmationDialog';
import LinkButton from '@ui/actions/LinkButton';
import { LexilePopupArt } from '@features/dashboard/home/whatAreMeasures/LexilePopupArt';
import { QuantilePopupArt } from '@features/dashboard/home/whatAreMeasures/QuantilePopupArt';

const DialogLayout = styled.div`
    width: 700px;

    h3 {
        padding: 12px 0 20px;
        font-weight: normal;

        ${whenProvided('$lexile')`
            color: var(--theme-green2);
        `}
        ${whenProvided('$quantile')`
            color: var(--theme-blue3);
        `}
    }

    p {
        margin-bottom: 24px;
    }
`;

export const WhatAreLexiles = styled(({ className }) => {
    const dialogState = useDialog();

    return (
        <>
            <LinkButton className={className} onClick={dialogState.openDialog}>
                {i18n('home.learnMoreAboutLexiles')}
            </LinkButton>
            <ConfirmationDialog
                title={i18n('home.whatAreLexiles')}
                confirmationLabel={i18n('common.home.close')}
                dialogState={dialogState}
                onConfirm={dialogState.closeDialog}
                confirmationOnly
                scrollable
            >
                <DialogLayout $lexile>
                    <p>{i18n('home.lexilesSupportLearning')}</p>
                    <LexilePopupArt />
                    <h3>{i18n('home.findingLexiles')}</h3>
                    <p>{i18n('home.lexileReporting')}</p>
                    <p>{i18n('home.lexileRanges')}</p>
                    <h3>{i18n('home.howCanYouUseLexiles')}</h3>
                    <p>{i18n('home.lexileOneAspect')}</p>
                    <p>{i18nWithHTML('home.howToUseLexiles', bold)}</p>
                </DialogLayout>
            </ConfirmationDialog>
        </>
    );
})``;

export const WhatAreQuantiles = styled(({ className }) => {
    const dialogState = useDialog();

    return (
        <>
            <LinkButton className={className} onClick={dialogState.openDialog}>
                {i18n('home.learnMoreAboutQuantiles')}
            </LinkButton>
            <ConfirmationDialog
                title={i18n('home.whatAreQuantiles')}
                confirmationLabel={i18n('common.home.close')}
                dialogState={dialogState}
                onConfirm={dialogState.closeDialog}
                confirmationOnly
                scrollable
            >
                <DialogLayout $quantile>
                    <p>{i18n('home.quantilesSupportLearning')}</p>
                    <QuantilePopupArt />
                    <h3>{i18n('home.findingQuantiles')}</h3>
                    <p>{i18n('home.quantileReporting')}</p>
                    <p>{i18n('home.quantileRanges')}</p>
                    <h3>{i18n('home.howCanYouUseQuantiles')}</h3>
                    <p>{i18n('home.quantileOneAspect')}</p>
                    <p>{i18nWithHTML('home.howToUseQuantiles', bold)}</p>
                </DialogLayout>
            </ConfirmationDialog>
        </>
    );
})``;
