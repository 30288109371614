import React from 'react';

const CheckIcon = props => (
    <svg
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill={props.fill || 'currentColor'}
        {...props}
    >
        <path d="M15.7846 3.2567C15.7846 3.00558 15.6842 2.75446 15.5033 2.57366L14.1373 1.20759C13.9565 1.02679 13.7054 0.92634 13.4542 0.92634C13.2031 0.92634 12.952 1.02679 12.7712 1.20759L6.18192 7.80692L3.22879 4.84375C3.04799 4.66295 2.79688 4.5625 2.54576 4.5625C2.29464 4.5625 2.04353 4.66295 1.86272 4.84375L0.496652 6.20982C0.315848 6.39062 0.215402 6.64174 0.215402 6.89286C0.215402 7.14397 0.315848 7.39509 0.496652 7.57589L4.13281 11.2121L5.49888 12.5781C5.67969 12.7589 5.9308 12.8594 6.18192 12.8594C6.43304 12.8594 6.68415 12.7589 6.86496 12.5781L8.23103 11.2121L15.5033 3.93973C15.6842 3.75893 15.7846 3.50781 15.7846 3.2567Z" />
    </svg>
);

export default CheckIcon;
