import React, { useMemo } from 'react';
import { pickBy, either, contains, compose, not } from 'ramda';
import {
    providers,
    isPartnerProvider,
    isPartnerOrSSOProvider
} from '@constants/identityProviders';
import { useUser } from '@utilities/hooks/useUser';
import { equals } from '@utilities/predicates';
import { asArray } from '@utilities/dataTypes/arrays';

export const tiers = {
    FREE: 'free',
    BASIC: 'basic',
    PRO: 'pro'
};

export const groups = {
    FREE_TRIAL: 'freeTrial',
    PRO: 'pro',
    STATE_PRO: 'statePro',
    PARTNER_PRO: 'partnerPro',
    PAID: 'paid',
    PREMIUM: 'premium'
};

const isInFreeTrial = contains('FreeTrial');
const isPro = contains('Pro');
const isStatePro = contains('StatePro');
const isPremium = either(isPro, isStatePro);
const isBasic = compose(not, either(isPremium, isInFreeTrial));

export const useTier = () => {
    const { groups, loggedIn } = useUser();
    const { providerName } = useProvider();
    const userTier = useMemo(() => {
        if (!loggedIn) {
            return tiers.FREE;
        }

        return isBasic(groups) && !isPartnerOrSSOProvider(providerName)
            ? tiers.BASIC
            : tiers.PRO;
    }, [groups, loggedIn, providerName]);

    return userTier;
};

export const useGroups = () => {
    const { groups: userGroups } = useUser();
    const { providerName } = useProvider();

    const partnerPro = isPartnerProvider(providerName);

    const pro = isPro(userGroups);
    const statePro = isStatePro(userGroups) && !partnerPro;
    const freeTrial = isInFreeTrial(userGroups);

    return {
        pro,
        statePro,
        freeTrial: freeTrial && !(pro || statePro || partnerPro),
        //It's possible to have userGroups = ['Pro', 'StatePro']
        //which is where this paid group comes in
        paid: pro && !statePro,
        //Premium is specifically not free trial users
        premium: statePro || pro,
        partnerPro
    };
};

export const useProvider = () => {
    const { identityProvider } = useUser();

    return {
        sso: !!identityProvider,
        providerName: identityProvider || providers.COGNITO
    };
};

export const withTier = Component => props => {
    const tier = useTier();

    return <Component {...props} userTier={tier} />;
};

export const withGroups = Component => props => {
    const userGroups = useGroups();

    return <Component {...props} userGroups={userGroups} />;
};

const Tier = ({ children, level }) => {
    const userTier = useTier();

    let matchesLevel = false;

    if (Array.isArray(level)) {
        matchesLevel = level.some(equals(userTier));
    } else {
        matchesLevel = userTier === level && level;
    }

    if (typeof children === 'function') {
        return children({
            userTier,
            matchesLevel
        });
    }

    return matchesLevel && children;
};

export const UserGroup = ({ children, group }) => {
    const userGroups = useGroups();

    group = asArray(group);

    const inGroup =
        Object.keys(
            pickBy(
                (inGroup, groupName) =>
                    inGroup && groupName && group.includes(groupName),
                userGroups
            )
        ).length > 0;

    if (typeof children === 'function') {
        return children(inGroup);
    }

    if (inGroup) {
        return children;
    }

    return null;
};

export const IdentityProvider = ({ children, provider }) => {
    const { providerName } = useProvider();

    let matchesProvider = false;

    if (Array.isArray(provider)) {
        matchesProvider = provider.some(equals(providerName));
    } else {
        matchesProvider = providerName === provider && provider;
    }

    if (typeof children === 'function') {
        return children({
            providerName,
            matchesProvider
        });
    }

    return matchesProvider && children;
};

export default Tier;
