import React from 'react';
import styled, { css } from 'styled-components';
import { whenProvided } from '@utilities/stylingUtils';

const fillStyles = css`
    .primary-fill {
        fill: var(--theme-light-blue-quantile);

        ${whenProvided('disabled')`
            fill: var(--theme-cool-gray);
        `}
    }
`;

const QuantileResourceCenterLogo = ({ className, height, width }) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100.83 117.44"
            height={height}
            width={width}
        >
            <path
                d="M9.19 0A9.23 9.23 0 0 0 0 9.2v97a9.12 9.12 0 0 0 9.19 9.2h82.45a9.23 9.23 0 0 0 9.19-9.2V36.62L66.73 0z"
                fillRule="evenodd"
                className="primary-fill"
            />
            <path
                d="M66.73 0v26.34c0 2.88 2 10.46 11 10.46h23.08z"
                style={{ isolation: 'isolate' }}
                opacity=".5"
                fill="#fff"
            />
            <path
                d="M35.81 54.39a14.85 14.85 0 0 1 21 0 15.12 15.12 0 0 1-.27 21l-7.45 7.51a21.31 21.31 0 0 0-1.11-8.31l3.88-4.15a8.2 8.2 0 0 0 0-11.35 7.86 7.86 0 0 0-11.07 0l-8.58 8.58a8.18 8.18 0 0 0 0 11.35 3.2 3.2 0 0 1 0 4.71 3.52 3.52 0 0 1-5 0c-5.53-5.82-5.53-15.23 0-20.76zm-.56 21.31a3.74 3.74 0 0 1 0-4.71 3.51 3.51 0 0 1 5 0 14.52 14.52 0 0 1 0 20.76l-8.61 8.58a14.52 14.52 0 0 1-20.76 0 14.87 14.87 0 0 1 0-21l7.76-7.78a18 18 0 0 0 1.11 8.58l-4.11 4.15a7.84 7.84 0 0 0 0 11.07 8.18 8.18 0 0 0 11.35 0l8.3-8.3a7.71 7.71 0 0 0-.04-11.35z"
                fill="#fff"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default styled(QuantileResourceCenterLogo)`
    ${fillStyles}
`;
