import {
    CognitoIdToken,
    CognitoAccessToken,
    CognitoRefreshToken,
    CognitoUserSession,
    CognitoUser,
    CognitoUserPool
} from 'amazon-cognito-identity-js';
import qs from 'qs';
import routes from '@constants/routes';
import { identityProviderSignOutURLs } from '@constants/identityProviders';

const cognitoURL = process.env.REACT_APP_COGNITO_DOMAIN_URL;
const clientID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
const userPoolID = process.env.REACT_APP_COGNITO_USER_POOL_ID;

export const federatedSignIn = code => {
    const formBody = Object.entries({
        grant_type: 'authorization_code',
        code: code,
        client_id: clientID,
        redirect_uri: window.location.origin + routes.FEDERATED_SIGN_IN
    })
        .map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');

    return fetch(`${cognitoURL}/token`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
    })
        .then(res => res.json())
        .then(resJson => {
            const IdToken = new CognitoIdToken({ IdToken: resJson.id_token });
            const AccessToken = new CognitoAccessToken({
                AccessToken: resJson.access_token
            });
            const RefreshToken = new CognitoRefreshToken({
                RefreshToken: resJson.refresh_token
            });
            let userSession = new CognitoUserSession({
                IdToken,
                AccessToken,
                RefreshToken
            });
            let cogPool = new CognitoUserPool({
                UserPoolId: userPoolID,
                ClientId: clientID
            });
            let cognitoUser = new CognitoUser({
                Username: userSession.idToken.payload['cognito:username'],
                Pool: cogPool
            });
            cognitoUser.setSignInUserSession(userSession);
        });
};

export const awsFederatedAuthorize = provider => {
    const queryParamsString = qs.stringify(
        {
            response_type: 'code',
            identity_provider: provider,
            client_id: clientID,
            redirect_uri: window.location.origin + routes.FEDERATED_SIGN_IN,
            scope: 'email+openid+profile'
        },
        { addQueryPrefix: true, encode: false }
    );

    return `${cognitoURL}/oauth2/authorize${queryParamsString}`;
};

export const buildSignOutURL = signOutRedirectURL =>
    cognitoURL +
    '/logout?client_id=' +
    clientID +
    '&logout_uri=' +
    signOutRedirectURL;

export const signOutURL = provider => {
    if (!identityProviderSignOutURLs[provider]) {
        return buildSignOutURL(window.location.origin);
    }
    return buildSignOutURL(identityProviderSignOutURLs[provider]['signOut']);
};

export const clearPastSSOSessionURL = provider => {
    if (!identityProviderSignOutURLs[provider]) {
        return routes.HOME_PAGE;
    }
    return buildSignOutURL(
        identityProviderSignOutURLs[provider]['clearPastSession']
    );
};
