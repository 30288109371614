import React, { useRef } from 'react';
import styled from 'styled-components';
import Downshift from 'downshift';
import { visuallyHidden } from 'donut-ui';
import ArrowDropDown from '@ui/icons/ArrowDropDown';
import { isString } from '@utilities/dataTypes/typeChecks';
import { whenNotProvided, whenProvided } from '@utilities/stylingUtils';
import { onButtonTrigger } from '@utilities/keyboardEvents';
import { filterOptions } from './dropdowns/autocompleteFilter';
import autocompleteInputEvents from './dropdowns/autocompleteInputEvents';
import InputLabel from '@ui/messages/InputLabel';
import OptionList from '@ui/inputs/dropdowns/OptionList';
import { BaseOption } from '@ui/inputs/dropdowns/Options';
import Tooltip from '@ui/Tooltip';
import { autofillFieldnames } from '@constants/htmlSpec';

let DecoratorIcon = ({ className, onClick, icon, disabled, ...props }) => {
    return (
        <span
            className={className}
            onClick={disabled ? undefined : onClick}
            onKeyDown={disabled ? undefined : onButtonTrigger(onClick)}
            {...props}
        >
            {icon}
        </span>
    );
};

DecoratorIcon = styled(DecoratorIcon)`
    display: inline-block;
    height: 24px;
    position: absolute;
    right: 4px;
    bottom: calc(50% - 12px);
    color: var(--theme-gray2);

    ${whenNotProvided('disabled')`
        cursor: pointer;
        color: var(--theme-gray3);
    `}
`;

const DropdownIcon = styled(DecoratorIcon).attrs({
    icon: <ArrowDropDown />
})`
    right: 0;
    width: 18px;

    svg {
        height: 5px;
    }
`;

const Input = styled.input`
    background: var(--theme-white);
    color: var(--theme-dark-gray);
    border: 1px solid var(--theme-gray1);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1rem;
    height: 40px;

    padding: 10px 24px 10px 12px;
    ${({ size }) => (size === 'full' ? 'width: 100%;' : 'width: 300px;')}

    ${whenProvided('disabled')`
        color: var(--theme-gray3);
    `}
`;

const InputContainer = styled.span`
    position: relative;
    height: 100%;
    width: 100%;
    display: inline-block;

    ${whenProvided('openUpwards')`            
         ${OptionList}{
            top: auto;
            bottom: calc(100% + 4px);
         }
    `}
`;

const renderOptions = ({ options, highlightedIndex, testId, getItemProps }) => {
    return options.map((item, index) => (
        <BaseOption
            data-testid={`${testId}-${item.label}`}
            highlighted={highlightedIndex === index}
            {...getItemProps({
                item,
                index,
                key: (item.value && item.value.key) || item.value || index
            })}
        >
            {item.label || item}
        </BaseOption>
    ));
};

const Container = styled.div`
    position: relative;

    ${InputLabel} {
        margin-bottom: 4px;

        ${whenProvided('a11yLabel')`
            ${visuallyHidden}
        `}
    }

    ${Tooltip} {
        margin-left: 4px;
    }
`;

const itemToString = item => item?.label || item || '';

const currentInputText = (selectedItem, inputValue, allowFreeText) => {
    return allowFreeText && isString(selectedItem) && selectedItem !== ''
        ? selectedItem
        : inputValue;
};

const showAllWhenSelected = ({ selectedItem }) => !!selectedItem;

const Autocomplete = props => {
    const inputRef = useRef(null);
    const {
        a11yLabel,
        label,
        options = [],
        placeholder,
        disabled,
        required,
        formatSelectedValue,
        onSelect,
        onChange,
        control = {},
        openOnFocus,
        openUpwards,
        inputSize,
        allowFreeText,
        matchOnLabel,
        alreadyOrdered,
        initialSelectedItem,
        className,
        testId,
        tooltip,
        name,
        autofill
    } = props;

    return (
        <Downshift
            onChange={onChange}
            onSelect={onSelect}
            itemToString={formatSelectedValue || itemToString}
            initialSelectedItem={initialSelectedItem}
            {...control}
        >
            {downshift => {
                const {
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    getLabelProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                    setState
                } = downshift;

                const validOptions = filterOptions({
                    options,
                    inputValue,
                    selectedItem,
                    matchOnLabel,
                    alreadyOrdered,
                    shouldShowAll: showAllWhenSelected
                });

                return (
                    <div className={className}>
                        <Container a11yLabel={a11yLabel}>
                            {label && (
                                <>
                                    <InputLabel
                                        {...getLabelProps()}
                                        disabled={disabled}
                                        required={required}
                                        as="span"
                                    >
                                        {label}{' '}
                                        {required ? <span>*</span> : null}
                                    </InputLabel>
                                    {tooltip && <Tooltip>{tooltip}</Tooltip>}
                                </>
                            )}
                            <InputContainer openUpwards={openUpwards}>
                                <Input
                                    type="text"
                                    data-testid={testId}
                                    size={inputSize}
                                    ref={inputRef}
                                    {...getInputProps({
                                        value: currentInputText(
                                            selectedItem,
                                            inputValue,
                                            allowFreeText
                                        ),
                                        disabled,
                                        placeholder,
                                        ...autocompleteInputEvents(
                                            props,
                                            downshift
                                        )
                                    })}
                                    autoComplete={
                                        autofillFieldnames.has(name)
                                            ? autofill || 'random-string'
                                            : 'off'
                                    }
                                />
                                {openOnFocus && (
                                    <DropdownIcon
                                        disabled={disabled}
                                        onClick={() => {
                                            setState({
                                                isOpen: !isOpen
                                            });
                                        }}
                                    />
                                )}
                                <OptionList
                                    isOpen={
                                        isOpen &&
                                        (inputValue || openOnFocus) &&
                                        validOptions.length > 0
                                    }
                                    {...getMenuProps({
                                        suppressRefError: true
                                    })}
                                >
                                    {renderOptions({
                                        options: validOptions || [],
                                        highlightedIndex,
                                        getItemProps,
                                        testId
                                    })}
                                </OptionList>
                            </InputContainer>
                        </Container>
                    </div>
                );
            }}
        </Downshift>
    );
};

export default Autocomplete;
