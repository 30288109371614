import routes from '@constants/routes';

const ssoProviders = {
    GASSO: 'GASSO',
    CASSO: 'CASSO',
    Okta: 'Okta',
    OktaSansState: 'OktaSansState',
    K12: 'K12'
};

export const ssoProvidersArray = Object.values(ssoProviders);

const ssoProviderLogos = [
    ssoProviders.Okta,
    ssoProviders.OktaSansState,
    ssoProviders.K12
];

const partnerProviders = [ssoProviders.K12, ssoProviders.OktaSansState];

export const isPartnerProvider = provider =>
    partnerProviders.includes(provider);

export const isPartnerOrSSOProvider = provider =>
    ssoProvidersArray.includes(provider);

export const ssoProviderHasLogo = provider =>
    ssoProviderLogos.includes(provider);

export const providers = {
    COGNITO: 'cognito',
    ...ssoProviders
};

// Location where user will be redirected after being sent to
// https://uap.auth.us-east-1.amazoncognito.com/logout
export const identityProviderSignOutURLs = {
    [providers.GASSO]: {
        clearPastSession: `${window.location.origin}${routes.FEDERATED_SIGN_IN}/${providers.GASSO}`,
        signOut: 'https://app.gadoe.org/lexileweb/lexileip.aspx'
    },
    [providers.CASSO]: {
        clearPastSession: `${window.location.origin}${routes.FEDERATED_SIGN_IN}/${providers.CASSO}`,
        signOut: process.env.REACT_APP_CASSO_SIGNOUT
    },
    [providers.Okta]: {
        clearPastSession: `${window.location.origin}${routes.FEDERATED_SIGN_IN}/${providers.Okta}`,
        signOut: process.env.REACT_APP_OKTA_SIGNOUT
    },
    [providers.OktaSansState]: {
        clearPastSession: `${window.location.origin}${routes.FEDERATED_SIGN_IN}/${providers.OktaSansState}`,
        signOut: process.env.REACT_APP_OKTASANSSTATE_SIGNOUT
    },
    [providers.K12]: {
        clearPastSession: `${window.location.origin}${routes.FEDERATED_SIGN_IN}/${providers.K12}`,
        signOut: process.env.REACT_APP_K12_LOGOUT_URL
    }
};

export const identityProviderBackToPortalURLs = {
    [providers.CASSO]: {
        text: 'TOMS',
        url: process.env.REACT_APP_CASSO_SIGNOUT
    },
    [providers.Okta]: {
        text: 'Okta Home',
        url: process.env.REACT_APP_OKTA_HOME
    }
};
