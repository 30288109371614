import styled from 'styled-components';

const NotificationBubble = styled.span`
    display: inline-block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    z-index: 1;

    line-height: 20px;
    font-size: 0.875rem;
    text-align: center;
    font-weight: bold;
    color: var(--theme-white);
    background-color: var(--theme-red);

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export default NotificationBubble;
