import { insert } from 'ramda';
import { sortByProp, alphabetize } from '@utilities/dataTypes/arrays';
import { propEquals } from '@utilities/dataTypes/objects';

export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
];

export const partnerStates = [
    'Alabama',
    'California',
    'Georgia',
    'Idaho',
    'Illinois',
    'Indiana',
    'Kansas',
    'Minnesota',
    'Missouri',
    'Nevada',
    'New Hampshire',
    'North Carolina',
    'North Dakota',
    'South Carolina',
    'Tennessee',
    'Texas',
    'Vermont',
    'Virgin Islands',
    'West Virginia',
    'Wyoming'
];

export const stateAndTerritories = alphabetize([
    ...states,
    'Virgin Islands',
    'District of Columbia'
]);

const strToLabelValue = str => ({ label: str, value: str });
export const labelValueStates = states.map(strToLabelValue);
export const labelValueStatesAndTerritories = stateAndTerritories.map(
    strToLabelValue
);

export const stateItems = [
    { name: 'Alabama', shortName: 'AL' },
    { name: 'Alaska', shortName: 'AK' },
    { name: 'Arizona', shortName: 'AZ' },
    { name: 'Arkansas', shortName: 'AR' },
    { name: 'California', shortName: 'CA' },
    { name: 'Colorado', shortName: 'CO' },
    { name: 'Connecticut', shortName: 'CT' },
    { name: 'Delaware', shortName: 'DE' },
    { name: 'Florida', shortName: 'FL' },
    { name: 'Georgia', shortName: 'GA' },
    { name: 'Hawaii', shortName: 'HI' },
    { name: 'Idaho', shortName: 'ID' },
    { name: 'Illinois', shortName: 'IL' },
    { name: 'Indiana', shortName: 'IN' },
    { name: 'Iowa', shortName: 'IA' },
    { name: 'Kansas', shortName: 'KS' },
    { name: 'Kentucky', shortName: 'KY' },
    { name: 'Louisiana', shortName: 'LA' },
    { name: 'Maine', shortName: 'ME' },
    { name: 'Maryland', shortName: 'MD' },
    { name: 'Massachusetts', shortName: 'MA' },
    { name: 'Michigan', shortName: 'MI' },
    { name: 'Minnesota', shortName: 'MN' },
    { name: 'Mississippi', shortName: 'MS' },
    { name: 'Missouri', shortName: 'MO' },
    { name: 'Montana', shortName: 'MT' },
    { name: 'Nebraska', shortName: 'NE' },
    { name: 'Nevada', shortName: 'NV' },
    { name: 'New Hampshire', shortName: 'NH' },
    { name: 'New Jersey', shortName: 'NJ' },
    { name: 'New Mexico', shortName: 'NM' },
    { name: 'New York', shortName: 'NY' },
    { name: 'North Carolina', shortName: 'NC' },
    { name: 'North Dakota', shortName: 'ND' },
    { name: 'Ohio', shortName: 'OH' },
    { name: 'Oklahoma', shortName: 'OK' },
    { name: 'Oregon', shortName: 'OR' },
    { name: 'Pennsylvania', shortName: 'PA' },
    { name: 'Rhode Island', shortName: 'RI' },
    { name: 'South Carolina', shortName: 'SC' },
    { name: 'South Dakota', shortName: 'SD' },
    { name: 'Tennessee', shortName: 'TN' },
    { name: 'Texas', shortName: 'TX' },
    { name: 'Utah', shortName: 'UT' },
    { name: 'Vermont', shortName: 'VT' },
    { name: 'Virginia', shortName: 'VA' },
    { name: 'Washington', shortName: 'WA' },
    { name: 'West Virginia', shortName: 'WV' },
    { name: 'Wisconsin', shortName: 'WI' },
    { name: 'Wyoming', shortName: 'WY' }
];

const asOptions = arr => ({
    byName: arr.map(({ name }) => strToLabelValue(name)),
    byAbbreviation: arr.map(({ name, shortName }) => ({
        label: name,
        value: shortName
    }))
});

export const stateAndCCItems = [
    { name: 'Common Core', shortName: 'CC' },
    ...sortByProp('name')([
        ...stateItems,
        { name: 'District of Columbia', shortName: 'DC' },
        { name: 'DODEA', shortName: 'DD' },
        { name: 'Virgin Islands', shortName: 'VI' }
    ]),
    { name: 'South Africa', shortName: 'SA' }
];

export const stateAndTerritoryItems = [
    ...sortByProp('name')([
        ...stateItems,
        { name: 'Virgin Islands', shortName: 'VI' },
        { name: 'District of Columbia', shortName: 'DC' }
    ])
];

export const stateCCAndCCRItems = insert(
    1,
    {
        name: 'CCR Standards for Adult Education',
        shortName: 'CR'
    },
    stateAndCCItems
);

export const stateOptions = asOptions(stateItems);
export const stateAndCCOptions = asOptions(stateAndCCItems);
export const stateCCAndCCROptions = asOptions(stateCCAndCCRItems);

export const getShortName = state => {
    return state?.length === 2
        ? state
        : stateAndTerritoryItems.find(propEquals('name', state))?.shortName;
};

export const getLongName = state => {
    return state?.length !== 2
        ? state
        : stateAndTerritoryItems.find(propEquals('shortName', state))?.name;
};
