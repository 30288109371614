import { useEffect, useRef } from 'react';

const useOutsideClickListener = (handleClick, isListening = true) => {
    const ref = useRef(null);

    useEffect(() => {
        const onClickOutside = e => {
            if (!ref || !isListening || ref.current.contains(e.target)) {
                return;
            }

            handleClick();
        };

        document.addEventListener('mousedown', onClickOutside);

        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, [handleClick, isListening]);

    return ref;
};

export default useOutsideClickListener;
