/*
 * Note: some client-sent reporting (that hopefully we won't do for much longer) relies
 * on these paths for certain tools. Before changing, check and update-if-needed
 * hub-dashboard/hubdash/backend/google_analytics.py
 */

const routes = {
    ACCOUNT: '/account',
    BILLING: '/billing',
    CAREER_DATABASE_QUANTILE: '/quantile-career-database',
    CAREER_DATABASE_LEXILE: '/lexile-career-database',
    CONFIRM: '/confirm',
    DECODABLE_PASSAGES: '/decodable-passages',
    FEDERATED_SIGN_IN: '/federated-sign-in',
    START_SSO: '/start-sso',
    FAMILY_HOME: '/family',
    FIND_A_BOOK: '/find-a-book',
    BOOK_DETAILS: '/book-details',
    FIND_A_DECODABLE_BOOK: '/find-a-decodable-book',
    GROWTH_PLANNER_QUANTILE: '/quantile-growth-planner',
    GROWTH_PLANNER_LEXILE: '/lexile-growth-planner',
    HOME_PAGE: '/',
    MATH_AT_HOME: '/math-at-home',
    MATH_SKILLS_DATABASE: '/math-skills-database',
    MEMBERSHIP_OPTIONS: '/membership-options',
    MMM_LEXILE: '/lexile-measures-manager',
    MMM_QUANTILE: '/quantile-measures-manager',
    NORMS_LEXILE: '/lexile-grade-level-charts',
    NORMS_QUANTILE: '/quantile-grade-level-charts',
    NOT_FOUND: '/not-found',
    QSC_DETAIL: '/quantile-skill-and-concept',
    QUANTILE_TEACHER_ASSISTANT: '/quantile-teacher-assistant',
    REGISTRATION_EMAIL: '/registration-email',
    REGISTRATION: '/registration',
    REQUEST_PASSWORD: '/request-password',
    RESET_PASSWORD: '/password_reset',
    RESOURCE_CENTER: '/quantile-resource-center',
    SIGN_IN: '/sign-in',
    SUMMER_MATH_CHALLENGE: '/summer-math-challenge',
    TEXT_ANALYZER: '/analyzer',
    VERIFY_EMAIL: '/verify',
    WELCOME: '/welcome',
    WORDLISTS: '/wordlists'
};

export default routes;
