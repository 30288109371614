import React from 'react';
import styled, { css } from 'styled-components';
import { useUniqueId } from 'donut-ui';
import InputLabel from '@ui/messages/InputLabel';
import { TooltipWrapper } from '@ui/Tooltip';
import { autofillFieldnames } from '@constants/htmlSpec';
import { onEscape } from 'utilities/keyboardEvents';
import useTooltip from 'utilities/hooks/useTooltip';

export const TextInputElement = styled.input`
    border: 1px solid var(--theme-gray1);
    background: var(--theme-white);
    border-radius: 4px;
    height: 40px;
    width: 100%;
    font-size: 1rem;
    color: var(--theme-dark-gray);
`;

const layout = css`
    ${InputLabel} {
        display: inline-block;
        min-width: max-content;
        margin-right: 17px;
        margin-bottom: 4px;
        vertical-align: top;
    }

    ${TextInputElement} {
        padding: 0 6px;
    }

    .label-container {
        display: flex;
        align-items: baseline;
    }

    .required-span {
        margin-left: 4px;
    }
`;

const TextInput = ({
    className,
    children,
    label,
    name,
    value,
    onChange,
    onClick,
    onFocus,
    autoFocus,
    onBlur,
    disabled,
    required,
    inputRef,
    type = 'text',
    id,
    maxLength,
    tooltip,
    tooltipLeft = false,
    tooltipIcon = true,
    fullWidth,
    ...props
}) => {
    const uid = useUniqueId('textfield');
    const labelId = useUniqueId('label');

    const { tooltipId, tooltipRef, showTooltip, hideTooltip } = useTooltip();

    const handleBlur = event => {
        onBlur && onBlur(event);
        hideTooltip();
    };

    const handleFocus = () => {
        onFocus && onFocus();
        showTooltip();
    };

    return tooltip ? (
        <div className={className} onClick={onClick}>
            <TooltipWrapper
                id={tooltipId}
                ref={tooltipRef}
                text={tooltip}
                tooltipLeft={tooltipLeft}
                tooltipIcon={tooltipIcon}
                fullWidth={fullWidth}
            >
                {label && (
                    <InputLabel
                        id={labelId}
                        htmlFor={id || uid}
                        disabled={disabled}
                        required={required}
                    >
                        {label}
                        {required ? (
                            <span className="required-span" aria-hidden="true">
                                *
                            </span>
                        ) : null}
                    </InputLabel>
                )}
                <TextInputElement
                    aria-required={required}
                    aria-describedby={tooltipId}
                    ref={inputRef}
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onKeyUp={onEscape(hideTooltip)}
                    autoFocus={autoFocus}
                    id={id || uid}
                    disabled={disabled}
                    maxLength={maxLength}
                    autoComplete={
                        autofillFieldnames.has(name) ? 'disabled' : 'off'
                    }
                    {...props}
                />
            </TooltipWrapper>
            {children}
        </div>
    ) : (
        <div className={className} onClick={onClick}>
            {label && (
                <div className="label-container">
                    <InputLabel
                        id={labelId}
                        htmlFor={id || uid}
                        disabled={disabled}
                        required={required}
                    >
                        {label}
                        {required ? (
                            <span className="required-span" aria-hidden="true">
                                *
                            </span>
                        ) : null}
                    </InputLabel>
                </div>
            )}
            <TextInputElement
                aria-required={required}
                ref={inputRef}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                autoFocus={autoFocus}
                id={id || uid}
                disabled={disabled}
                maxLength={maxLength}
                autoComplete={autofillFieldnames.has(name) ? 'disabled' : 'off'}
                {...props}
            />
            {children}
        </div>
    );
};

export default styled(TextInput)`
    ${layout}
`;
