import React from 'react';
import { type } from 'ramda';

// Node doesn't have a 'window' object, so we look for 'global' if we're running tests
// The 'window.__hubtext' value gets populated from within '/public/index.html'.
let messages = window.__hubText || global.__hubText;

const curlyBraceNum = /{([0-9])}/g;
const replaceStringParams = (str = '', params) =>
    str.replace(curlyBraceNum, (_, paramIndex) => params[paramIndex]);

/**
 * Sets the current language for the app. In the future, this might
 * be better placed elsewhere, but this will at least get us started.
 */
export const setLanguage = language => (messages = language);

/**
 * Get the text string for the associated string id, formatting parameters if necessary,
 * for the current language
 *
 * @example
 * //assume 'account.cardEndingIn' = 'Card ending in *{0}' in en-us.json...
 * i18n('account.cardEndingIn', 1234) //returns "Card ending in *1234"
 */
export const i18n = (id = '', ...params) => {
    if (!messages[id]) {
        throw new Error(`Message does not exist for key: ${id}`);
    }
    const message =
        type(messages[id]) === 'Object' ? messages[id].text : messages[id];

    return params.length === 0 ? message : replaceStringParams(message, params);
};

/**
 * Used for i18nWithHTML strings that have multiple templated elements
 * This is baked into i18WithHTML and should not be used directly.
 * It will automatically be used if you pass in an array of renderers
 *
 * @example
 * 'example.key' = 'This is a <<example>> of the <<text>> we are parsing'
 *
 *  i18nWithHTML('example.key', [
 *    example => <b>{example}</b>,
 *    text => <b>{text}</b>
 *  ])
 **/

const multiPartI18nWithHTML = (str, renderers = []) => {
    const [current, ...rest] = renderers;

    if (!current) {
        return str;
    }

    const [, pre, content, post] = str.match(/(.*?)<<(.*?)>>(.*)/);

    const formatted = current(content);

    return (
        <>
            {pre}
            {formatted}
            {multiPartI18nWithHTML(post, rest)}
        </>
    );
};

/**
 * Get text string for supplied id, format to replace where appropriate with
 * HTML tags, allowing styling and classes to be applied in the middle
 * of a string.
 *
 * @example
 * //assume 'findABook.saveBookToShelf' = 'Save <<{0}>> to a Bookshelf'...
 *            bookTitle = 'Red Rising'
 * //usage i18nWithHTML('findABook.saveBookToShelf',
 *     bookTitle => (<span class="myClass">{ bookTitle }</span>),
 *     bookTitle)
 * //returns <span>Save
 *               <span class="myClass">Red Rising</span> to a BookShelf
 *           </span>
 */
export const i18nWithHTML = (id, renderer, ...params) => {
    // Call standard i18n function to replace params
    const str = i18n(id, ...params);

    // Use multipart function if renderer is an array
    if (Array.isArray(renderer)) {
        return <span>{multiPartI18nWithHTML(str, renderer)}</span>;
    }

    // Split string up on our special tag notation: '<<0>>'
    const [, pre, content, post] = str.match(/(.*)<<(.*)>>(.*)/);

    // Get formatted tag content with passed renderer function
    const formatted = renderer(content);

    return (
        <span>
            {pre}
            {formatted}
            {post}
        </span>
    );
};
