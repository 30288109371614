import { lexicalSort } from '@utilities/sorts';
import { getIn } from '@utilities/dataTypes/objects';

export const sortWith = sortFn => (array = []) => array.slice().sort(sortFn);

export const alphabetize = sortWith(lexicalSort);

export const sortByProp = (prop, sortFn = lexicalSort) => (array = [], asc) =>
    array.slice().sort((a, b) => {
        return sortFn(a[prop], b[prop], asc);
    });

export const sortByPath = (path, sortFn = lexicalSort) => (array = [], asc) =>
    array.slice().sort((a, b) => {
        return sortFn(getIn(path)(a).fold(), getIn(path)(b).fold(), asc);
    });
