import { makeEndpoint, versioned } from './httpRequest';
import createStaticResource from '@utilities/staticResources';
import { valueExistsAt, mapOverKeys } from '@utilities/dataTypes/objects';
import { filter } from '@utilities/dataTypes/arrays';

const filterResourcesFromResponse = mapOverKeys([
    'downloadable_assets',
    'print_assets',
    'web_assets'
])(filter(valueExistsAt('asset_link')));

const NAME = process.env.REACT_APP_QUANTILE_TOOLS_NAME;
const ENDPOINT = process.env.REACT_APP_QUANTILE_TOOLS_URL;

const mathApi = makeEndpoint(NAME, ENDPOINT);
export const quantileRoot = mathApi.configure();

export const findTextbook = ({
    title,
    isbn,
    publisher,
    state_edition,
    grade
}) =>
    mathApi.get('/textbooks', {
        title,
        isbn,
        publisher,
        state_edition,
        grade
    });

export const loadQSC = id => mathApi.get(`/qscs/${id}/`);

export const loadPublishers = createStaticResource(() =>
    mathApi.get('/publishers/')
);

export const loadCourseResource = id =>
    versioned('2.0')(mathApi.get)(`/textbooks/${id}/`);

export const getCoursesForState = state =>
    mathApi.get('/courses/', {
        state
    });

export const getQSCsByStateStandard = id =>
    mathApi.get(`/statestandards/${id}/`);

export const getQSCsByCourse = course =>
    mathApi.get('/statestandards/', {
        course
    });

export const getQSCsByKeyword = (keyword, measure) =>
    mathApi.get('/qscs/', {
        keyword,
        qmeasure_min: measure ? measure - 50 : '',
        qmeasure_max: measure ? measure + 50 : ''
    });

export const getStateStandards = (qsc_id, state) =>
    mathApi.get('/statestandards/', {
        qsc_id,
        state
    });

export const loadSavedResources = () => mathApi.get('/user/resources');

export const saveResource = asset_id =>
    mathApi.patch('/user/resources', {
        add: [asset_id]
    });

export const removeSavedResources = asset_ids =>
    mathApi.patch('/user/resources', {
        remove: asset_ids
    });

export const createUserDefinedList = ({ name, description, resources }) =>
    mathApi.post('/user/resource-lists', {
        name,
        description,
        add: [...resources]
    });

export const deleteUserDefinedList = id =>
    mathApi.del(`/user/resource-lists/${id}`);

export const loadSavedLists = () => mathApi.get('/user/resource-lists');

export const moveResources = (source, destination, resources) =>
    mathApi.patch('/user/resource-lists', {
        source,
        destination,
        move: resources
    });

export const loadListDetails = listId =>
    mathApi.get(`/user/resource-lists/${listId}`);

export const modifyUserList = (listId, changes) =>
    mathApi.patch(`/user/resource-lists/${listId}`, changes);

export const findResources = ({
    qsc,
    course,
    goal,
    grade,
    state,
    measureRange,
    keyword
}) => {
    const requestParams = {
        qsc,
        course,
        goal,
        grade,
        state,
        qmeasure_min: measureRange && measureRange[0],
        qmeasure_max: measureRange && measureRange[1],
        keyword
    };

    return mathApi
        .get('/resources/', requestParams)
        .then(filterResourcesFromResponse)
        .then(res => ({
            ...res,
            count: {
                total:
                    (res.downloadable_assets?.length || 0) +
                    (res.print_assets?.length || 0) +
                    (res.web_assets?.length || 0)
            }
        }));
};

export const getClustersByStateStandard = id => mathApi.get(`/cluster/${id}/`);
