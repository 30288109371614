import { useSessionState } from '@utilities/hooks/useSessionState';
import { TOOL_AUDIENCES } from '~/constants/tools';
import { i18n } from '@i18n/format';

export const filterOptions = [
    { label: i18n('tools.all'), value: TOOL_AUDIENCES.ALL },
    { label: i18n('tools.toolsForEducators'), value: TOOL_AUDIENCES.EDUCATORS },
    { label: i18n('tools.toolsForParents'), value: TOOL_AUDIENCES.PARENTS },
    { label: i18n('tools.toolsForStudents'), value: TOOL_AUDIENCES.STUDENTS },
    {
        label: i18n('tools.toolsForResearchers'),
        value: TOOL_AUDIENCES.RESEARCHERS
    }
];

export const familyFilterOptions = [
    { label: i18n('tools.home.all'), value: TOOL_AUDIENCES.ALL },
    {
        label: i18n('tools.home.toolsForEducators'),
        value: TOOL_AUDIENCES.EDUCATORS
    },
    {
        label: i18n('tools.home.toolsForParents'),
        value: TOOL_AUDIENCES.PARENTS
    },
    {
        label: i18n('tools.home.toolsForStudents'),
        value: TOOL_AUDIENCES.STUDENTS
    },
    {
        label: i18n('tools.home.toolsForResearchers'),
        value: TOOL_AUDIENCES.RESEARCHERS
    }
];

export const useToolFilter = () =>
    useSessionState('all-tools-filter', TOOL_AUDIENCES.ALL);
