import React from 'react';
import styled from 'styled-components';
import { i18n, i18nWithHTML } from '@i18n/format';
import baseRoutes from '@constants/routes';
import { pushTo } from '@utilities/navigation';
import ConfirmationDialog from '@ui/dialogs/ConfirmationDialog';
import Link from '@ui/actions/Link';

const Layout = styled.div`
    width: 550px;
    p:first-of-type {
        margin-bottom: 20px;
    }
`;

const TrialExpired = ({ dialogState }) => {
    return (
        <ConfirmationDialog
            title={i18n('account.wereSorry')}
            confirmationLabel={i18n('account.upgradeToPremium')}
            cancelLabel={i18n('account.continueToBasicPlus')}
            dialogState={dialogState}
            onConfirm={() => pushTo(baseRoutes.BILLING)}
            onCancel={() => pushTo(baseRoutes.MEMBERSHIP_OPTIONS)}
            forceDecision
        >
            <Layout>
                <p>
                    {i18nWithHTML(
                        'account.trialEnded',
                        seeMembershipOptions => (
                            <Link
                                onClick={dialogState.closeDialog}
                                to={baseRoutes.MEMBERSHIP_OPTIONS}
                                $noUnderline
                            >
                                {seeMembershipOptions}
                            </Link>
                        )
                    )}
                </p>
                <p>{i18n('account.trialEndedContinueBoogaloo')}</p>
            </Layout>
        </ConfirmationDialog>
    );
};

export default TrialExpired;
