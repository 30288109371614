import React from 'react';

const LexileLogo = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="150 150 1250 1250"
            fill={props.fill || '#fff'}
            height={36}
            aria-hidden="true"
            {...props}
        >
            <path d="M233.25 648.37h-42.14v203.26h109.56v-40.38h-67.42zM388.6 851.63h121.44v-39.87h-79.3v-41.7h68.69v-40.12h-68.69v-41.7h79.3v-39.87H388.6zM777.08 648.37h-49.91l-40.91 63.57-40.92-63.57h-49.86l67.02 99.86-69.56 103.4h50.14l42.94-67.3 43.17 67.3h50.12l-69.55-103.64zM861.6 648.37h42.14v203.26H861.6zM1053 648.37h-42.14v203.26h109.57v-40.38H1053zM1308.6 688.24v-39.87h-121.44v203.26h121.44v-39.87h-79.3v-41.7h68.69v-40.12h-68.69v-41.7zM1252.21 1264.04c0 22.9-18.68 41.58-41.58 41.58s-41.58-18.68-41.58-41.58 18.69-41.58 41.58-41.58c22.89 0 41.58 18.68 41.58 41.58zm-6.37 0c0-19.55-15.55-34.99-35.21-34.99-19.55 0-34.99 15.44-34.99 34.99 0 19.55 15.44 34.99 34.99 34.99 19.65.01 35.21-15.44 35.21-34.99zm-27.98 2.59l10.04 18.36h-6.8l-9.72-17.5h-5.94v17.5h-5.94v-41.91h13.82c6.91 0 12.42 5.4 12.42 12.2.01 5.2-3.23 9.63-7.88 11.35zm-12.42-5.07h7.88c3.56 0 6.37-2.81 6.37-6.27 0-3.46-2.81-6.26-6.37-6.26h-7.88v12.53zM622.34 595.19H191.11C258.93 348.24 485.01 166.8 753.5 166.8c190.31 0 359.28 91.18 465.72 232.2-62.13-36.4-134.45-57.3-211.66-57.3-172.67 0-320.95 104.39-385.22 253.49zm-129.9 563.11c-77.21 0-149.53-20.89-211.66-57.3 106.44 141.03 275.42 232.2 465.72 232.2 268.49 0 494.57-181.45 562.39-428.39H877.66c-64.27 149.1-212.55 253.49-385.22 253.49z" />
        </svg>
    );
};

export default LexileLogo;
