import { useEffect } from 'react';
import { apply } from 'ramda';
import { match, constant } from '@utilities/dataTypes/functions';
import {
    onSessionEnd,
    resetSessionTimer
} from '@utilities/analytics/sessionAnalytics';
import usageTracker from './usageTracker';
import {
    startTransaction,
    addTransactionLabels,
    endTransaction
} from './agent';

const createEvent = (name, type, prefix) => async labels => {
    const transaction = startTransaction(name, type);
    await addTransactionLabels(labels, prefix)(transaction);
    endTransaction(transaction);
};

export const withAnalytics = (event, labels) => func => (...args) => {
    event(labels);
    return apply(func, args);
};

export const useAnalytics = (name, type, labels) => {
    useEffect(() => {
        const transaction = startTransaction(name, type);
        addTransactionLabels(labels)(transaction);
        return () => endTransaction(transaction);
    }, [name, type, labels]);
};

export const trackPageViews = location => {
    createEvent(location.pathname, 'navigation')();
    usageTracker.incPageCount();
};

export const onSignIn = () =>
    createEvent('Successful Sign-In', 'hub-platform')();

/************************************************
 *  End of Session Events
 ***********************************************/

document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
        onSessionEnd();
    }

    if (document.visibilityState === 'visible') {
        resetSessionTimer();
    }
});

/************************************************
 *  Hub platform general/account events
 ***********************************************/

export const checkedNotifications = createEvent(
    'Checked Notifications',
    'hub-platform'
);
export const clickedNotification = createEvent(
    'Clicked Notification',
    'hub-platform'
);

export const clickedBanner = createEvent('Banner Clicked', 'hub-platform');
export const disabledAutoRenew = createEvent(
    'Disabled Auto Renew',
    'hub-platform'
);
export const studentProceededToBasic = createEvent(
    'Student Proceeded To Basic',
    'hub-platform'
);
export const upgradedToBasicPlus = createEvent(
    'Upgraded to Basic+',
    'hub-platform'
);
export const upgradedToPremium = createEvent(
    'Upgraded to Premium',
    'hub-platform'
);
export const redirectedFromFamilyLink = createEvent(
    'Redirected from family link',
    'hub-platform'
);

/************************************************
 *  Lexile Career Database
 ***********************************************/

export const cdbSearchLexile = createEvent(
    'Clicked Search Button',
    'lexile-career-db',
    'lcdb'
);
export const cdbUsedStateDropdownSearchLexile = createEvent(
    'State level search - dropdown',
    'lexile-career-db',
    'lcdb'
);
export const cdbUsedMsaDropdownSearchLexile = createEvent(
    'MSA level search - dropdown',
    'lexile-career-db',
    'lcdb'
);
export const cdbUsedStateMapSearchLexile = createEvent(
    'State level search - map',
    'lexile-career-db',
    'lcdb'
);
export const cdbUsedMsaMapSearchLexile = createEvent(
    'MSA level search - map',
    'lexile-career-db',
    'lcdb'
);
export const cdbClickedRelatedOccupationLexile = createEvent(
    'Clicked Related Occupation',
    'lexile-career-db',
    'lcdb'
);
export const cdbClickedGoToGPLexile = createEvent(
    'Clicked go to GP button',
    'lexile-career-db',
    'lcdb'
);

/************************************************
 *  Quantile Career Database
 ***********************************************/

export const cdbSearchQuantile = createEvent(
    'Clicked Search Button',
    'quantile-career-db',
    'qcdb'
);
export const cdbUsedStateDropdownSearchQuantile = createEvent(
    'State level search - dropdown',
    'quantile-career-db',
    'qcdb'
);
export const cdbUsedMsaDropdownSearchQuantile = createEvent(
    'MSA level search - dropdown',
    'quantile-career-db',
    'qcdb'
);
export const cdbUsedStateMapSearchQuantile = createEvent(
    'State level search - map',
    'quantile-career-db',
    'qcdb'
);
export const cdbUsedMsaMapSearchQuantile = createEvent(
    'MSA level search - map',
    'quantile-career-db',
    'qcdb'
);
export const cdbClickedRelatedOccupationQuantile = createEvent(
    'Clicked Related Occupation',
    'quantile-career-db',
    'qcdb'
);
export const cdbClickedGoToGPQuantile = createEvent(
    'Clicked go to GP button',
    'quantile-career-db',
    'qcdb'
);

/************************************************
 *  Math at Home
 ***********************************************/
export const mahSearch = createEvent('Search', 'math-at-home', 'mah');
export const mahSavedResource = createEvent(
    'Saved Resource',
    'math-at-home',
    'mah'
);
export const mahLearnMore = createEvent(
    'Clicked Learn More',
    'math-at-home',
    'mah'
);
export const mahNavigatedFromTool = createEvent(
    'Navigated to MAH from Other Tool',
    'math-at-home',
    'msd'
);

/************************************************
 *  Growth Planner
 ***********************************************/

export const gpClickedAddMeasure = createEvent(
    'Clicked Add/Edit Measure',
    'growth-planner',
    'gp'
);
export const gpSubmitMeasures = createEvent(
    'Clicked Submit in Add/Edit Measure',
    'growth-planner',
    'gp'
);
export const gpClickedAddCareer = createEvent(
    'Clicked Add/Edit Career',
    'growth-planner',
    'gp'
);
export const gpSubmitAddCareer = createEvent(
    'Clicked Submit in Add/Edit Career',
    'growth-planner',
    'gp'
);
export const gpViewedCareerDetails = createEvent(
    'Viewed Career Details Page',
    'growth-planner',
    'gp'
);
export const gpClickedBackOnDetailPage = createEvent(
    'Clicked Back on Career Details Page',
    'growth-planner',
    'gp'
);
export const gpUsedStateDropdownSearch = createEvent(
    'State level search - Dropdown',
    'growth-planner',
    'gp'
);
export const gpUsedMsaDropdownSearch = createEvent(
    'MSA level search - Dropdown'
);
export const gpUsedStateMapSearch = createEvent(
    'State level search - Map',
    'growth-planner',
    'gp'
);
export const gpUsedMsaMapSearch = createEvent(
    'MSA level search - Map',
    'growth-planner',
    'gp'
);
export const gpClickedFabLink = createEvent(
    'Clicked Find a Book Button - Lexile',
    'growth-planner',
    'gp'
);
export const gpClickedOnetLinkLexile = createEvent(
    'Clicked ONet Button - Lexile',
    'growth-planner',
    'gp'
);
export const gpClickedWordListsLink = createEvent(
    'Clicked WordLists Button - Lexile',
    'growth-planner',
    'gp'
);
export const gpClickedMathAtHomeLink = createEvent(
    'Clicked MathAtHome Button - Quantile',
    'growth-planner',
    'gp'
);
export const gpClickedMathSkillsDBLink = createEvent(
    'Clicked MathSkillsDB Button - Quantile',
    'growth-planner',
    'gp'
);
export const gpClickedOnetLinkQuantile = createEvent(
    'Clicked ONet Button - Quantile',
    'growth-planner',
    'gp'
);
// TODO: wire this up once Related Occupations become links
export const gpClickedRelatedCareer = createEvent(
    'Clicked a related occupation link',
    'growth-planner',
    'gp'
);

/************************************************
 *  Quantile Teacher Assistant
 ***********************************************/

export const qtaClickedSearch = createEvent(
    'Clicks Search in Quantile Teacher Assistant',
    'quantile-teacher-assistant',
    'qta'
);

/************************************************
 *  Find a Book
 ***********************************************/

const formatIndicatorForAPMLabel = indicator =>
    indicator ? `${indicator.gte} - ${indicator.lte}` : '';
const formatQuickSearchForAPMLabel = quickSearch =>
    quickSearch?.value
        ? `${quickSearch.value.searchStr} in ${quickSearch.value.field}`
        : quickSearch;

export const fabSearch = (filters, spanishSearch) => {
    createEvent(
        'Search',
        'find-a-book',
        'fab'
    )({
        ...filters,
        quickSearch: formatQuickSearchForAPMLabel(filters.quickSearch),
        grade: filters.grade ? filters.grade - 1 : '',
        booksInSpanish: spanishSearch || '',
        categories: filters.categories?.toString(),
        interests: filters.interests?.toString(),
        lexileCodes: filters.lexileCodes?.toString(),
        lexileRange: filters.lexileRange?.start
            ? `${filters.lexileRange.start} - ${filters.lexileRange.end}`
            : '',
        subCategories: filters.subCategories?.toString(),
        decoding_demand_percentile: formatIndicatorForAPMLabel(
            filters.decoding_demand_percentile
        ),
        semantic_demand_percentile: formatIndicatorForAPMLabel(
            filters.semantic_demand_percentile
        ),
        structure_demand_percentile: formatIndicatorForAPMLabel(
            filters.structure_demand_percentile
        ),
        syntactic_demand_percentile: formatIndicatorForAPMLabel(
            filters.syntactic_demand_percentile
        ),
        mlf: formatIndicatorForAPMLabel(filters.mlf),
        msl: formatIndicatorForAPMLabel(filters.msl)
    });
};
export const fabClickedRegister = createEvent(
    'Clicked Register Button',
    'find-a-book',
    'fab'
);
export const fabClickedUpgrade = createEvent(
    'Clicked Upgrade Button',
    'find-a-book',
    'fab'
);
export const fabClickedWhatAreLexiles = createEvent(
    "Clicked 'What are Lexile Measures?' link ",
    'find-a-book',
    'fab'
);
export const fabClickedNewSearch = createEvent(
    'Clicked New Search Button',
    'find-a-book',
    'fab'
);
export const fabFavoritedBook = createEvent(
    'Added Book to Favorites',
    'find-a-book',
    'fab'
);
export const fabClickedFindOnAmazon = createEvent(
    'Clicked Find on Amazon Button',
    'find-a-book',
    'fab'
);
export const fabClickedFindInLibrary = createEvent(
    'Clicked Find in Library Button',
    'find-a-book',
    'fab'
);
export const fabClickedCreateAFreeAccount = createEvent(
    'Clicked Create a Free Account Link',
    'find-a-book',
    'fab'
);
export const fabClickedAdditionalISBNs = createEvent(
    'Clicked Additional ISBNs Button',
    'find-a-book',
    'fab'
);

/************************************************
 *  Math Skills Database
 ***********************************************/

export const msdSearch = createEvent('Search', 'math-skills-database', 'msd');

export const msdSavedResource = createEvent(
    'Saved Resource',
    'math-skills-database',
    'msd'
);

export const msdClickedLearnMore = createEvent(
    'Clicked Learn more about Skills and Concept Measures link',
    'math-skills-database',
    'msd'
);

export const msdNavigatedFromTool = createEvent(
    'Navigated to MSD from Other Tool',
    'math-skills-database',
    'msd'
);

/************************************************
 *  Measures Manager
 ***********************************************/

export const mmSearchLexile = createEvent(
    'Search - Lexile',
    'measures-manager',
    'mmm'
);

export const mmSearchQuantile = createEvent(
    'Search - Quantile',
    'measures-manager',
    'mmm'
);

/************************************************
 *  Analyzer
 ***********************************************/

export const taAnalyzed = createEvent('Analyzed', 'text-analyzer', 'ta');

export const taAnalyzedSpanish = createEvent(
    'Analyzed Spanish',
    'text-analyzer',
    'ta'
);

export const taAnalysisResult = createEvent(
    'Analysis Result',
    'text-analyzer',
    'ta'
);

export const taClickedTermsOfUse = createEvent(
    'Clicked terms of use',
    'text-analyzer',
    'ta'
);

export const taAnalyzerError = createEvent(
    'Text not measurable',
    'text-analyzer',
    'ta'
);

export const taClickedEditingGuidelines = createEvent(
    'Clicked editing guidelines',
    'text-analyzer',
    'ta'
);

/************************************************
 *  Quantile Resource Center
 ***********************************************/

export const rcNavigatedToFromTool = createEvent(
    'Viewed RC from another tool',
    'resource-center',
    'rc'
);

export const rcCreatedList = createEvent(
    'Created a list',
    'resource-center',
    'rc'
);

export const rcViewedList = createEvent(
    'Viewed a custom list',
    'resource-center',
    'rc'
);

/************************************************
 *  WordLists
 ***********************************************/
export const wlDownload = filters =>
    createEvent(
        'Downloaded a word list',
        'word-lists',
        'wl'
    )({
        contentType: filters.contents,
        gradeRange: match(filters.grade)
            .on(grade => grade >= '1' && grade <= '5', constant('1-5'))
            .otherwise(() => filters.grade),
        domains: filters.domains.toString(),
        fileFormats: filters.fileFormats.toString()
    });

/************************************************
 *  Grade Level Charts
 ***********************************************/
export const lexileGradeDropdownBlurred = createEvent(
    'Clicked grade dropdown - Lexile',
    'lexile-grade-level-charts',
    'lglc'
);

export const lexileTimeOfYearBlurred = createEvent(
    'Clicked Time of Year - Lexile',
    'lexile-grade-level-charts',
    'lglc'
);

export const lexilePercentileChanged = createEvent(
    'Changed percentile slider - Lexile',
    'lexile-grade-level-charts',
    'lglc'
);

export const lexileDownloadedPDF = createEvent(
    'Downloaded chart PDF - Lexile',
    'lexile-grade-level-charts',
    'lglc'
);

export const quantileGradeDropdownBlurred = createEvent(
    'Clicked grade dropdown - Quantile',
    'quantile-grade-level-charts',
    'qglc'
);

export const quantileTimeOfYearBlurred = createEvent(
    'Clicked Time of Year - Quantile',
    'quantile-grade-level-charts',
    'qglc'
);

export const quantilePercentileChanged = createEvent(
    'Changed percentile slider - Quantile',
    'quantile-grade-level-charts',
    'qglc'
);

export const quantileDownloadedPDF = createEvent(
    'Downloaded chart PDF - Quantile',
    'quantile-grade-level-charts',
    'qglc'
);

/************************************************
 *  Lexile Quantile Academy
 ***********************************************/

export const clickedLQAcademyCard = createEvent(
    'L&Q Academy Card Click',
    'lexile-quantile-academy',
    'lqa'
);

export const clickedLQAcademyQuickLink = createEvent(
    'L&Q Academy Quick Link Click',
    'lexile-quantile-academy',
    'lqa'
);
