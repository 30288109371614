import React from 'react';
import styled from 'styled-components';

const Protractor = ({ className }) => {
    return (
        <svg
            className={className}
            width="100%"
            height="100%"
            viewBox="0 0 317 381"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
        >
            <rect x="0" y="339.632" width="306" height="41.368" />
            <rect x="169" y="199.632" width="135" height="9" />
            <rect x="223.5" y="0" width="26" height="64" />
            <path d="M35,339.632c8.12,-138.334 229.574,-130.42 236,-0l-41,-0c-6.912,-94.658 -152.107,-81.347 -155,-0l-40,-0Z" />
            <path d="M163,346.632l66,-225l-15,-20l-61,192l10,53Z" />
            <path d="M309,347.632l8,-55l-59,-191l-16,21l67,225Z" />
            <path d="M236,45.632c28.7,-0 52,19.94 52,44.5c0,24.56 -23.3,44.5 -52,44.5c-28.7,-0 -52,-19.94 -52,-44.5c0,-24.56 23.3,-44.5 52,-44.5Zm0,11.125c21.525,-0 39,14.955 39,33.375c0,18.42 -17.475,33.375 -39,33.375c-21.525,-0 -39,-14.955 -39,-33.375c0,-18.42 17.475,-33.375 39,-33.375Z" />
        </svg>
    );
};

export default styled(Protractor)``;
