import {
    observable,
    action,
    runInAction,
    computed,
    makeObservable
} from 'mobx';
import { i18n } from '@i18n/format';
import { negate } from '@utilities/predicates';
import {
    loadSavedResources,
    loadSavedLists,
    loadListDetails,
    saveResource,
    moveResources,
    removeSavedResources,
    createUserDefinedList,
    deleteUserDefinedList,
    modifyUserList
} from '@api/quantile';
import {
    asArray,
    prepend,
    concat,
    contains
} from '@utilities/dataTypes/arrays';
import { pluckOrFallback, propEquals } from '@utilities/dataTypes/objects';
import { dateToTimestamp } from '@utilities/dataTypes/dates';
import { loadingManager } from '@features/global/LoadingManager';

const addIdProperty = resource => ({ ...resource, id: resource.asset_id });

export class ResourceStore {
    resourcesLoaded = false;
    resources = [];
    userLists = [];
    relatedQscs = [];
    userListDetails = new Map();

    reset = () => {
        this.resourcesLoaded = false;
        this.resources = [];
        this.userLists = [];
        this.relatedQscs = [];
        this.userListDetails = new Map();
    };

    loadSavedResources = async () => {
        const { metadata, resources = [] } = await loadingManager.load(() =>
            loadSavedResources()
        );

        runInAction(() => {
            this.resources = resources.map(resource => ({
                ...resource,
                id: resource.asset_id
            }));

            this.relatedQscs = metadata.qscs;
            this.resourcesLoaded = true;
        });
    };

    removeResources = async resources => {
        const ids = asArray(resources).map(resource =>
            resource.id ? resource.id : resource
        );
        await removeSavedResources(ids);
        runInAction(() => {
            this.resources = this.resources.filter(
                resource => !contains(ids, resource.id)
            );
        });
    };

    saveResource = async resource => {
        await saveResource(resource.id);
        runInAction(() => {
            this.resources = concat(this.resources, resource);
        });
    };

    createList = async values => {
        const { id } = await createUserDefinedList(values);

        runInAction(() => {
            this.userLists.push({
                ...values,
                last_modified: this.nowTimestamp(),
                id
            });
        });

        return id;
    };

    deleteList = async id => {
        return loadingManager
            .load(() => deleteUserDefinedList(id))
            .then(() => {
                runInAction(() => {
                    this.userListDetails.delete(id);
                    this.userLists = this.userLists.filter(
                        negate(propEquals('id', id))
                    );
                });
            });
    };

    loadSavedLists = async () => {
        const lists = await loadingManager.load(() => loadSavedLists());

        runInAction(() => {
            this.userLists = lists.map(list => ({
                ...list,
                resources: list.resources.map(addIdProperty)
            }));
        });
    };

    loadListDetails = async listId => {
        const listDetails = await loadingManager.load(() =>
            loadListDetails(listId)
        );
        runInAction(() => {
            listDetails.resources = listDetails.resources.map(addIdProperty);
            this.userListDetails.set(listId, listDetails);
        });
    };

    modifyList = (listId, { name, description }) => {
        return loadingManager.load(() =>
            modifyUserList(listId, { name, description }).then(() => {
                runInAction(() => {
                    const listIdx = this.userLists.findIndex(
                        propEquals('id', listId)
                    );
                    const list = this.userLists[listIdx];
                    const updatedListDetails = {
                        ...list,
                        last_modified: this.nowTimestamp(),
                        name,
                        description
                    };

                    this.userLists[listIdx] = updatedListDetails;

                    const fullListData = this.listById(listId);
                    this.userListDetails.set(listId, {
                        ...fullListData,
                        ...updatedListDetails
                    });
                });
            })
        );
    };

    addResourcesToList = (listId, resourceIds) => {
        //Don't need to add resourceIds to user store because
        //this operation takes place on the Saved Resources tab,
        //which doesn't ever show an individual list's details
        return loadingManager
            .load(() => modifyUserList(listId, { add: resourceIds }))
            .then(() => {
                this.updateModifiedDate(listId);
            });
    };

    moveToList = (listId, destinationId, resourceIds) => {
        return loadingManager
            .load(() => moveResources(listId, destinationId, resourceIds))
            .then(() => {
                runInAction(() => {
                    const list = this.listById(listId);
                    list.resources = list.resources.filter(
                        resource => !contains(resourceIds, resource.id)
                    );
                    this.updateModifiedDate(listId);
                    this.updateModifiedDate(destinationId);
                });
            });
    };

    removeFromList = (listId, resourceIds) => {
        return loadingManager
            .load(() => modifyUserList(listId, { remove: resourceIds }))
            .then(() => {
                runInAction(() => {
                    const list = this.listById(listId);
                    list.resources = list.resources.filter(
                        resource => !contains(resourceIds, resource.id)
                    );
                    this.updateModifiedDate(listId);
                });
            });
    };

    constructor() {
        makeObservable(this, {
            resourcesLoaded: observable,
            resources: observable,
            userLists: observable,
            relatedQscs: observable,
            userListDetails: observable,
            reset: action,
            loadSavedResources: action,
            removeResources: action,
            saveResource: action,
            createList: action,
            deleteList: action,
            loadSavedLists: action,
            loadListDetails: action,
            modifyList: action,
            addResourcesToList: action,
            moveToList: action,
            removeFromList: action,
            savedAssets: computed,
            relatedQscOptions: computed,
            userListOptions: computed,
            updateModifiedDate: action
        });
    }

    get savedAssets() {
        return new Set(this.resources.map(pluckOrFallback('id')));
    }

    get relatedQscOptions() {
        return prepend({
            value: 0,
            label: i18n('quantileResourceCenter.allQSCs')
        })(
            this.relatedQscs.map(({ id, description }) => ({
                value: id,
                label: `QSC${id}: ${description}`
            }))
        );
    }

    get userListOptions() {
        return this.userLists.map(({ name, id }) => ({
            value: id,
            label: name
        }));
    }

    updateModifiedDate = listId => {
        const list = this.listById(listId);
        if (list) {
            list.last_modified = this.nowTimestamp();
        }

        const userList = this.userLists.find(propEquals('id', listId));
        if (userList) {
            userList.last_modified = this.nowTimestamp();
        }
    };

    nowTimestamp = () => dateToTimestamp(new Date()) / 1000;
    isResourceSaved = resource => this.savedAssets.has(resource.id);
    listById = listId => this.userListDetails.get(listId);
}

export const resourceCenter = new ResourceStore();
