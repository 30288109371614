import styled from 'styled-components';
import { pxToRem } from '@utilities/stylingUtils';

export const PrimaryHeader = styled.h1`
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(24)};

    margin: 0;
    padding: 0;
`;

export const LexilePrimaryHeader = styled(PrimaryHeader)`
    color: var(--theme-green1);
`;

export const QuantilePrimaryHeader = styled(PrimaryHeader)`
    color: var(--theme-blue3);
`;

export const SecondaryHeader = styled.h2`
    font-size: 1.125rem;
    font-weight: normal;
    color: var(--theme-less-dark-gray);
    margin: 0;
    padding: 0;
`;
