import React, { useState } from 'react';
import styled from 'styled-components';
import { i18n } from '@i18n/format';
import { whenProvided } from '@utilities/stylingUtils';
import { isZero } from '@utilities/predicates';
import { queryParams } from '@utilities/navigation';
import Link from '@ui/actions/Link';
import LinkButton from '@ui/actions/LinkButton';
import ChevronDown from '@ui/icons/ChevronDown';
import FlexParent from '@ui/layouts/FlexParent';
import Tooltip from '@ui/Tooltip';
import { qscDetails } from '@features/mathSkillsDB/mathSkillsRoutes';
import QSCCard from './QSCCard';

const SkillList = styled.div`
    font-size: 0.875rem;
    :not(:last-child) {
        margin-bottom: 20px;
    }

    h3 {
        font-weight: normal;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        margin: 4px 0;
    }

    ${Link} {
        text-decoration: none;
        margin-right: 4px;
    }

    ${Link}:hover {
        text-decoration: underline;
    }

    .quantile-measure {
        margin-left: 4px;
    }

    ${FlexParent} {
        justify-content: flex-start;
        align-items: center;

        ${Tooltip} {
            margin-left: 4px;
        }
    }
`;

const Chevron = styled(ChevronDown)`
    margin: 0 8px;
    transition: transform 150ms ease-out;

    ${whenProvided('open')`
        transform: rotate(180deg);
    `}
`;

const QSCSkillList = ({ skills, title, tooltip }) => {
    if (!skills || isZero(skills.length)) {
        return null;
    }

    return (
        <SkillList>
            <FlexParent>
                <h3>{title}</h3>
                {tooltip && <Tooltip>{tooltip}</Tooltip>}
            </FlexParent>
            <ul>
                {skills.map(qsc => (
                    <li key={qsc.id}>
                        <Link to={qscDetails(qsc.id, queryParams().state)}>
                            {qsc.qsc_id_display}:
                        </Link>
                        <span>{qsc.description}</span>
                        <span className="quantile-measure">
                            ({qsc.qmeasure_display})
                        </span>
                    </li>
                ))}
            </ul>
        </SkillList>
    );
};

const KnowledgeCluster = ({
    prerequisites = [],
    supporting = [],
    impending = []
}) => {
    const [isOpen, setOpen] = useState(true);
    const totalQSCs =
        prerequisites.length + supporting.length + impending.length;

    return (
        <QSCCard>
            <h2>
                <span>{i18n('mathSkillsDB.knowledgeCluster')}</span>
                <LinkButton onClick={() => setOpen(!isOpen)}>
                    {i18n(
                        isOpen
                            ? 'mathSkillsDB.hideQSCs'
                            : 'mathSkillsDB.showQSCs',
                        totalQSCs
                    )}
                    <Chevron open={isOpen} />
                </LinkButton>
            </h2>
            {isOpen && (
                <React.Fragment>
                    <QSCSkillList
                        title={i18n('mathSkillsDB.prerequisiteSkills')}
                        skills={prerequisites}
                        tooltip={i18n('quantile.prereqQSCs.tooltip')}
                    />
                    <QSCSkillList
                        title={i18n('mathSkillsDB.supportingSkills')}
                        skills={supporting}
                        tooltip={i18n('quantile.supportingQSCs.tooltip')}
                    />
                    <QSCSkillList
                        title={i18n('mathSkillsDB.impendingSkills')}
                        skills={impending}
                        tooltip={i18n('quantile.impendingQSCs.tooltip')}
                    />
                </React.Fragment>
            )}
        </QSCCard>
    );
};

export default KnowledgeCluster;
