export const drawerConstants = {
    closedWidth: '66px',
    openWidth: '190px',
    menuIconSize: '40px'
};

export const appBarHeight = '66px';

export const productHeaderHeight = '90px';

export const tabListHeight = '46px';
