import { isNegative } from '@utilities/predicates';
import { isArray, length } from './queries_arrays';
import { concat } from './merges_arrays';
import { remove } from './deletions_arrays';

export const asArray = value => (isArray(value) ? value : [value]);

export const fmap = f => (array = []) => array.map(f);

export const flatten = (arrayOfArrays = []) =>
    arrayOfArrays.reduce((acc, val) => {
        if (!isArray(val) || length(val) > 0) {
            return concat(acc, val);
        }
        return acc;
    }, []);

/**
 * If the value exists in the array, remove it.
 * If the value does not exist in the array, add it.
 */
export const toggle = (array, value) => {
    const index = array.indexOf(value);

    return isNegative(index)
        ? concat(array, value)
        : remove(array, value, index);
};
