import { equals } from '../predicates';
import { compose } from './functions';

export const type = value => typeof value;

export const isType = expectedType => compose(equals(expectedType), type);

export const isFunction = isType('function');
export const isString = isType('string');
export const isNumber = isType('number');
export const isBool = isType('boolean');
export const isObject = isType('object');
