import React from 'react';
import styled, { css } from 'styled-components';
import Link from '@ui/actions/Link';

const layout = css`
    ${Link} {
        margin-left: 4px;
        opacity: 0;
        transition: opacity ease 0.2s;
    }

    //prettier-ignore
    ${Link}:focus:not(:active) {
        opacity: 1;
    }
`;

const HiddenKeyboardLink = ({ className, to, children }) => (
    <div className={className}>
        <Link to={to}>{children}</Link>
    </div>
);

export default styled(HiddenKeyboardLink)`
    ${layout}
`;
