import React from 'react';

const GrowthPlannerIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            aria-hidden="true"
        >
            <path d="M1.17 0a1.16 1.16 0 0 1 1.16 1.17v12.66A1.16 1.16 0 0 1 1.17 15 1.17 1.17 0 0 1 0 13.83V1.17A1.17 1.17 0 0 1 1.17 0z" />
            <rect y="12.67" width="15" height="2.33" rx="1.17" />
            <path d="M6 11.33a8.65 8.65 0 0 1 8.67-8.66V.33a11 11 0 0 0-11 11H6z" />
        </svg>
    );
};

export default GrowthPlannerIcon;
