import React from 'react';
import styled from 'styled-components';
import { MakeshiftButton } from 'donut-ui';
import { i18n } from '@i18n/format';
import { whenProvided } from '@utilities/stylingUtils';
import LexileMeasure from '@ui/data/LexileMeasure';
import ChevronUp from '@ui/icons/ChevronUp';

export const Table = styled.table`
    border-collapse: collapse;
    text-align: left;
    font-weight: normal;
    width: 100%;

    tr {
        border-bottom: 1px solid var(--theme-gray1);
    }

    td {
        padding: 8px 12px;
    }

    ${LexileMeasure} {
        color: var(--theme-green1);
    }
`;

export const TableHeader = styled.th`
    padding: 8px 12px;
    text-transform: uppercase;
    font-weight: normal;
    color: var(--theme-gray3);
    background-color: var(--theme-gray4);
    border: none;
`;

const ClickableHeader = styled(TableHeader)`
    > div {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
    }

    span {
        margin-right: 8px;
    }

    svg {
        transition: transform 0.2s ease;
        ${whenProvided('sortAsc')`
            transform: rotate(-180deg);
        `}
        flex: 0 1 17px;
    }
`;

//SortableHeader expects "sorting" to be a structure similar to (if not directly from) useSorting
export const SortableHeader = ({
    relatedProp,
    relatedPath,
    sorting: { sortingBy, updateSortingBy },
    sortFn,
    children
}) => {
    const onClick = () =>
        updateSortingBy({
            prop: relatedProp,
            path: relatedPath,
            asc: true,
            sortFn
        });

    const sortingAsc =
        sortingBy.path === relatedPath &&
        sortingBy.prop === relatedProp &&
        sortingBy.asc;

    return (
        <ClickableHeader sortAsc={sortingAsc}>
            <div onClick={onClick}>
                <span>{children}</span>
                <MakeshiftButton>
                    {getButtonProps => (
                        <ChevronUp
                            height={8}
                            width={13}
                            {...getButtonProps({
                                'aria-label': i18n(
                                    sortingAsc
                                        ? 'common.sortDesc'
                                        : 'common.sortAsc'
                                ),
                                onClick
                            })}
                        />
                    )}
                </MakeshiftButton>
            </div>
        </ClickableHeader>
    );
};
