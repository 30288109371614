import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from 'donut-ui';
import { inject, observer } from 'mobx-react';
import { timeDifference } from '@utilities/dataTypes/dates';

const OverlayContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;

    background-color: var(--theme-gray4);
    opacity: 0.6;
    z-index: 99999;
    height: 100vh;
    width: 100vw;

    ${CircularProgress} {
        height: 50px;
        width: 50px;

        color: var(--theme-blue4);
        margin: auto;
        display: block;
        margin-top: calc(50vh - 50px);
    }
`;

class Loader extends React.Component {
    state = {
        startTime: null
    };
    componentDidMount() {
        this.setState({
            startTime: new Date()
        });
    }
    showLoader = () => {
        const { loadingManager, individualState } = this.props;
        const { startTime } = this.state;

        return (
            (individualState &&
                startTime &&
                timeDifference(startTime, new Date()) > 200) ||
            (loadingManager.loading && loadingManager.showLoader)
        );
    };
    render() {
        return this.showLoader() ? (
            <OverlayContainer data-test-id="loading-overlay">
                <CircularProgress />
            </OverlayContainer>
        ) : null;
    }
}

export default inject('loadingManager')(observer(Loader));
