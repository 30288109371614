import styled from 'styled-components';
import { appBarHeight } from '@ui/navigation/navConstants';

const MainAppBar = styled.header`
    position: relative;

    z-index: 100;
    height: ${appBarHeight};
    background-color: var(--theme-blue4);
    color: var(--theme-white);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    padding: 0 16px 0 32px;
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: center;

    > a {
        text-transform: uppercase;
        font-weight: bold;
        color: inherit;
        text-decoration: none;
        transition: color 0.2s ease;
    }
    > a:hover {
        color: var(--theme-gray1);
    }
`;

export default MainAppBar;
