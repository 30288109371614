import {
    isAfter,
    isBefore,
    lightFormat,
    differenceInCalendarDays,
    differenceInMilliseconds,
    getTime
} from 'date-fns';

export const isFuture = date => isAfter(new Date(date), new Date());

export const isPast = date => isBefore(new Date(date), new Date());

export const daysUntil = date =>
    Math.abs(differenceInCalendarDays(new Date(), new Date(date)));

export const formatApiDate = date => date && lightFormat(date, 'MM/dd/yyyy');

export const dateToTimestamp = getTime;

export const currentYear = new Date().getFullYear();

export const timeDifference = (date, otherDate) =>
    Math.abs(differenceInMilliseconds(date, otherDate));

// Formats seconds into time 60 => 1:00
// minimum display shows X:XX, then adds more if needed.
// Wed Dec 31 1969 19:00:01 GMT-0500 (Eastern Standard Time)
export const secondsToTime = seconds => {
    let startSlice = 15;

    if (seconds >= 600) startSlice = 14;
    if (seconds >= 3600) startSlice = 12;
    if (seconds >= 36000) startSlice = 11;

    const date = new Date(0);
    date.setSeconds(seconds); // specify duration in seconds

    return date.toISOString().slice(startSlice, 19);
};

export const parseUTCISO = dateString => new Date(Date.parse(dateString));
