import { makeEndpoint, tiered } from './httpRequest';

const NAME = process.env.REACT_APP_ATAB_NAME;
const ENDPOINT = process.env.REACT_APP_ATAB_URL;

const analyzerApi = makeEndpoint(NAME, ENDPOINT);
export const textAnalyzerRoot = analyzerApi.configure();

const get = tiered(analyzerApi.get);

export const analyzeText = (text, wordCount, language) =>
    tiered(analyzerApi.post)('/analysis', {
        text,
        word_count: wordCount,
        language
    });

export const getAnalysisHistory = () => get('/analysis');

export const getAnalysisHistoryById = id => get(`/analysis/${id}`);

export const getVocabById = id => get(`/analysis/${id}/vocab`);

export const getWordDefinitions = (words = []) =>
    analyzerApi.post('/basic/definitions', {
        words
    });

export const getMeasurementCount = () => get('/analysis/count');
