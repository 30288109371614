import { useRef } from 'react';
import { useUniqueId } from 'donut-ui';

const useTooltip = () => {
    const tooltipId = useUniqueId('tooltip');
    const tooltipRef = useRef(null);

    const showTooltip = () => {
        tooltipRef?.current && tooltipRef.current.setHidden(false);
    };

    const hideTooltip = () => {
        tooltipRef?.current && tooltipRef.current.setHidden(true);
    };

    return {
        tooltipId: tooltipId,
        tooltipRef: tooltipRef,
        showTooltip: showTooltip,
        hideTooltip: hideTooltip
    };
};

export default useTooltip;
