import React from 'react';

const HamburgerIcon = ({ title, ...props }) => (
    <svg viewBox="0 1 15 10" fill="none" {...props}>
        <title>{title}</title>
        <path d="M0 12h18v-2H0v2zm0-5h18V5H0v2zm0-7v2h18V0H0z" fill="#F2F2F2" />
    </svg>
);

export default HamburgerIcon;
