import React from 'react';
import styled from 'styled-components';

const Calculator = ({ className }) => {
    return (
        <svg
            className={className}
            width="100%"
            height="100%"
            viewBox="0 0 267 254"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
        >
            <path
                d="M267,19.516c0,-10.771 -8.745,-19.516 -19.516,-19.516l-227.968,0c-10.771,0 -19.516,8.745 -19.516,19.516l0,214.079c0,10.772 8.745,19.517 19.516,19.517l227.968,-0c10.771,-0 19.516,-8.745 19.516,-19.517l0,-214.079Zm-27.5,74.905c0,-5.519 -4.481,-10 -10,-10l-43.641,0c-5.519,0 -10,4.481 -10,10l-0,132.027c-0,5.519 4.481,10 10,10l43.641,-0c5.519,-0 10,-4.481 10,-10l0,-132.027Zm-148.359,82.779c0,-5.519 -4.481,-10 -10,-10l-43.641,-0c-5.519,-0 -10,4.481 -10,10l0,49.248c0,5.519 4.481,10 10,10l43.641,-0c5.519,-0 10,-4.481 10,-10l0,-49.248Zm74.18,-0c-0,-5.519 -4.481,-10 -10,-10l-43.642,-0c-5.519,-0 -10,4.481 -10,10l0,49.248c0,5.519 4.481,10 10,10l43.642,-0c5.519,-0 10,-4.481 10,-10l-0,-49.248Zm-74.18,-82.779c0,-5.519 -4.481,-10 -10,-10l-43.641,0c-5.519,0 -10,4.481 -10,10l0,49.248c0,5.519 4.481,10 10,10l43.641,-0c5.519,-0 10,-4.481 10,-10l0,-49.248Zm74.18,0c-0,-5.519 -4.481,-10 -10,-10l-43.642,0c-5.519,0 -10,4.481 -10,10l0,49.248c0,5.519 4.481,10 10,10l43.642,-0c5.519,-0 10,-4.481 10,-10l-0,-49.248Zm74.179,-66.89l-212,0l0,36.196l212,-0l0,-36.196Z"
                fill="#fff"
            />
            <path
                d="M56.554,135.138l-0,-13.086l-12.989,0l0,-5.468l12.989,-0l-0,-12.989l5.533,0l0,12.989l12.989,-0l-0,5.468l-12.989,0l0,13.086l-5.533,0Z"
                fill="#fff"
                fillRule="nonzero"
            />
            <path
                d="M45.164,218.147l11.052,-15.722l-10.226,-14.527l6.409,0l4.643,7.092c0.873,1.349 1.576,2.479 2.108,3.39c0.835,-1.253 1.604,-2.364 2.307,-3.333l5.098,-7.149l6.124,0l-10.453,14.242l11.251,16.007l-6.295,0l-6.209,-9.399l-1.652,-2.535l-7.947,11.934l-6.21,0Z"
                fill="#fff"
                fillRule="nonzero"
            />
            <path
                d="M223.435,156.251l-31.511,0l0,-5.468l31.511,-0l-0,5.468Zm-0,14.486l-31.511,0l0,-5.469l31.511,0l-0,5.469Z"
                fill="#fff"
                fillRule="nonzero"
            />
            <rect
                x="117.745"
                y="116.311"
                width="31.51"
                height="5.469"
                fill="#fff"
                fillRule="nonzero"
            />
            <circle cx="133.5" cy="189.531" r="4" fill="#fff" />
            <rect
                x="117.745"
                y="199.158"
                width="31.51"
                height="5.469"
                fill="#fff"
                fillRule="nonzero"
            />
            <circle cx="133.5" cy="214.531" r="4" fill="#fff" />
        </svg>
    );
};

export default styled(Calculator)``;
