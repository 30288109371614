import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import mathSkillsDBLogo from '~/images/Quantile/QuantileMathSkillsDatabase_Full.png';
import { i18n } from '@i18n/format';
import { loadQSC } from '@api/quantile';
import baseRoutes from '@constants/routes';
import ToResourceCenter from '@features/quantileResourceCenter/ToResourceCenter';
import { pushTo } from '@utilities/navigation';
import { msdClickedLearnMore } from '@utilities/analytics/analyticsEvents';
import ProductHeader from '@ui/headers/ProductHeader';
import ToolContent from '@ui/layouts/ToolContent';
import Link from '@ui/actions/Link';
import { PrimaryHeader } from '@ui/headers/PrimaryHeaders';
import SEO from '@features/global/SEO';
import QSCCard from './QSCCard';
import KnowledgeCluster from './KnowledgeCluster';
import ResourceList from './ResourceList';
import StateStandards from './StateStandards';
import { msdQuickStartGuide } from '@constants/externalLinks';

const QSCPrimaryHeader = styled(PrimaryHeader)`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .measure-display {
        font-size: 1.125rem;
    }

    .separator {
        padding: 0 4px;
    }
`;

const PageLayout = styled.div`
    ${QSCPrimaryHeader} {
        margin-bottom: 12px;
    }

    ${QSCCard} {
        margin-bottom: 20px;
    }

    .qsc-description {
        font-size: 1.125rem;
        color: var(--theme-gray3);
        margin-bottom: 40px;
        padding-bottom: 20px;
    }
`;

const LearnMore = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--theme-gray1);
`;

const QSCHeader = ({ qscId, quantileMeasure, strand }) => {
    return (
        <QSCPrimaryHeader>
            <span>{qscId}</span>
            <div className="measure-display">
                <Link
                    to={`${baseRoutes.MATH_SKILLS_DATABASE}?quantile=${quantileMeasure}`}
                >
                    {quantileMeasure}
                </Link>
                <span className="separator"> | </span>
                <span>{strand}</span>
            </div>
        </QSCPrimaryHeader>
    );
};

const QSCDetails = ({ qscId }) => {
    const [qscDetails, setQSC] = useState({});
    useEffect(() => {
        loadQSC(qscId).then(data => {
            setQSC({
                ...data,
                resources: [
                    ...data.web_resources,
                    ...data.downloadable_resources,
                    ...data.print_resources
                ]
            });
        });
    }, [qscId]);

    return (
        <PageLayout>
            <SEO>
                <title>QSC{qscId}</title>
            </SEO>
            <ProductHeader
                productName={i18n('mathSkillsDB.productName')}
                productBranding={mathSkillsDBLogo}
                onClick={() => pushTo(baseRoutes.MATH_SKILLS_DATABASE)}
                rightDecoration={<ToResourceCenter currentTool="QSC Details" />}
                addHeader
            />
            <ToolContent>
                <QSCHeader
                    qscId={qscDetails.qsc_id_display}
                    quantileMeasure={qscDetails.qmeasure_display}
                    strand={qscDetails.strand}
                />
                <div className="qsc-description">{qscDetails.description}</div>
                <LearnMore>
                    <Link
                        href={msdQuickStartGuide}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() => {
                            msdClickedLearnMore();
                        }}
                    >
                        {i18n('quantile.learnMoreSkills')}
                    </Link>
                </LearnMore>
                <KnowledgeCluster
                    prerequisites={qscDetails.prerequisites}
                    supporting={qscDetails.supporting}
                    impending={qscDetails.impending}
                />
                <ResourceList resources={qscDetails.resources} />
                <StateStandards id={qscId} />
            </ToolContent>
        </PageLayout>
    );
};

export default QSCDetails;
