import React from 'react';
import { Redirect } from 'react-router-dom';
import baseRoutes from '@constants/routes';
import { useUser } from '@utilities/hooks/useUser';

const RestrictedTo = ({ signedIn, notSignedIn }) => {
    const { loggedIn } = useUser();

    if (signedIn && !loggedIn) {
        return <Redirect to={baseRoutes.HOME_PAGE} />;
    }

    if (notSignedIn && loggedIn) {
        return <Redirect to={baseRoutes.HOME_PAGE} />;
    }

    return null;
};

export default RestrictedTo;
