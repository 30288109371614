import React from 'react';

const MathAtHomeIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.01 15"
            aria-hidden="true"
        >
            <path fill="none" d="M0 0h16.01v15H0z" />
            <path d="M12.81 3.28V0h-2.62v1.5L8 0 0 5.49l1 1.3 7.06-5 7 5L16 5.45z" />
            <path d="M14.05 7.54L8.52 3.73a1 1 0 0 0-1.1 0L2 7.56a1 1 0 0 0-.41.79V14a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V8.33a1 1 0 0 0-.54-.79z" />
        </svg>
    );
};

export default MathAtHomeIcon;
