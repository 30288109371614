import styled from 'styled-components';
import LinkButton from '@ui/actions/LinkButton';

const QSCCard = styled.div`
    padding: 8px 12px 12px 12px;
    border: 1px solid var(--theme-gray1);
    box-shadow: var(--elevated);

    h2 {
        font-size: 1.5rem;
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        ${LinkButton} {
            font-size: 1.125rem;
            text-decoration: none;
        }
    }
`;

export default QSCCard;
