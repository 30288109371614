import {
    lexileMeasureToInt,
    quantileMeasureToInt
} from '@utilities/dataTypes/custom';
import {
    firstWord,
    removeNonNumericCharacters
} from '@utilities/dataTypes/strings';
import { isString } from '@utilities/dataTypes/typeChecks';

export const lexicalSort = (a, b, asc) => {
    asc = asc === false ? -1 : 1;

    if ((!a && b) || a < b) {
        return -1 * asc;
    }
    if ((!b && a) || a > b) {
        return 1 * asc;
    }
    return 0;
};

export const lexicalSortIgnoreCase = (a, b, asc) => {
    if (isString(a) && isString(b)) {
        asc = asc === false ? -1 : 1;

        return asc * a.toLowerCase().localeCompare(b.toLowerCase());
    }
    return lexicalSort(a, b, asc);
};

// Naturally sort two alphanumeric strings
// Multi-digit numbers in strings are treated as one character
export const naturalSort = (a, b, asc) => {
    asc = asc === false ? -1 : 1;

    return (
        // Pass numeric option to localeCompare to use numeric collation
        // E.g., '1' < '2' < '10'
        asc * String(a).localeCompare(String(b), undefined, { numeric: true })
    );
};

export const sortLexileRange = (a, b, asc) => {
    if (isString(a) && isString(b)) {
        a = lexileMeasureToInt(firstWord(a));
        b = lexileMeasureToInt(firstWord(b));
    }

    if (Array.isArray(a) && a[0] && Array.isArray(b) && b[0]) {
        a = a[0];
        b = b[0];
    }

    return lexicalSort(a, b, asc);
};

export const sortStringAsInt = (a, b, asc) => {
    if (isString(a) && isString(b)) {
        a = parseInt(removeNonNumericCharacters(a));
        b = parseInt(removeNonNumericCharacters(b));
    }

    return lexicalSort(a, b, asc);
};

export const sortQuantileMeasure = (a, b, asc) => {
    if (isString(a) && isString(b)) {
        a = quantileMeasureToInt(a);
        b = quantileMeasureToInt(b);
    }

    return lexicalSort(a, b, asc);
};

export const sortByFirstArrayItem = (a, b, asc) => lexicalSort(a[0], b[0], asc);
