import React, { useContext } from 'react';

import { evolve } from 'ramda';

import { UserContext } from '@utilities/contexts';

import { lowercase } from '@utilities/dataTypes/strings';

export const lowercaseEmail = evolve({ email: lowercase });

export const useUser = () => useContext(UserContext);

export const withUser = Component => props => {
    const user = useUser();

    return <Component {...props} user={user} />;
};
