import React from 'react';
import styled from 'styled-components';
import { i18n } from '@i18n/format';

const Layout = styled.div`
    text-align: center;
    margin: 80px;
    color: var(--theme-less-dark-gray);

    h1 {
        margin-bottom: 24px;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid var(--theme-gray1);
    }
    p {
        font-size: 1.125rem;
    }
`;

const RegistrationEmail = () => {
    return (
        <Layout>
            <h1>{i18n('account.emailSent')}</h1>
            <p>{i18n('account.emailConfirmationDesc')}</p>
        </Layout>
    );
};

export default RegistrationEmail;
