import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
    .st0 {
        fill: currentColor;
    }
    .st1,
    .st2 {
        fill: none;
        stroke: currentColor;
    }
    .st1 {
        stroke-width: 0.8;
    }
    .st2 {
        stroke-linecap: round;
        stroke-linejoin: round;
    }
`;

const GradeLevelChartsIcon = props => {
    return (
        <Svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="9 8 20 20"
            xmlSpace="preserve"
            aria-hidden="true"
            {...props}
        >
            <path
                className="st0"
                d="M11.74 18.77c0-.06 0-.12.01-.17 0-.03.01-.05.01-.07-1.15 1.17-1.92 2.67-2.05 4.34-.02.3.24.55.56.55h1.46c-.01-.33-.02-.66-.02-.98 0-1.23.02-2.45.03-3.67zM13.27 18.89c1.44.51 2.88 1.03 4.31 1.54.09.03.13.07.12.17v-.13c.4-.14.79-.29 1.19-.43l4.44-1.59c.1-.04.17-.05.22-.04-.96-.93-2.17-1.65-3.54-2.03 1.2-.98 1.77-2.08 1.7-3.48-.1-1.93-1.65-3.39-3.77-3.49-2.41-.12-4.23 1.45-4.23 3.62 0 1.33.5 2.37 1.71 3.35-1.38.37-2.58 1.09-3.54 2.02.03 0 .06.01.11.03.42.16.85.31 1.28.46zM23.66 18.53c.01.04.01.1.02.17.02.89.03 1.79.03 2.68 0 .68-.01 1.35-.02 2.03h1.45c.33 0 .59-.25.56-.55-.13-1.66-.89-3.16-2.04-4.33z"
            />
            <path
                className="st1"
                d="M17.7 23.38c-.01 0-.02 1.79-.02 2.68 0 .06 0 .12-.01.19-.02.16-.05.19-.21.14-.49-.16-.98-.31-1.46-.49-1.37-.49-2.73-.99-4.1-1.48-.11-.04-.16-.1-.16-.21-.01-.59-.04-1.19-.04-1.78 0-1.22.02-2.44.03-3.66 0-.06 0-.12.01-.17.02-.19.05-.22.24-.15l1.29.45c1.44.51 2.88 1.03 4.31 1.54.09.03.13.07.12.17v2.77zm0 0s-.01 1.03 0 0z"
            />
            <path
                className="st2"
                d="M24.21 12.91h5M24.21 17.91h5M24.21 22.91h5"
            />
            <path
                className="st1"
                d="M17.6 20.61c0-.1.03-.14.12-.17 1.44-.51 2.88-1.03 4.31-1.54l1.29-.45c.18-.06.22-.04.24.15.01.06.01.12.01.17.01 1.22.03 2.44.03 3.66 0 .59-.03 1.19-.04 1.78 0 .11-.05.17-.16.21-1.37.49-2.73.99-4.1 1.48-.48.17-.97.33-1.46.49-.16.05-.19.03-.21-.14-.01-.06-.01-.12-.01-.19 0-.89-.02-2.68-.02-2.68.01 0 .01-1.84 0-2.77zm.01 2.77c.01 1.03 0 0 0 0z"
            />
        </Svg>
    );
};

export default GradeLevelChartsIcon;
