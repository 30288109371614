import { css } from 'styled-components';

export const baseTheme = css`
    --theme-warm-black: #666666;
    --theme-warm-black2: #999999;
    --theme-warm-black3: #cccccc;
    --theme-black: #000000;
    --theme-blue1: #004186;
    --theme-blue2: #1589ee;
    --theme-blue3: #0071bc;
    --theme-blue4: #005dc0;
    --theme-blue5: #becede;
    --theme-blue6: #99caff;
    --theme-blue7: #f5faff;
    --theme-blue8: #004a99;
    --theme-blue9: #f6fcfe;
    --theme-blue-quantile: #20bddc;
    --theme-blue-growth: #1ba1bb;
    --theme-blue-transparent: rgba(9, 115, 226, 0.1);
    --theme-light-blue-quantile: #4fbddc;
    --theme-cool-gray: #98a3a8;
    --theme-dark-gray: #2c2c2c;
    --theme-less-dark-gray: #4f4f4f;
    --theme-gray1: #dddbda;
    --theme-gray2: #bdbdbd;
    --theme-gray3: #707070;
    --theme-gray4: #f5f5f5;
    --theme-gray5: #e6e6e6;
    --theme-gray6: #e5e5e5;
    --theme-gray7: #fafafa;
    --theme-gray8: #f4f8fa;
    --theme-gray9: #706e6b;
    --theme-gray10: #fafaf9;
    --theme-green1: #009245;
    --theme-green2: #04844b;
    --theme-green3: #00d537;
    --theme-green4: #8fe497;
    --theme-green5: #ddf7e0;
    --theme-green6: #f6fef7;
    --theme-green-lexile: #1fc931;
    --theme-green-growth: #1bac2a;
    --theme-green-transparent: rgba(0, 213, 55, 0.1);
    --theme-green-transparent2: rgba(31, 201, 49, 0.3);
    --theme-orange2: #db3a00;
    --theme-orange3: #e4841b;
    --theme-orange-transparent: rgba(255, 124, 0, 0.05);
    --theme-purple1: #c02fb4;
    --theme-purple2: #751f71;
    --theme-red: #c23934;
    --theme-violet1: #1e2433;
    --theme-violet2: #293043;
    --theme-web-bg1: #e6e1de;
    --theme-web-bg2: #edf7f6;
    --theme-white: #ffffff;
    --theme-white2: #f6f9fc;
    --theme-white3: #e6e1de;
    --theme-white4: #fefaf6;
    --theme-yellow1: #fdc500;
    --theme-yellow-transparent: rgba(253, 197, 0, 0.1);
    --theme-muted-purple: #dcbfef;
    --theme-muted-green: #d4e5b5;
    --theme-muted-blue: #b6cee6;
    --theme-muted-red: #f1c8c0;
    --theme-muted-yellow: #f9ebaa;
    --theme-muted-lexile: #ebf8f6;

    --elevated: 0px 2px 2px rgba(0, 0, 0, 0.1);
    --elevated-dark: 0px 2px 3px rgba(112, 110, 107, 0.5);
    --outline: 0px 0px 3px var(--theme-blue2);
`;

export const lexileTheme = css`
    --brand-rgb: 31, 201, 49;
    --theme-brand: #1fc931;
    --theme-primary: #04844b;
    --theme-secondary: #009245;
    --theme-muted-brand: #ebf8f6;
`;

export const quantileTheme = css`
    --brand-rgb: 32, 189, 220;
    --theme-brand: #20bddc;
    --theme-primary: #0071bc;
    --theme-secondary: #004186;
    --theme-muted-brand: #eeffff;
`;
