import { i18n } from '@i18n/format';
import { equals, negate, toBoolAcceptZero } from '@utilities/predicates';
import { asValidator, compose } from '@utilities/dataTypes/functions';
import {
    isEmail,
    isPassword,
    length,
    lessThan,
    isSpalphabetic
} from '@utilities/dataTypes/strings';
import { compareProps } from '@utilities/dataTypes/objects';
import { isFuture } from '@utilities/dataTypes/dates';
import {
    textBounds,
    studentBounds,
    spanishBounds
} from '@utilities/dataTypes/LexileRange';
import {
    isValidLexileMeasure,
    isValidSpanishLexile,
    isValidLexileIncrement,
    isValidQuantileMeasure,
    isWithinStudentQuantileBounds
} from '@utilities/dataTypes/custom';
import { between, checkAll } from '@utilities/predicates';

export const makeOptional = validator => value => {
    if (value !== 0 && !value) {
        return;
    }

    return validator(value);
};

export const required = asValidator(toBoolAcceptZero);

export const nameLengthValidation = asValidator(lessThan(16))(
    i18n('validation.nicknameCharacterLimit')
);

export const spalphabeticValidation = asValidator(isSpalphabetic)(
    i18n('validation.nicknameAlphabetic')
);

export const validEmail = asValidator(isEmail)(i18n('validation.emailError'));

const maxEmailLength = asValidator(compose(between(5, 255), length))(
    i18n('validation.emailLength')
);

export const validateEmail = checkAll(
    required(i18n('validation.emailRequired')),
    maxEmailLength,
    validEmail
);

//This validation should only be used when the user is entering a new password
export const validNewPassword = asValidator(isPassword)(
    i18n('validation.pwdRequirements')
);

export const validatePassword = checkAll(
    required(i18n('validation.pwdRequired')),
    validNewPassword
);

export const notInFuture = asValidator(negate(isFuture))(
    i18n('validation.dateNotInFuture')
);

export const isLexileMeasure = asValidator(isValidLexileMeasure);

export const isSpanishLexile = asValidator(isValidSpanishLexile);

export const validLexileIncrement = asValidator(isValidLexileIncrement);

export const withinTextLexileBounds = asValidator(textBounds.containsMeasure);

export const withinStudentLexileBounds = asValidator(
    studentBounds.containsMeasure
);

export const withinSpanishLexileBounds = asValidator(
    spanishBounds.containsMeasure
);

export const isQuantileMeasure = asValidator(isValidQuantileMeasure);

export const withinStudentQuantileBounds = asValidator(
    isWithinStudentQuantileBounds
);

// TODO: functions from validators.js are supposed to be validators,
// these are just predicates.
export const passwordsMatch = compareProps(
    equals,
    'password',
    'confirmPassword'
);

export const emailsMatch = compareProps(equals, 'email', 'confirmEmail');

export const isUnitedStates = country =>
    country === 'US' ||
    country === 'United States' ||
    country?.value === 'US' ||
    country?.value === 'United States';
