import React from 'react';
import styled, { css } from 'styled-components';
import { i18n } from '@i18n/format';
import GraduationCapSimple from '@ui/icons/GraduationCapSimple';

const layout = css`
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;

    > :first-child {
        width: 150px;
        text-align: right;
        margin-right: 8px;
        text-transform: uppercase;
    }

    > :last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const CareerCircle = styled.div`
    height: 60px;
    width: 60px;
    background-color: #018745;
    border: solid 8px #00ce01;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

    span {
        position: absolute;
        bottom: -35px;
        font-weight: bold;
        font-size: 1.25rem;
    }

    ${GraduationCapSimple} {
        height: 20px;
    }
`;

const CareerReadiness = ({ className, measure }) => (
    <div className={className}>
        <span>{i18n('home.collegeAndCareer')}</span>
        <div>
            <CareerCircle>
                <GraduationCapSimple />
                <span>{measure}</span>
            </CareerCircle>
        </div>
    </div>
);

CareerReadiness.CareerCircle = CareerCircle;

export default styled(CareerReadiness)`
    ${layout}
`;
