import React from 'react';

const StarIcon = ({
    fill = 'none',
    height = '12',
    width = '12',
    className = ''
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill={fill}
        className={className}
        aria-hidden="true"
    >
        <path
            d="M6 8.8l3.71 2.7-1.42-4.36L12 4.5H7.45L6 0 4.55 4.5H0l3.71 2.64-1.42 4.36L6 8.8z"
            fill="currentColor"
        />
    </svg>
);

export default StarIcon;
