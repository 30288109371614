import React from 'react';
import styled from 'styled-components';
import { Field, FastField, ErrorMessage } from 'formik';
import TextInput from '@ui/inputs/TextInput';
import ValidationWarning from '@ui/messages/ValidationWarning';

//So this optimized prop... looks sweet, but don't use it unless your form is getting super slow
const TextInputField = ({
    className,
    validate,
    name,
    value,
    onBlur,
    optimized,
    ...props
}) => {
    const FieldComponent = optimized ? FastField : Field;

    return (
        <FieldComponent name={name} validate={validate}>
            {({ field, form }) => (
                <div className={className}>
                    <TextInput
                        {...field}
                        {...props}
                        onBlur={event => {
                            field.onBlur(event);
                            onBlur &&
                                onBlur({
                                    value: event.target.value,
                                    form,
                                    event
                                });
                        }}
                    />
                    <ErrorMessage name={name} component={ValidationWarning} />
                </div>
            )}
        </FieldComponent>
    );
};

export default styled(TextInputField)`
    ${ValidationWarning} {
        margin-top: 4px;
    }
`;
