import React from 'react';
import { inject, observer } from 'mobx-react';

class QuantileTool extends React.Component {
    componentDidMount() {
        const { resourceCenter, forceRefresh } = this.props;
        if (!resourceCenter.resourcesLoaded || forceRefresh) {
            resourceCenter.loadSavedResources();
        }
    }
    render() {
        return this.props.children;
    }
}

export default inject('resourceCenter')(observer(QuantileTool));
