import React, { useContext } from 'react';
import styled from 'styled-components';
import { lexileTheme, quantileTheme } from '~/theme';
import { LexileDialog, QuantileDialog } from '@ui/dialogs/Dialog';

export const LEXILE = 'lexile';
export const QUANTILE = 'quantile';

export const ScaleContext = React.createContext();

const LexileTheme = styled.div`
    ${lexileTheme}
`;

const QuantileTheme = styled.div`
    ${quantileTheme}
`;

const createScaleProvider = scale => ({ children }) => {
    const Theme = scale === LEXILE ? LexileTheme : QuantileTheme;

    return (
        <Theme>
            <ScaleContext.Provider value={scale}>
                {children}
            </ScaleContext.Provider>
        </Theme>
    );
};

export const Lexile = createScaleProvider(LEXILE);
export const Quantile = createScaleProvider(QUANTILE);

export const useScale = () => useContext(ScaleContext);

export const ReadScale = props => {
    const scale = useContext(ScaleContext);
    return props.children({ scale, ...props[scale] });
};

export const ScaleDialog = styled(props => {
    const scale = useScale();

    const Dialog = scale === LEXILE ? LexileDialog : QuantileDialog;

    return <Dialog {...props} />;
})``;
