import React from 'react';
import styled, { css } from 'styled-components';
import { ifPresent } from 'toastezy';
import { i18n } from '@i18n/format';
import { loadNotifications, markAsRead, deleteNotification } from '@api/uap';
import { toolsByToolName } from '@constants/tools';
import baseRoutes from '@constants/routes';
import { pluck } from '@utilities/dataTypes/objects';
import { useUser } from '@utilities/hooks/useUser';
import { pushTo } from '@utilities/navigation';
import Tier, { UserGroup, groups, tiers } from '@ui/layouts/Tier';
import Button, { UpgradeButton, RegisterButton } from '@ui/actions/Button';
import LinkButton from '@ui/actions/LinkButton';
import Link from '@ui/actions/Link';
import Notifications from '@ui/messages/notifications/Notifications';
import AppBarMenu from '@ui/navigation/AppBarMenu';
import NotificationList from '@ui/messages/notifications/NotificationList';
import {
    checkedNotifications,
    clickedNotification
} from '@utilities/analytics/analyticsEvents';
import { useViewport } from '@utilities/hooks/useViewport';
import { breakpoints } from '@utilities/stylingUtils';
import { media } from '@utilities/stylingUtils';

const layout = css`
    display: flex;
    align-items: center;

    ${LinkButton}, ${Link} {
        color: var(--theme-white);
        font-size: 1rem;
    }

    ${Link},
    ${Notifications},
    > button:not(:last-child) {
        margin-right: 16px;

        ${media.phone`
            margin-right: 12px;
        `}
    }

    ${NotificationList} {
        top: calc(100% + 20px);
    }
`;

const onNotificationClick = async ({ tool, externalUrl, eventDescription }) => {
    await clickedNotification({ notificationLabel: eventDescription });

    if (toolsByToolName[tool]) {
        pushTo(toolsByToolName[tool].path);
    }

    if (externalUrl) {
        window.open(externalUrl);
    }
};

const FreeTrialCountdown = () => {
    const { daysLeftInTrial } = useUser();

    return (
        <Link to={baseRoutes.MEMBERSHIP_OPTIONS} $noUnderline>
            {i18n('account.freeTrialDaysRemaining', daysLeftInTrial)}
        </Link>
    );
};

const AppBarButtons = ({ className }) => {
    const { width } = useViewport();
    const breakpoint = breakpoints.phone;

    return (
        <div className={className}>
            <UserGroup group={groups.FREE_TRIAL}>
                <FreeTrialCountdown />
            </UserGroup>
            <Tier level={[tiers.BASIC, tiers.PRO]}>
                <Notifications
                    loadNotifications={loadNotifications}
                    markAsRead={notifications => {
                        notifications
                            .map(pluck('id'))
                            .forEach(ifPresent(markAsRead));
                    }}
                    deleteNotification={deleteNotification}
                    onNotificationClick={onNotificationClick}
                    onOpen={({ unreadCount }) =>
                        unreadCount > 0 && checkedNotifications()
                    }
                />
            </Tier>
            <Tier level={tiers.FREE}>
                {width >= 410 && (
                    <RegisterButton $studentFacing type="button">
                        {i18n('common.register')}
                    </RegisterButton>
                )}
                <Button
                    $studentFacing
                    onClick={() => pushTo(baseRoutes.SIGN_IN)}
                >
                    {i18n('account.signIn')}
                </Button>
            </Tier>
            <Tier level={tiers.BASIC}>
                {width >= 640 && (
                    <UpgradeButton $studentFacing>
                        {i18n('common.upgrade')}
                    </UpgradeButton>
                )}
            </Tier>
            <UserGroup group={groups.FREE_TRIAL}>
                {width >= breakpoint && (
                    <UpgradeButton $studentFacing>
                        {i18n('common.upgrade')}
                    </UpgradeButton>
                )}
            </UserGroup>
            <Tier level={[tiers.BASIC, tiers.PRO]}>
                <AppBarMenu />
            </Tier>
        </div>
    );
};

export default styled(AppBarButtons)`
    ${layout}
`;
