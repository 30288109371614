import { isPositive } from '../predicates';
import { on } from './functions';

export const joinStrings = delimeter => (...strings) =>
    strings.reduce(
        (acc, str) => (str ? `${acc}${acc ? delimeter : ''}${str}` : acc),
        ''
    );

export const trim = (str = '') => str.trim();

export const lowercase = (str = '') => str.toLowerCase();

export const uppercase = (str = '') => str.toUpperCase();

export const capitalize = (str = '') =>
    str.charAt(0).toUpperCase() + str.slice(1);

export const contains = search => string => isPositive(string.indexOf(search));

export const ignoreCaseContains = on(contains)(lowercase);

export const length = (str = '') => (str && str.length) || 0;

export const wordCount = (str = '') => (str ? length(str.match(/\S+/g)) : 0);

export const split = delimiter => str => (str ? str.split(delimiter) : []);

export const firstWord = (str = '') => {
    const match = str.match(/\S+/g);
    return match ? match[0] : '';
};

export const matchesRegex = regex => str => regex.test(str);

export const lessThan = limit => (str = '') => length(str) < limit;

export const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

export const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const qscFormat = /^QSC\d{2,4}$/;

export const numbers = /[0-9]/;

export const spalphabetic = /^[a-zA-Z0-9\s.-]*$/i;

export const decimalNumberOrEmpty = /^(\d+)?([.]?\d*)?$/;

export const emojiEntity = /([^&#]*)(&#.*;)(.*)/;

export const containsEmojiEntity = matchesRegex(emojiEntity);

export const isSpalphabetic = matchesRegex(spalphabetic);

export const isPassword = matchesRegex(passwordFormat);

export const isEmail = matchesRegex(emailFormat);

export const isQSC = matchesRegex(qscFormat);

export const isDecimalNumberOrEmpty = matchesRegex(decimalNumberOrEmpty);

export const hasNumber = matchesRegex(numbers);

export const isZipCode = (str = '') =>
    matchesRegex(numbers)(str) && str.length === 5;

export const couldBeISBN = str => {
    // If it's not 10 or 13 characters, return false
    if (str.length !== 10 && str.length !== 13) {
        return false;
    }
    // If the first 9/12 characters aren't numbers, return false
    if (isNaN(str.substring(0, str.length - 1))) {
        return false;
    }

    // If the last character is not a number and not X, return false
    const lastChar = str.charAt(str.length - 1);
    if (isNaN(lastChar) && lastChar !== 'X') {
        return false;
    }

    return true;
};

export const removeSpecialCharacters = str => str.replace(/[^a-zA-Z0-9]/g, '');

export const removeNonNumericCharacters = str => str && str.replace(/\D/g, '');

export const strToBool = str => lowercase(str) === 'true';
