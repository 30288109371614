import { compose } from '@utilities/dataTypes/functions';
import { isPositive, gte } from '@utilities/predicates';

export const isArray = maybeArray => Array.isArray(maybeArray);

export const length = array => (isArray(array) ? array.length : -1);

export const isEmptyArray = compose(gte(0), length);

export const contains = (array, value) =>
    isArray(array) && isPositive(array.indexOf(value));

export const equalLength = (arrA, arrB) =>
    isArray(arrA) && isArray(arrB) && length(arrA) === length(arrB);

export const equalContents = (arrA, arrB) =>
    equalLength(arrA, arrB) && arrA.every((el, index) => el === arrB[index]);

export const indexOfItem = (arr, value) => arr.indexOf(value);

export const indexOfObject = (arr, obj, searchKey) =>
    arr.findIndex((item, i) => item[searchKey] === obj[searchKey]);
