import React from 'react';
import styled from 'styled-components';
import { Button as DonutButton } from 'donut-ui';
import { whenNotProvided } from '@utilities/stylingUtils';
import { pushTo } from '@utilities/navigation';
import baseRoutes from '@constants/routes';

const Button = styled(DonutButton)`
    color: var(--theme-white);
    background-color: var(--theme-blue1);

    ${whenNotProvided('$studentFacing')`
        padding: 5px 20px;
    `}

    &:disabled {
        color: var(--theme-white);
        background-color: var(--theme-cool-gray);
    }
`;

export const LexileButton = styled(Button)`
    background-color: var(--theme-green2);
`;

export const QuantileButton = styled(Button)`
    background-color: var(--theme-blue3);
`;

export const OrangeButton = styled(Button)`
    background-color: var(--theme-orange3);
    color: var(--theme-dark-gray);
`;

export const HollowButton = styled(Button)`
    background-color: var(--theme-white);
    border: 1px solid var(--theme-gray1);
    color: var(--theme-blue4);

    &:disabled {
        color: var(--theme-gray3);
        background-color: var(--theme-white);
    }
`;

const PurpleButton = styled(Button)`
    background-color: var(--theme-purple1);
`;

export const UpgradeButton = ({ children, onClick, ...props }) => {
    return (
        <OrangeButton
            {...props}
            onClick={() => {
                onClick && onClick();
                pushTo(baseRoutes.BILLING);
            }}
        >
            {children}
        </OrangeButton>
    );
};

export const RegisterButton = ({ children, onClick, ...props }) => {
    return (
        <PurpleButton
            {...props}
            onClick={() => {
                onClick && onClick();
                pushTo(baseRoutes.REGISTRATION);
            }}
        >
            {children}
        </PurpleButton>
    );
};

export default Button;
