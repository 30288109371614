import React from 'react';

const NotificationBell = () => {
    return (
        <svg viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.0351 16.4973h-.2504c-.9515 0-1.7528-.851-1.7528-1.8616V8.51879c0-4.84032-3.806-8.723206-8.4134-8.51044478C7.31165.221106 4.00639 4.15719 4.00639 8.78474v5.90416c0 1.0106-.80128 1.8616-1.7528 1.8616h-.2504C.901437 16.5505 0 17.5079 0 18.6781v.7979c0 .3723.350559.7447.751197.7447H23.2871c.4007 0 .7512-.3724.7512-.7979v-.7979c0-1.1701-.9014-2.1276-2.0032-2.1276zm-7.562 5.8511H9.56532c-.30048 0-.55088.3191-.5008.6382.2504 1.4894 1.50238 2.5532 2.95468 2.5532 1.4523 0 2.7043-1.117 2.9547-2.5532.0501-.3191-.2003-.6382-.5008-.6382z"
                fill="currentColor"
            />
        </svg>
    );
};

export default NotificationBell;
