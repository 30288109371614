import React from 'react';
import { inject, observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { ScreenReaderText, MakeshiftButton } from 'donut-ui';
import { i18n } from '@i18n/format';
import Tier, { tiers } from '@ui/layouts/Tier';
import CheckIcon from '@ui/icons/CheckIcon';
import PlusIcon from '@ui/icons/PlusIcon';

const SavedIcon = styled(CheckIcon)`
    color: var(--theme-green-lexile);
`;

const AddToSavedIcon = styled(PlusIcon)`
    color: var(--theme-blue3);
`;

const layout = css`
    display: inline-flex;
    cursor: pointer;
    padding: 4px;
    border: 1px solid var(--theme-gray1);
    border-radius: 4px;
    transition: box-shadow 0.2s ease;

    :hover {
        box-shadow: var(--elevated);
    }

    svg {
        height: 12px;
        width: 12px;
    }
`;

class ToggleSavedResource extends React.Component {
    state = {
        isUpdating: false
    };

    isResourceSaved = () => {
        return this.props.resourceCenter.isResourceSaved(this.props.resource);
    };

    startUpdate = () => this.setState({ isUpdating: true });
    updateFinished = () => this.setState({ isUpdating: false });

    toggleSave = () => {
        if (this.state.isUpdating) {
            return;
        }

        this.startUpdate();

        const { resourceCenter, resource, onClick } = this.props;

        const isSaved = this.isResourceSaved();
        onClick && onClick(resource, isSaved);

        (isSaved
            ? resourceCenter.removeResources(resource)
            : resourceCenter.saveResource(resource)
        ).then(this.updateFinished);
    };

    render() {
        const { className } = this.props;
        return (
            <Tier level={[tiers.BASIC, tiers.PRO]}>
                <MakeshiftButton>
                    {getButtonProps => (
                        <span
                            {...getButtonProps({
                                className,
                                onClick: this.toggleSave
                            })}
                        >
                            <ScreenReaderText>
                                {i18n(
                                    this.isResourceSaved()
                                        ? 'quantileResourceCenter.removeResource'
                                        : 'quantileResourceCenter.addResource'
                                )}
                            </ScreenReaderText>
                            {this.isResourceSaved() ? (
                                <SavedIcon />
                            ) : (
                                <AddToSavedIcon />
                            )}
                        </span>
                    )}
                </MakeshiftButton>
            </Tier>
        );
    }
}

export default styled(inject('resourceCenter')(observer(ToggleSavedResource)))`
    ${layout}
`;
