import React from 'react';
import styled, { css } from 'styled-components';
import SMCNotFoundImage from '~/images/SMCNotFoundImage.png';
import { i18n, i18nWithHTML } from '@i18n/format';
import Link from '@ui/actions/Link';
import baseRoutes from '@constants/routes';
import { pxToRem } from 'utilities/stylingUtils';

const layout = css`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 36px;
    padding: 80px 32px;

    @media only screen and (max-width: 860px) {
        flex-direction: column-reverse;
        row-gap: 36px;
    }

    .content {
        max-width: 414px;

        h1 {
            margin-bottom: 16px;
            font-size: ${pxToRem(24)};
        }
    }
`;

const toolLinks = [
    mathAtHome => <Link to={baseRoutes.MATH_AT_HOME}>{mathAtHome}</Link>,
    teacherAssistant => (
        <Link to={baseRoutes.QUANTILE_TEACHER_ASSISTANT}>
            {teacherAssistant}
        </Link>
    ),
    mathSkillsDatabase => (
        <Link to={baseRoutes.MATH_SKILLS_DATABASE}>{mathSkillsDatabase}</Link>
    )
];

const SMCNotFound = ({ className }) => {
    return (
        <div className={className}>
            <img src={SMCNotFoundImage} alt="" />
            <div className="content">
                <h1>{i18n('smc.noLongerAvailable')}</h1>
                <p>{i18nWithHTML('smc.otherQuantileResources', toolLinks)}</p>
            </div>
        </div>
    );
};

export default styled(SMCNotFound)`
    ${layout}
`;
