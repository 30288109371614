import { isString, isFunction } from '@utilities/dataTypes/typeChecks';
import { compose, match } from '@utilities/dataTypes/functions';
import { falsyProp, queryAny } from '@utilities/dataTypes/objects';
import { at, alphabetize, sortByProp } from '@utilities/dataTypes/arrays';
import { ignoreCaseContains } from '@utilities/dataTypes/strings';

const filterPlainText = (options, searchString) =>
    searchString ? options.filter(ignoreCaseContains(searchString)) : options;

const matchLabel = searchString =>
    queryAny({
        label: ignoreCaseContains(searchString)
    });

const matchLabelValue = searchString =>
    queryAny({
        value: ignoreCaseContains(searchString),
        label: ignoreCaseContains(searchString)
    });

const filterByText = (options, searchString, labelOnly) =>
    searchString
        ? options.filter(
              labelOnly
                  ? matchLabel(searchString)
                  : matchLabelValue(searchString)
          )
        : options;

const stringOptions = compose(isString, at(0));

export const filterOptions = ({
    options,
    inputValue,
    selectedItem,
    matchOnLabel,
    alreadyOrdered,
    shouldShowAll = falsyProp('inputValue')
}) => {
    const searchString = shouldShowAll({ inputValue, selectedItem })
        ? ''
        : inputValue;

    return match(options)
        .on(isFunction, () => options(searchString))
        .on(stringOptions, () => {
            const filtered = filterPlainText(options, searchString);
            return alreadyOrdered ? filtered : alphabetize(filtered);
        })
        .otherwise(() => {
            const filtered = filterByText(options, searchString, matchOnLabel);
            return alreadyOrdered ? filtered : sortByProp('label')(filtered);
        });
};
