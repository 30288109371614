import React from 'react';
import styled from 'styled-components';
import NullableValue from './NullableValue';
import { formatLexileMeasure } from '@utilities/dataTypes/custom';

const LexileMeasure = ({
    className,
    placeholder = '--',
    code = '',
    children
}) => (
    <span className={className}>
        <NullableValue placeholder={placeholder}>
            {formatLexileMeasure(children, code)}
        </NullableValue>
    </span>
);

export default styled(LexileMeasure)``;
