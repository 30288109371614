import React from 'react';

const ChevronDoubleLeftIcon = ({ title, ...props }) => {
    return (
        <svg viewBox="0 0 18 15" fill="none" {...props}>
            <title>{title}</title>
            <path
                d="M.608 6.985c0 .098.05.208.123.282l5.72 5.72a.423.423 0 0 0 .283.124c.098 0 .209-.05.283-.123l.613-.614a.423.423 0 0 0 .123-.282.423.423 0 0 0-.123-.283L2.806 6.985 7.63 2.16a.423.423 0 0 0 .123-.283.4.4 0 0 0-.123-.282L7.017.981a.423.423 0 0 0-.283-.123.423.423 0 0 0-.282.123L.73 6.702a.423.423 0 0 0-.123.283zM10.608 6.985c0 .098.05.208.123.282l5.72 5.72a.423.423 0 0 0 .283.124c.098 0 .209-.05.283-.123l.614-.614a.423.423 0 0 0 .122-.282.423.423 0 0 0-.122-.283l-4.825-4.824L17.63 2.16a.423.423 0 0 0 .122-.283.4.4 0 0 0-.122-.282l-.614-.614a.423.423 0 0 0-.283-.123.423.423 0 0 0-.282.123L10.73 6.702a.423.423 0 0 0-.123.283z"
                fill="#F2F2F2"
            />
        </svg>
    );
};

export default ChevronDoubleLeftIcon;
