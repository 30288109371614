import React from 'react';
import styled, { css } from 'styled-components';
import { MakeshiftButton, ScreenReaderText } from 'donut-ui';

const layout = css`
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 4px;

    :hover,
    :focus {
        outline: 0;
        box-shadow: var(--outline);
    }

    svg {
        height: 24px;
        width: 24px;
        color: var(--theme-blue4);
    }
`;

const IconButton = React.forwardRef(
    ({ onClick, className, children, label, ...props }, ref) => {
        return (
            <MakeshiftButton>
                {getButtonProps => (
                    <div
                        {...getButtonProps({ className, onClick, ...props })}
                        ref={ref}
                    >
                        <>
                            {/* labels shouldn't be visible on IconButtons... otherwise it's just a regular button */}
                            {label && (
                                <ScreenReaderText>{label}</ScreenReaderText>
                            )}
                            {children}
                        </>
                    </div>
                )}
            </MakeshiftButton>
        );
    }
);

export default styled(IconButton)`
    ${layout}
`;
