import { i18n } from '@i18n/format';
import baseRoutes from '@constants/routes';
import Link from '@ui/actions/Link';
import { useViewport } from '@utilities/hooks/useViewport';

const AppBarLink = () => {
    const { width } = useViewport();
    const breakpoint = 750;

    return (
        <Link to={baseRoutes.HOME_PAGE}>
            {width < breakpoint
                ? i18n('tools.all')
                : i18n('common.lexileAndQuantileTools')}
        </Link>
    );
};

export default AppBarLink;
