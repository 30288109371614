import { Maybe, orElse } from 'toastezy';
import { toBool, equals, negate } from '../predicates';
import { constant, compose } from './functions';
import { contains } from './arrays/queries_arrays';

const fold = maybe => maybe.fold();

export const pluck = prop => obj => Maybe.of(obj ? obj[prop] : obj);

export const pluckOrFallback = (prop, fallback) =>
    compose(orElse(fallback), pluck(prop));

export const truthyValue = prop => obj => !!(obj && obj[prop]);
export const falsyProp = prop => negate(truthyValue(prop));
export const isEmpty = obj =>
    Object.entries(obj).length === 0 && obj.constructor === Object;

/**
 * Currently only handles dot notation, not array-syntax,
 * and
 * @param {String} propStr 'some.prop.to.get'
 * @param {Object} obj to get the prop from
 * @return {Maybe} the value at the nested property
 * @example
 * getIn('palette.color.blue')({
 *   palette: {
 *      color: {
 *          blue: 'blue'
 *      }
 *   }
 * })
 */
export const getIn = (propStr = '') => obj =>
    propStr
        .split('.')
        .reduce((acc, nested) => acc.chain(pluck(nested)), Maybe(obj));

export const update = (obj, key, value) => ({
    ...obj,
    [key]: value
});

export const valueExistsAt = path => compose(toBool, fold, getIn(path));

export const propExists = key => obj => obj && obj.hasOwnProperty(key);

const toObject = key => value => ({ [key]: value });

/**
 * Take an object, who's values are all Maybes,
 * and if those Maybes have values, add them to the object passed
 * as the first param
 *
 * @example
 * insertMaybes({})({
 *     first: Maybe(1),
 *     second: Maybe(2),
 *     third: Maybe(undefined || null)
 * })
 *
 * returns {
 *     first: 1,
 *     second: 2
 * }
 */
export const insertMaybes = obj => maybeStructure =>
    Object.entries(maybeStructure).reduce(
        (acc, [key, maybe]) => ({
            ...acc,
            ...maybe.map(toObject(key)).orElse({})
        }),
        obj
    );

/**
 * @example
 * const isId5 = propEquals('id', 5);
 * isId5({ id: 3 }) //false
 * isId5({ id: 5 })
 */
export const propEquals = (prop, value) =>
    compose(equals(value), fold, pluck(prop));

/**
 * Takes a predicate, and runs it based on a couple of objects' properties.
 * It can take a single prop or two props; and a single object, or two objects.
 *
 * Compares the same prop on two objects...
 * compareProps(greaterThan, 'sum')({ sum: 5 }, { sum: 10 })
 *
 * Compares different props on the same object...
 * compareProps(greaterThan, 'lower', 'upper')({ lower: 2, upper: 5 })
 *
 * Compares different props on different objects....
 * compareProps(greaterThan, 'sumA', 'sumB')({ sumA: 10 }, { sumB: 2 })
 */
export const compareProps = (predicate, propA, propB) => (objA, objB) =>
    toBool(
        Maybe.of(predicate)
            .ap(pluck(propA)(objA))
            .ap(pluck(propB || propA)(objB || objA))
            .fold()
    );

/**
 * Run a number of queries/predicates on an object of a similar structure
 *
 * Checks to see if x or y is greater than 5
 * query(OR)({ x: gt(5), y: gt(5) })({ x: 0, y: 10}) //true
 *
 * @param {Function} join How does this function join booleans together (||, &&, ^, etc)
 * @param {Object} predicateStructure an object with keys matching up with the queried objects, that has predicate fns as values
 * @param {Object} obj the data to query
 */
export const query = join => predicateStructure => obj =>
    Object.entries(predicateStructure).reduce((acc, [key, predicate]) => {
        const res = toBool(predicate(getIn(key)(obj).fold()));

        if (acc === undefined) {
            return res;
        }

        return join(acc, res);
    }, undefined);

export const queryAny = query((x, y) => x || y);
export const queryAll = query((x, y) => x && y);

export const extract = keys => obj =>
    keys.reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
    }, {});

export const mapEntry = f => prop => obj => {
    if (!obj || typeof obj !== 'object') {
        return obj;
    }

    const { [prop]: propValue, ...rest } = obj;
    const result = f(prop, propValue);

    if (result) {
        const [key, value] = result;
        rest[key] = value;
    }

    return rest;
};

//mapProp :: f => prop => obj
export const mapProp = f => mapEntry((key, value) => [key, f(value)]);

export const mapOverKeys = keys => f => obj => {
    if (!obj) {
        return obj;
    }

    return keys.reduce((acc, key) => mapProp(f)(key)(acc), obj);
};

export const mapObj = f => obj => {
    if (!obj) {
        return obj;
    }

    return mapOverKeys(Object.keys(obj))(f)(obj);
};

export const rename = oldName => newName =>
    mapEntry((_, value) => [newName, value])(oldName);

export const renameProps = renames => obj =>
    renames.reduce(
        (acc, [current, newName]) => rename(current)(newName)(acc),
        obj
    );

export const deleteProp = mapEntry(constant(undefined));

export const deleteProps = props => obj =>
    props.reduce((acc, prop) => deleteProp(prop)(acc), obj);

export const filter = predicate => obj => {
    if (!obj) {
        return obj;
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (predicate(value, key)) {
            return acc;
        }
        return deleteProp(key)(acc);
    }, obj);
};

export const deletePropsNotIn = propsToKeep =>
    filter((value, key) => contains(propsToKeep, key));
