import { observable, action, makeObservable } from 'mobx';

class LoadingManager {
    loading = false;
    showLoader = false;

    load = fp => {
        this.setLoading(true);
        return fp()
            .then(response => {
                this.setLoading(false);
                return response;
            })
            .catch(e => {
                this.setLoading(false);
                throw e;
            });
    };

    setLoading = toggle => {
        const changed = this.loading !== toggle;
        this.loading = toggle;

        if (changed) {
            if (toggle) {
                //Websites that display loading indicators for short durations (< 200ms)
                //are perceived as slower and less responsive. This only shows the loader
                //if we've been waiting at least 250ms
                setTimeout(() => {
                    if (this.loading) {
                        this.toggleShowLoader(true);
                    }
                }, 250);
            } else {
                this.showLoader = false;
            }
        }
    };

    toggleShowLoader = showLoader => {
        this.showLoader = showLoader;
    };

    constructor() {
        makeObservable(this, {
            loading: observable,
            showLoader: observable,
            load: action,
            setLoading: action,
            toggleShowLoader: action
        });
    }
}

export const loadingManager = new LoadingManager();
