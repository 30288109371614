export const getItem = key => {
    return JSON.parse(localStorage.getItem(`mm-${key}`));
};

export const setItem = (key, value) => {
    localStorage.setItem(`mm-${key}`, JSON.stringify(value));
};

export const removeItem = key => {
    const item = getItem(`mm-${key}`);
    if (!item) return null;
    localStorage.removeItem(`mm-${key}`);
    return item;
};
