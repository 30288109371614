import { useState, useMemo } from 'react';
import { isString } from '@utilities/dataTypes/typeChecks';
import { sortByPath, sortByProp } from '@utilities/dataTypes/arrays';

//Right now, this assumes sorting a list of objects
const useSorting = (list = [], defaultSort) => {
    const [sortingBy, updateSortingBy] = useState(
        isString(defaultSort) ? { prop: defaultSort, asc: true } : defaultSort
    );

    const sortedList = useMemo(
        () =>
            (sortingBy.path
                ? sortByPath(sortingBy.path, sortingBy.sortFn)
                : sortByProp(sortingBy.prop, sortingBy.sortFn))(
                list,
                !!sortingBy.asc
            ),
        [sortingBy, list]
    );

    return {
        values: sortedList,
        sortingBy,
        updateSortingBy: newSort => {
            if (isString(newSort)) {
                newSort = {
                    prop: newSort,
                    asc: true
                };
            }

            if (
                newSort.path === sortingBy.path &&
                newSort.prop === sortingBy.prop
            ) {
                newSort.asc = !sortingBy.asc;
            }
            updateSortingBy(newSort);
        }
    };
};

export default useSorting;
