// This object corresponds to the object in public/index.html
import { getItem, setItem } from '@utilities/localStorage';

export const languages = {
    en: 'en',
    es: 'es'
};

export const languageCodeText = {
    en: 'English',
    es: 'Español'
};

export const currentLanguage =
    getItem('preferred-locale')?.language || languages.en;

export const setLanguage = language => {
    setItem('preferred-locale', { language });
    window.location.reload();
};

export default languages;
