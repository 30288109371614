import React from 'react';

const SuccessAlert = props => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        {props.title && <title>{props.title}</title>}
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 12C24 18.6274 18.6274 24 12 24C5.37256 24 0 18.6274 0 12C0 5.37256 5.37256 0 12 0C18.6274 0 24 5.37256 24 12ZM4 12.3875L6.82843 9.7417L10 12.7084L17.1716 6L20 8.64575L10 18L4 12.3875Z"
            fill="currentColor"
        />
    </svg>
);

export default SuccessAlert;
