import React from 'react';
import styled, { css } from 'styled-components';
import * as tools from '@constants/tools';
import ToolListItem from './ToolListItem';
import { DrawerLink } from './DrawerLink';
import FindABookDrawerIcon from '@ui/icons/products/FABIcon';
import FADBDrawerIcon from '@ui/icons/products/FADBIcon';
import GrowthPlannerDrawerIcon from '@ui/icons/products/GrowthPlannerIcon';
import TextAnalyzerDrawerIcon from '@ui/icons/products/TextAnalyzerIcon';
import CareerDBDrawerIcon from '@ui/icons/products/CareerDBIcon';
import MMMDrawerIcon from '@ui/icons/products/MMMIcon';
import WordListsDrawerIcon from '@ui/icons/products/WordListsIcon';
import MathSkillsDatabaseDrawerIcon from '@ui/icons/products/MathSkillsIcon';
import TeacherAssistantDrawerIcon from '@ui/icons/products/TeacherAssistantIcon';
import MathAtHomeDrawerIcon from '@ui/icons/products/MathAtHomeIcon';
import GradeLevelChartsIcon from '@ui/icons/products/GradeLevelChartsIcon';
import { useFavorites } from '@features/dashboard/Favorites';
import DecodablePassagesDrawerIcon from '@ui/icons/products/DecodablePassagesIcon';
import { TOOL_DOMAIN } from '@constants/tools';
import { favorites } from '@constants/favorites';

const layout = css`
    ${DrawerLink} {
        margin-bottom: 8px;
    }

    ${ToolListItem}:not(:last-of-type) {
        margin-bottom: 12px;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
`;

const iconsByToolId = {
    [tools.decodablePassages.id]: DecodablePassagesDrawerIcon,
    [tools.findABook.id]: FindABookDrawerIcon,
    [tools.findADecodableBook.id]: FADBDrawerIcon,
    [tools.growthPlanner.id]: GrowthPlannerDrawerIcon,
    [tools.growthPlannerMath.id]: GrowthPlannerDrawerIcon,
    [tools.textAnalyzer.id]: TextAnalyzerDrawerIcon,
    [tools.lexileNorms.id]: GradeLevelChartsIcon,
    [tools.quantileNorms.id]: GradeLevelChartsIcon,
    [tools.careerDatabaseReading.id]: CareerDBDrawerIcon,
    [tools.careerDatabaseMath.id]: CareerDBDrawerIcon,
    [tools.managingMultipleMeasures.id]: MMMDrawerIcon,
    [tools.managingMultipleMeasuresMath.id]: MMMDrawerIcon,
    [tools.lexileWordList.id]: WordListsDrawerIcon,
    [tools.mathSkillsDatabase.id]: MathSkillsDatabaseDrawerIcon,
    [tools.quantileTeacherAssistant.id]: TeacherAssistantDrawerIcon,
    [tools.mathAtHome.id]: MathAtHomeDrawerIcon
};

const getToolCategory = domain => {
    if (domain === TOOL_DOMAIN.LEXILE) {
        return favorites.LEXILE;
    } else if (domain === TOOL_DOMAIN.QUANTILE) {
        return favorites.QUANTILE;
    } else {
        return favorites.K2;
    }
};

const ToolList = ({
    className,
    name,
    url,
    onClick,
    domain,
    collapseSidebar
}) => {
    const favoritesState = useFavorites();
    const favoriteTools = favoritesState[getToolCategory(domain)];

    return (
        <div className={className}>
            <DrawerLink
                onClick={e => {
                    if (onClick) {
                        e.preventDefault();
                        onClick();
                    }
                }}
                to={url}
                aria-current={null}
            >
                {name}
            </DrawerLink>
            <ul>
                {favoriteTools.map(toolId => {
                    const tool = tools.toolsByToolName[toolId];
                    if (tool) {
                        return (
                            <ToolListItem
                                key={tool.id}
                                name={tool.shortName || tool.toolName}
                                url={tool.path || `/${tool.id}`}
                                icon={iconsByToolId[tool.id]}
                                collapseSidebar={collapseSidebar}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
            </ul>
        </div>
    );
};

export default styled(ToolList)`
    ${layout}
`;
