import { makeEndpoint } from './httpRequest';

const NAME = process.env.REACT_APP_GP_NAME;
const ENDPOINT = process.env.REACT_APP_GP_URL;

const gpApi = makeEndpoint(NAME, ENDPOINT);
export const gpRoot = gpApi.configure();

export const calcTrajectory = values =>
    gpApi.post('/growth-chart-internal', values);
