import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { length } from '@utilities/dataTypes/arrays';
import { remove } from '@utilities/dataTypes/arrays';
import { propEquals } from '@utilities/dataTypes/objects';
import { markAllAsRead } from '@utilities/notification';
import useOutsideClickListener from '@utilities/hooks/useOutsideClickListener';
import IconButton from '@ui/actions/IconButton';
import NotificationBell from '@ui/icons/NotificationBell';
import NotificationBubble from './NotificationBubble';
import NotificationList from './NotificationList';

const layout = css`
    position: relative;

    > ${IconButton} {
        padding: 0 0 0 4px;
        height: 40px;
        width: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: flex-end;

        svg {
            color: var(--theme-white);
            height: 30px;
            width: 24px;
        }
    }

    ${NotificationBubble} {
        position: absolute;
        top: 2px;
        left: 1px;
    }

    ${NotificationList} {
        position: absolute;
        right: -12px;
        top: calc(100% + 12px);
    }
`;

const removeReadNotifications = notifications =>
    notifications && notifications.filter(propEquals('read', false));

const Notifications = ({
    className,
    loadNotifications,
    markAsRead,
    deleteNotification,
    onNotificationClick,
    onOpen
}) => {
    const [isOpen, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const clickBoundary = useOutsideClickListener(() => {
        setOpen(false);
        markAsRead(unread);
        setNotifications(markAllAsRead(notifications));
    }, isOpen);

    useEffect(() => {
        loadNotifications().then(setNotifications);
    }, [loadNotifications]);

    const unread = removeReadNotifications(notifications);
    const unreadCount = length(unread);

    return (
        <div className={className} ref={clickBoundary}>
            <IconButton
                onClick={() => {
                    setOpen(!isOpen);
                    if (isOpen) {
                        markAsRead(unread);
                        setNotifications(markAllAsRead(notifications));
                    } else {
                        onOpen && onOpen({ unreadCount });
                    }
                }}
                aria-label="Notifications"
            >
                <NotificationBell />
                {unreadCount > 0 && (
                    <NotificationBubble>{unreadCount}</NotificationBubble>
                )}
            </IconButton>
            {isOpen && (
                <NotificationList
                    notifications={notifications}
                    markAsRead={markAsRead}
                    deleteNotification={notification => {
                        setNotifications(remove(notifications, notification));
                        deleteNotification(notification.id);
                    }}
                    onNotificationClick={onNotificationClick}
                />
            )}
        </div>
    );
};

export default styled(Notifications)`
    ${layout}
`;
