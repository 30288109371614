import { i18n } from '@i18n/format';
import baseRoutes from '@constants/routes';
import { routes } from '@features/dashboard/account/accountRoutes';

export const accountMenuOptions = [
    {
        value: baseRoutes.HOME_PAGE,
        label: i18n('tools.all')
    },
    {
        value: routes.PROFILE,
        label: i18n('common.profile')
    },
    {
        value: routes.CHANGE_PASSWORD,
        label: i18n('account.changePwd')
    },
    {
        value: routes.MAILING_LISTS,
        label: i18n('account.mailingLists')
    },
    {
        value: routes.MEMBERSHIP_PLAN,
        label: i18n('account.membershipPlan')
    },
    {
        label: i18n('account.logout')
    }
];
