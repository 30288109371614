import { isArray } from './queries_arrays';

//In hindsight... this seems silly.
export const concat = (array = [], value) => array.concat(value);

export const concatMap = f => arr =>
    arr.reduce((acc, val) => concat(acc, f(val)), []);

export const prepend = item => array => {
    if (!isArray(array)) {
        return null;
    }

    return concat(isArray(item) ? item : [item], array);
};
