import { i18n } from '@i18n/format';
import { getLongName } from '@constants/states';

const statePartnerLogoAltText = {
    Alabama: i18n('account.partnerLogo.alabama'),
    Alaska: i18n('account.partnerLogo.alaska'),
    Arizona: i18n('account.partnerLogo.arizona'),
    Arkansas: i18n('account.partnerLogo.arkansas'),
    California: i18n('account.partnerLogo.california'),
    Colorado: i18n('account.partnerLogo.colorado'),
    Connecticut: i18n('account.partnerLogo.connecticut'),
    Delaware: i18n('account.partnerLogo.delaware'),
    'District of Columbia': i18n('account.partnerLogo.districtOfColumbia'),
    Florida: i18n('account.partnerLogo.florida'),
    Georgia: i18n('account.partnerLogo.georgia'),
    Hawaii: i18n('account.partnerLogo.hawaii'),
    Idaho: i18n('account.partnerLogo.idaho'),
    Illinois: i18n('account.partnerLogo.illinois'),
    Indiana: i18n('account.partnerLogo.indiana'),
    Iowa: i18n('account.partnerLogo.iowa'),
    Kansas: i18n('account.partnerLogo.kansas'),
    Kentucky: i18n('account.partnerLogo.kentucky'),
    Louisiana: i18n('account.partnerLogo.louisiana'),
    Maine: i18n('account.partnerLogo.maine'),
    Maryland: i18n('account.partnerLogo.maryland'),
    Massachusetts: i18n('account.partnerLogo.massachusetts'),
    Michigan: i18n('account.partnerLogo.michigan'),
    Minnesota: i18n('account.partnerLogo.minnesota'),
    Mississippi: i18n('account.partnerLogo.mississippi'),
    Missouri: i18n('account.partnerLogo.missouri'),
    Montana: i18n('account.partnerLogo.montana'),
    Nebraska: i18n('account.partnerLogo.nebraska'),
    Nevada: i18n('account.partnerLogo.nevada'),
    'New Hampshire': i18n('account.partnerLogo.newHampshire'),
    'New Jersey': i18n('account.partnerLogo.newJersey'),
    'New Mexico': i18n('account.partnerLogo.newMexico'),
    'New York': i18n('account.partnerLogo.newYork'),
    'North Carolina': i18n('account.partnerLogo.northCarolina'),
    'North Dakota': i18n('account.partnerLogo.northDakota'),
    Ohio: i18n('account.partnerLogo.ohio'),
    Oklahoma: i18n('account.partnerLogo.oklahoma'),
    Oregon: i18n('account.partnerLogo.oregon'),
    Pennsylvania: i18n('account.partnerLogo.pennsylvania'),
    'Rhode Island': i18n('account.partnerLogo.rhodeIsland'),
    'South Carolina': i18n('account.partnerLogo.southCarolina'),
    'South Dakota': i18n('account.partnerLogo.southDakota'),
    Tennessee: i18n('account.partnerLogo.tennessee'),
    Texas: i18n('account.partnerLogo.texas'),
    Utah: i18n('account.partnerLogo.utah'),
    Vermont: i18n('account.partnerLogo.vermont'),
    Virginia: i18n('account.partnerLogo.virginia'),
    'Virgin Islands': i18n('account.partnerLogo.virginIslands'),
    Washington: i18n('account.partnerLogo.washington'),
    'West Virginia': i18n('account.partnerLogo.westVirginia'),
    Wisconsin: i18n('account.partnerLogo.wisconsin'),
    Wyoming: i18n('account.partnerLogo.wyoming')
};

const ssoProviderLogoAltText = {
    Okta: i18n('account.partnerLogo.okta'),
    OktaSansState: i18n('account.partnerLogo.oktaSansState'),
    K12: i18n('account.partnerLogo.k12')
};

export const getStatePartnerLogoAltText = partnerState => {
    if (
        !partnerState ||
        !(getLongName(partnerState) in statePartnerLogoAltText)
    ) {
        return i18n('account.partnerLogo');
    }

    return statePartnerLogoAltText[getLongName(partnerState)];
};

export const getSsoProviderLogoAltText = ssoProvider => {
    if (!ssoProvider || !(ssoProvider in statePartnerLogoAltText)) {
        return i18n('account.partnerLogo');
    }

    return ssoProviderLogoAltText[ssoProvider];
};
