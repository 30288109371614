import React from 'react';

const MathSkillsIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            aria-hidden="true"
        >
            <path d="M10.63 5.63a1.26 1.26 0 0 0-1.25-1.25H1.25A1.25 1.25 0 0 0 0 5.63v8.12A1.25 1.25 0 0 0 1.25 15h8.13a1.26 1.26 0 0 0 1.25-1.25zM8.13 10a.33.33 0 0 1-.32.31H5.94v1.88a.34.34 0 0 1-.31.31H5a.33.33 0 0 1-.31-.31v-1.88H2.81A.33.33 0 0 1 2.5 10v-.62a.34.34 0 0 1 .31-.32h1.88V7.19A.33.33 0 0 1 5 6.88h.63a.33.33 0 0 1 .31.31v1.87h1.87a.34.34 0 0 1 .32.32zm4.68 2.5h-.93v-1.87h.93a.3.3 0 0 0 .32-.32V2.19a.3.3 0 0 0-.32-.31H4.69a.29.29 0 0 0-.31.31v.94H2.5v-.94A2.18 2.18 0 0 1 4.69 0h8.12A2.18 2.18 0 0 1 15 2.19v8.12a2.18 2.18 0 0 1-2.19 2.19z" />
        </svg>
    );
};

export default MathSkillsIcon;
