import React from 'react';
import styled from 'styled-components';
import NoResultsImage from '~/images/Lexile/findABook/NoResults.png';

const Layout = styled.div`
    text-align: center;

    h1 {
        margin-top: 80px;
        margin-bottom: 40px;
        font-weight: normal;
        color: var(--theme-less-dark-gray);
    }

    img {
        height: 400px;
        width: 400px;
        object-fit: contain;
    }
`;

class MainErrorBoundary extends React.Component {
    state = {
        hasError: false
    };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Layout>
                    <h1>
                        Well, this is unexpected... Please refresh the page and
                        try again.
                    </h1>
                    <img src={NoResultsImage} alt="Oops" />
                </Layout>
            );
        }

        return this.props.children;
    }
}

export default MainErrorBoundary;
