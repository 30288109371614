import React from 'react';
import { Field, ErrorMessage, FastField } from 'formik';
import styled from 'styled-components';
import { getIn } from '@utilities/dataTypes/objects';
import { isFunction } from '@utilities/dataTypes/typeChecks';
import Autocomplete from '@ui/inputs/Autocomplete';
import ValidationWarning from '@ui/messages/ValidationWarning';

class ControlledAutocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            selectedItem: props.field.value || '',
            highlightedIndex: -1,
            isOpen: false,
            onStateChange: this.syncDownshiftState
        };
    }

    selectedItemToText = selectedItem => {
        return this.props.formatSelectedValue
            ? this.props.formatSelectedValue(selectedItem)
            : selectedItem.label || selectedItem;
    };

    componentDidMount() {
        const { field } = this.props;
        if (field.value) {
            this.setState({
                inputValue: this.selectedItemToText(field.value),
                selectedItem: field.value
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.field.value !== this.props.field.value) {
            this.setState({
                inputValue: getIn('field.value')(this.props)
                    .map(this.selectedItemToText)
                    .orElse(''),
                selectedItem: this.props.field.value || ''
            });
        }
    }

    syncDownshiftState = changes => this.setState(changes);

    render() {
        const {
            form,
            field,
            name,
            onSearch,
            onSelect,
            onEnter,
            className,
            ...props
        } = this.props;

        return (
            <span className={className}>
                <Autocomplete
                    {...props}
                    name={name}
                    control={this.state}
                    onSelect={value => {
                        form.setFieldValue(name, value);
                        onSelect && onSelect(value, form);
                    }}
                    onBlur={() => form.setFieldTouched(name, true)}
                    onSearch={
                        !onSearch ? undefined : () => onSearch({ field, form })
                    }
                    onEnter={
                        !onEnter
                            ? undefined
                            : async inputValue => {
                                  await field.onChange({
                                      target: {
                                          name: field.name,
                                          value: inputValue
                                      }
                                  });
                                  isFunction(onEnter) &&
                                      onEnter({ field, form, inputValue });
                              }
                    }
                />
                <ErrorMessage name={name} component={ValidationWarning} />
            </span>
        );
    }
}

const freeTextFormatter = item => {
    if (typeof item === 'string') {
        return item;
    }

    return item?.label || item || '';
};

const AutocompleteField = props => {
    const FieldComponent = props.optimized ? FastField : Field;

    return (
        <FieldComponent name={props.name} validate={props.validate}>
            {formik => (
                <ControlledAutocomplete
                    {...props}
                    {...formik}
                    formatSelectedValue={
                        props.allowFreeText ? freeTextFormatter : undefined
                    }
                />
            )}
        </FieldComponent>
    );
};

export default styled(AutocompleteField)`
    display: inline-block;
    width: 100%;

    ${ValidationWarning} {
        margin-top: 4px;
    }
`;
