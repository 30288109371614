import { getData, getName, overwrite } from 'country-list';
import { sort } from 'ramda';
import { lexicalSort } from '@utilities/sorts';
import { renameProps } from '@utilities/dataTypes/objects';

const countryBlacklist = ['VI', 'VG'];

overwrite([{ code: 'GB', name: 'United Kingdom' }]);

const sortCountries = sort((a, b) =>
    a.value === 'US' ? -1 : lexicalSort(a.label, b.label)
);

export const countryOptions = sortCountries(
    getData()
        .filter(({ code }) => !countryBlacklist.includes(code)) // Remove blacklisted countries
        .map(
            renameProps([
                ['code', 'value'],
                ['name', 'label']
            ])
        )
);

export const getCountryLabel = val => getName(val) || val;
