import { loadingManager } from '@features/global/LoadingManager';
import { wlDownload } from '@utilities/analytics/analyticsEvents';
import { makeEndpoint, tiered } from './httpRequest';

const NAME = process.env.REACT_APP_WORDLISTS_NAME;
const ENDPOINT = process.env.REACT_APP_WORDLISTS_URL;

export const WORDS_ONLY = 'wordsOnly';
export const WORDS_AND_FREQUENCY = 'wordsAndFrequency';

const ACCEPT_TYPES = {
    pdf: 'application/pdf; version=1.0',
    csv: 'text/csv; version=1.0',
    zip: 'application/zip; version=1.0'
};

const wlApi = makeEndpoint(NAME, ENDPOINT);
export const wordListsRoot = wlApi.configure(() => ({}));

const getFileFormat = fileFormats =>
    fileFormats.length > 1 ? 'zip' : fileFormats[0];

export const downloadWordList = filters => {
    wlDownload(filters);
    const { grade, fileFormats, domains, contents } = filters;

    // Words only vs words and freqency
    const includeQueryParam =
        contents === WORDS_AND_FREQUENCY ? '&frequencies=true' : '';
    const requestedFormat = getFileFormat(fileFormats);
    const domainsQueryParams = domains
        .map(domain => `&domains=${domain}`)
        .join('');

    const domainsFileName = domains.join('_');
    return loadingManager.load(() =>
        tiered(wlApi.download)(
            `/wordlists?grade=${grade}${domainsQueryParams}${includeQueryParam}`,
            {
                format: ACCEPT_TYPES[requestedFormat],
                fileName: `WordList_grade${grade}_${domainsFileName}.${requestedFormat}`
            }
        )
    );
};

export const fetchWordListVersion = () =>
    wlApi.get('/version', () => ({
        headers: {
            Accept: 'application/json; version=1.0',
            'Content-Type': 'application/json; version=1.0'
        }
    }));
