import { apm } from '@elastic/apm-rum';
import { bind } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { capitalize } from '@utilities/dataTypes/strings';
import { getCookieConsent } from './cookieConsent';

let sessionID = null;
let userContext;

export const initializeUserContext = () => {
    userContext = {
        userId: null,
        userRole: null,
        userGroups: null,
        userCountry: null,
        userState: null,
        userIdentityProvider: null
    };
};

initializeUserContext();

export const initTracking = bind(enabled => {
    if (getCookieConsent() || enabled) {
        apm.init({
            serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
            serverUrl: process.env.REACT_APP_APM_SERVER_URL,
            serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION,
            disableInstrumentations: ['fetch', 'xmlhttprequest'],
            pageLoadTransactionName: 'Landed On hub.lexile.com'
        });

        sessionID = uuidv4();
        apm.addLabels({ sessionID });
    }
}, apm);

export const startTransaction = bind(
    (name, type) => apm._initialized && apm.startTransaction(name, type),
    apm
);

const addLabelPrefixes = (labels, prefix) => {
    if (!labels || !prefix) {
        return labels;
    }

    return Object.entries(labels).reduce((acc, [key, value]) => {
        return {
            ...acc,
            [`${prefix}${capitalize(key)}`]: value
        };
    }, {});
};

export const addTransactionLabels = (labels, prefix) => transaction =>
    new Promise(resolve => {
        if (transaction && labels) {
            transaction.addLabels(addLabelPrefixes(labels, prefix));
        }
        resolve();
    });

export const endTransaction = transaction => transaction && transaction.end();

export const setUserContext = user => {
    userContext = {
        userRole: user.role,
        userGroups: user.groups,
        userCountry: user.country,
        userState: user.state,
        userIdentityProvider: user.identityProvider || 'Hub Cognito'
    };

    if (apm._initialized) {
        apm.setUserContext({
            id: user.uuid,
            email: user.email
        });

        apm.addLabels(userContext);

        userContext.userId = user.uuid;
    }
};

export const generateSessionEndData = value =>
    JSON.stringify({
        platform: process.env.REACT_APP_APM_SERVICE_NAME,
        sessionId: sessionID,
        sourceUrl: window.location.href,
        eventType: 'Session Terminated',
        value: JSON.stringify(value),
        ...userContext,
        userGroups: JSON.stringify(userContext.groups) || null
    });
