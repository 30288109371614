import React from 'react';
import styled, { css } from 'styled-components';
import { i18n } from '@i18n/format';
import { whenProvided } from '@utilities/stylingUtils';
import Button from '@ui/actions/Button';
import Dialog from './Dialog';
import ActionBar from './ActionBar';

const dialogLayout = css`
    ${ActionBar} {
        position: sticky;
        bottom: 0;
    }
`;

const ConfirmationText = styled.div`
    padding: 20px 20px 24px 20px;
    border-bottom: 1px solid var(--theme-gray1);
`;

const CancelButton = styled(Button)`
    background-color: var(--theme-white);
    border: 1px solid var(--theme-gray1);
    color: var(--theme-blue3);
    margin-right: 16px;
`;

const ConfirmButton = styled(Button)`
    color: var(--theme-white);
    background-color: var(--theme-blue3);

    ${whenProvided('destructive')`
        background-color: var(--theme-red);
    `}
`;

const ConfirmationDialog = ({
    dialogState,
    onConfirm,
    onCancel,
    className,
    title,
    cancelLabel = i18n('common.cancel'),
    confirmationLabel = i18n('common.yes'),
    forceDecision = false,
    scrollable = false,
    confirmationOnly,
    destructive,
    children
}) => {
    return (
        <Dialog
            className={className}
            dialogState={dialogState}
            title={title}
            onClose={onCancel}
            hasClose={!forceDecision}
            scrollable={scrollable}
        >
            <div>
                <ConfirmationText>{children}</ConfirmationText>
                <ActionBar>
                    {!confirmationOnly && (
                        <CancelButton
                            $studentFacing
                            onClick={() => {
                                const result = onCancel && onCancel();
                                if (result && result.then) {
                                    result.then(dialogState.closeDialog);
                                } else {
                                    dialogState.closeDialog();
                                }
                            }}
                        >
                            {cancelLabel}
                        </CancelButton>
                    )}
                    <ConfirmButton
                        $studentFacing
                        onClick={() => {
                            const result = onConfirm();
                            if (result && result.then) {
                                result.then(dialogState.closeDialog);
                            } else {
                                dialogState.closeDialog();
                            }
                        }}
                        destructive={destructive}
                    >
                        {confirmationLabel}
                    </ConfirmButton>
                </ActionBar>
            </div>
        </Dialog>
    );
};

export default styled(ConfirmationDialog)`
    ${dialogLayout}
`;
