import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import baseRoutes from '@constants/routes';
import NotFound from '@features/NotFound';

const ToolRedirects = () => (
    <Switch>
        <Route
            path="/fab"
            render={() => <Redirect to={baseRoutes.FIND_A_BOOK} />}
        />
        <Route
            path="/lexile-cdb"
            render={() => <Redirect to={baseRoutes.CAREER_DATABASE_LEXILE} />}
        />
        <Route
            path="/quantile-cdb"
            render={() => <Redirect to={baseRoutes.CAREER_DATABASE_QUANTILE} />}
        />
        <Route
            path="/wl"
            render={() => <Redirect to={baseRoutes.WORDLISTS} />}
        />
        <Route
            path="/lexile-mm"
            render={() => <Redirect to={baseRoutes.MMM_LEXILE} />}
        />
        <Route
            path="/quantile-mm"
            render={() => <Redirect to={baseRoutes.MMM_QUANTILE} />}
        />
        <Route
            path="/la"
            render={() => <Redirect to={baseRoutes.TEXT_ANALYZER} />}
        />
        <Route
            path="/quantile-gp"
            render={() => <Redirect to={baseRoutes.GROWTH_PLANNER_QUANTILE} />}
        />
        <Route
            path="/lexile-gp"
            render={() => <Redirect to={baseRoutes.GROWTH_PLANNER_LEXILE} />}
        />
        <Route
            path="/lexile-glc"
            render={() => <Redirect to={baseRoutes.NORMS_LEXILE} />}
        />
        <Route
            path="/quantile-glc"
            render={() => <Redirect to={baseRoutes.NORMS_QUANTILE} />}
        />
        <Route
            path="/smc"
            render={() => <Redirect to={baseRoutes.SUMMER_MATH_CHALLENGE} />}
        />
        <Route path={baseRoutes.START_SSO} />
        <Route render={() => <NotFound />} />
    </Switch>
);

export default ToolRedirects;
