import { Amplify } from 'aws-amplify';
import { uapRoot } from './uap';
import { fabRoot } from './fab';
import { gpRoot } from './growthPlanner';
import { mmmRoot } from './mmm';
import { textAnalyzerRoot } from './textAnalyzer';
import { wordListsRoot } from './wordLists';
import { careerDbRoot } from './careerDB';
import { quantileRoot } from './quantile';
import { gradeLevelChartsRoot } from './gradeLevelCharts';
import { decodablePassagesRoot } from './decodablePassages';

const configure = () =>
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            region: process.env.REACT_APP_COGNITO_REGION,
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
            userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
        },
        Storage: {
            region: process.env.REACT_APP_S3_REGION,
            bucket: process.env.REACT_APP_S3_BUCKET,
            identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
        },
        apiGateway: {
            REGION: process.env.REACT_APP_API_GATEWAY_REGION,
            URL: process.env.REACT_APP_API_GATEWAY_URL
        },
        API: {
            endpoints: [
                uapRoot,
                fabRoot,
                gpRoot,
                mmmRoot,
                textAnalyzerRoot,
                wordListsRoot,
                careerDbRoot,
                quantileRoot,
                gradeLevelChartsRoot,
                decodablePassagesRoot
            ]
        }
    });

export default configure;
