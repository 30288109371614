import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { i18n } from '@i18n/format';
import { isZero } from '@utilities/predicates';
import { whenProvided } from '@utilities/stylingUtils';
import { valueExistsAt } from '@utilities/dataTypes/objects';
import Link from '@ui/actions/Link';
import LinkButton from '@ui/actions/LinkButton';
import QSCCard from './QSCCard';
import ToggleSaveResource from '@features/quantileResourceCenter/ToggleSaveResource';
import RegisterPrompt from '@features/quantile/RegisterPrompt';
import ChevronDown from '@ui/icons/ChevronDown';

const Resources = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 0 0 8px;

    li {
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    ${Link} {
        font-size: 0.875rem;
        text-decoration: none;
    }

    ${Link}:hover {
        text-decoration: underline;
    }
`;

const StyledRegisterPrompt = styled(RegisterPrompt)`
    margin-bottom: 12px;
`;

const Chevron = styled(ChevronDown)`
    margin: 0 8px;
    transition: transform 150ms ease-out;

    ${whenProvided('open')`
        transform: rotate(180deg);
    `}
`;

const ResourceList = ({ resources = [] }) => {
    const [isOpen, setOpen] = useState(false);
    const resourcesWithLinks = useMemo(
        () => resources.filter(valueExistsAt('asset_link')),
        [resources]
    );
    return (
        <QSCCard>
            <h2>
                <span>{i18n('common.resources')}</span>
                {!isZero(resourcesWithLinks.length) && (
                    <LinkButton onClick={() => setOpen(!isOpen)}>
                        {i18n(
                            isOpen
                                ? 'mathSkillsDB.hideResources'
                                : 'mathSkillsDB.showResources',
                            resourcesWithLinks.length
                        )}
                        <Chevron open={isOpen} />
                    </LinkButton>
                )}
            </h2>
            <StyledRegisterPrompt />
            {isOpen && (
                <Resources>
                    {resourcesWithLinks.map(resource =>
                        !resource.asset_link ? undefined : (
                            <li key={resource.id}>
                                <Link
                                    href={resource.asset_link}
                                    target="_blank"
                                >
                                    {resource.name}
                                </Link>
                                <ToggleSaveResource resource={resource} />
                            </li>
                        )
                    )}
                </Resources>
            )}
        </QSCCard>
    );
};

export default ResourceList;
