export const subtract = y => x => x - y;

export const clamp = (floor, ceiling) => x =>
    Math.min(Math.max(x, floor), ceiling);

export const between = start => end => value => value >= start && value <= end;

export const divisibleBy = factor => value => {
    if (value === null || value === undefined || isNaN(value)) {
        return false;
    }

    return value % factor === 0;
};

export const isEven = divisibleBy(2);
