import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import WebFont from 'webfontloader';
import { createBrowserHistory } from 'history';
import {
    initialize as initializeNavigation,
    isInitialized,
    listen,
    AppRouter
} from '@utilities/navigation';
import configureAPI from '@api/configure';
import { initTracking } from '@utilities/analytics/agent';
import { trackPageViews } from '@utilities/analytics/analyticsEvents';
import App from './App';

const initialize = () => {
    initializeNavigation(createBrowserHistory());
    listen(trackPageViews);
    initTracking();

    configure({ enforceActions: 'always' });

    configureAPI();

    WebFont.load({
        google: {
            families: ['Cabin:400,700', 'sans-serif']
        }
    });
};

if (!isInitialized()) {
    initialize();
}

ReactDOM.render(
    <AppRouter>
        <App />
    </AppRouter>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}
