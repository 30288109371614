import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox as DonutCheckbox, whenProvided } from 'donut-ui';
import { TooltipWrapper } from '@ui/Tooltip';
import { onEscape } from 'utilities/keyboardEvents';
import useTooltip from 'utilities/hooks/useTooltip';

const layout = css`
    display: inline-flex;
    align-items: flex-start;

    ${DonutCheckbox.Label} {
        color: var(--theme-less-dark-gray);

        ${whenProvided('disabled')`
            opacity: 0.6;
        `}
    }

    ${DonutCheckbox.focusSelector} {
        box-shadow: 0px 0px 3px var(--theme-blue2);
    }

    ${DonutCheckbox.Check} {
        color: var(--theme-blue2);
    }
`;

const Checkbox = ({
    className,
    tooltip,
    tooltipLeft = false,
    tooltipIcon = true,
    ...checkboxProps
}) => {
    const { tooltipId, tooltipRef, showTooltip, hideTooltip } = useTooltip();

    return (
        <div className={className}>
            {tooltip ? (
                <TooltipWrapper
                    id={tooltipId}
                    ref={tooltipRef}
                    text={tooltip}
                    tooltipLeft={tooltipLeft}
                    tooltipIcon={tooltipIcon}
                >
                    <DonutCheckbox
                        {...checkboxProps}
                        a11yDescribedBy={tooltipId}
                        onFocus={showTooltip}
                        onBlur={hideTooltip}
                        onKeyUp={onEscape(hideTooltip)}
                    />
                </TooltipWrapper>
            ) : (
                <DonutCheckbox {...checkboxProps} />
            )}
        </div>
    );
};

export default styled(Checkbox)`
    ${layout}
`;
