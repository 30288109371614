import { useState, useEffect } from 'react';
import { getIn } from '@utilities/dataTypes/objects';
import { isString } from '@utilities/dataTypes/typeChecks';
import { i18n } from '@i18n/format';

const subscriptions = new Set();

const useSubscription = (subs, subscription) => {
    useEffect(() => {
        subs.add(subscription);

        return () => subs.delete(subscription);
    }, [subs, subscription]);
};

const useAlertHandler = () => {
    const [alert, setAlert] = useState();
    useSubscription(subscriptions, setAlert);

    return {
        alert,
        clear: () => setAlert()
    };
};

const types = {
    error: 'error',
    success: 'success'
};

const notifySubscribers = type => message => {
    if (isString(message)) {
        message = { message };
    }

    subscriptions.forEach(setState => setState({ type, ...message }));
};

const alert = notifySubscribers(types.error);
const success = notifySubscribers(types.success);

const getErrorMessage = (
    error,
    fallbackMessage = i18n('common.fallbackHTTPError')
) =>
    getIn('response.data.error_msg')(error)
        .filter(isString)
        .orElse(fallbackMessage);

const httpError = (
    error,
    fallbackMessage = i18n('common.fallbackHTTPError')
) => {
    alert({
        message: getErrorMessage(error, fallbackMessage)
    });
};

export { types, httpError, alert, success, useAlertHandler, getErrorMessage };
