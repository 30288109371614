import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { listen } from '@utilities/navigation';
import { FavoritesProvider } from '@features/dashboard/Favorites';
import AppBarButtons from '@ui/navigation/AppBarButtons';
import AppBarLink from '@ui/navigation/AppBarLink';
import QuickLinks from '@ui/navigation/QuickLinks';
import MainAppBar from '@ui/navigation/MainAppBar';
import {
    CollapsibleSidebarHeader,
    CollapsibleSidebarContent
} from '@ui/navigation/CollapsibleSidebar';
import { appBarHeight } from '@ui/navigation/navConstants';
import SkipLink from '@ui/navigation/SkipLink';
import ToolRoutes from './ToolRoutes';
import Footer from './Footer';
import { ViewportProvider } from 'utilities/hooks/useViewport';

const Main = styled.main`
    min-height: calc(100vh - 2 * ${appBarHeight});
`;

const PageLayout = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    scroll-padding-top: ${appBarHeight};

    .appBar-and-main {
        flex: auto;
    }

    .mainLayout-row {
        width: 100%;
        display: flex;
    }
`;

const ContentWindow = styled.div`
    height: calc(100vh - ${appBarHeight});
    width: 100%;
    overflow-y: auto;
`;

const MainLayout = () => {
    const contentWindowRef = useRef(null);

    const [isContentVisible, setContentVisible] = useState(true);
    const [isOpen, toggleOpen] = useState(true);

    useEffect(() => {
        listen(() => {
            if (contentWindowRef.current) {
                contentWindowRef.current.scrollTop = 0;
            }
        });
    });

    return (
        <PageLayout>
            <SkipLink />
            <FavoritesProvider>
                <ViewportProvider>
                    <div className="mainLayout-row">
                        <CollapsibleSidebarHeader
                            isOpen={isOpen}
                            toggleOpen={toggleOpen}
                            setContentVisible={setContentVisible}
                        />
                        <MainAppBar>
                            <AppBarLink />
                            <AppBarButtons />
                        </MainAppBar>
                    </div>
                    <div className="mainLayout-row">
                        <CollapsibleSidebarContent
                            isOpen={isOpen}
                            toggleOpen={toggleOpen}
                            isContentVisible={isContentVisible}
                            setContentVisible={setContentVisible}
                        >
                            <QuickLinks />
                        </CollapsibleSidebarContent>
                        <ContentWindow ref={contentWindowRef}>
                            <Main id="content" tabIndex="-1">
                                <ToolRoutes />
                            </Main>
                            <Footer />
                        </ContentWindow>
                    </div>
                </ViewportProvider>
            </FavoritesProvider>
        </PageLayout>
    );
};

export default MainLayout;
