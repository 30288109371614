import React from 'react';
import styled, { css } from 'styled-components';
import Link from '@ui/actions/Link';
import { i18n } from '@i18n/format';
import { appBarHeight } from '@ui/navigation/navConstants';
import { currentYear } from '@utilities/dataTypes/dates';
import {
    MMContact,
    privacyPolicy,
    termsOfUse
} from '~/constants/externalLinks';

const layout = css`
    min-height: ${appBarHeight};
    background-color: var(--theme-gray4);
    color: var(--theme-less-dark-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid var(--theme-gray1);
    white-space: nowrap;

    @media only screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

const Links = styled.div`
    display: flex;

    ${Link} {
        padding: 8px;
    }
`;

const Footer = ({ className }) => (
    <footer className={className}>
        <p>
            &copy; {currentYear} {i18n('common.allRightsReserved')}
        </p>
        <Links>
            <Link href={termsOfUse} target="_blank" $noUnderline>
                {i18n('common.termsOfUse')}
            </Link>
            <Link href={privacyPolicy} target="_blank" $noUnderline>
                {i18n('account.privacyPolicy')}
            </Link>
            <Link href={MMContact} target="_blank" $noUnderline>
                {i18n('common.contactUs')}
            </Link>
        </Links>
    </footer>
);

export default styled(Footer)`
    ${layout}
`;
