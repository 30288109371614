import styled, { css } from 'styled-components';
import { Tooltip as DonutTooltip, MakeshiftTooltip } from 'donut-ui';

const themeStyles = css`
    ${DonutTooltip.Content} {
        background: var(--theme-blue4);
        color: var(--theme-white);
    }

    ${DonutTooltip.Triangle} {
        background: var(--theme-blue4);
    }
`;

export const TooltipWrapper = styled(MakeshiftTooltip)`
    ${themeStyles}
`;

export default styled(DonutTooltip)`
    ${themeStyles}
`;
