export const aboutBookLabels =
    'https://hubsupport.lexile.com/use_find_a_book_to_create_labels_for_your_library.html';

export const aboutLexileMeasures = spanish =>
    spanish
        ? 'https://lexile.com/educators/understanding-lexile-measures/lexile-measures-spanish/'
        : 'https://lexile.com/educators/understanding-lexile-measures/about-lexile-measures-for-reading/';

export const fullSizeWordlistsInfographic =
    'https://mmincstatic.s3.amazonaws.com/atlas-uap/Wordlists_Infographic.png';

export const lexileFAQ =
    'https://lexile.com/educators/understanding-lexile-measures/factsheets-faqs/';

export const lexileNormsResearch =
    'https://lexile.com/educators/measuring-growth-with-lexile/college-and-career-readiness/';

export const lqAcademy =
    'https://lexilequantileacademy.catalog.instructure.com/';

export const lqAcademyLexileCourse =
    'https://lexilequantileacademy.catalog.instructure.com/courses/lexile-certification-course';

export const lqAcademyQuantileCourse =
    'https://lexilequantileacademy.catalog.instructure.com/courses/quantile-certification-course';

export const MMContact = 'https://support.lexile.com/s/contactsupport';

export const oldUAP = 'https://accounts.lexile.com/login/';

export const onet = 'https://www.onetonline.org/';

export const openLibraryLink = 'https://openlibrary.org/collections/k-12';

export const privacyPolicy =
    'https://policies.metametricsinc.com/MetaMetrics_Global_Privacy_Policy.pdf';

export const quantileFAQ =
    'https://www.quantiles.com/educators/understanding-quantile-measures/fact-sheets-faqs/';

export const whatIsDeezQuantiles =
    'https://www.quantiles.com/educators/understanding-quantile-measures/what-is-a-quantile-measure/';

export const quantileNormResearch =
    'https://www.quantiles.com/educators/measuring-growth-with-quantile-measures/college-career-readiness/';

export const lexileNormsQuickStartGuide =
    'https://hubsupport.lexile.com/lexile_and_quantile_grade_level_charts_quick_start_guide.html';

export const quantileNormsQuickStartGuide =
    'https://hubsupport.lexile.com/lexile_and_quantile_grade_level_charts_quick_start_guide_2.html';

export const support = 'https://hubsupport.lexile.com/';

export const TAEditingGuide =
    'https://hubsupport.lexile.com/lexile_analyzer_text_preparation_guidelines.html';

export const termsOfUse =
    'https://policies.metametricsinc.com/MetaMetrics_Terms_of_Use.pdf';

export const msdQuickStartGuide =
    'https://hubsupport.lexile.com/quantile_math_skills_database_quick_start_guide.html';

export const quantileLearnMore = 'https://www.quantiles.com/';

export const findADecodableBookKnowledgeBase =
    'https://hubsupport.lexile.com/decodability.html';

export const phraseListPDF =
    'https://mmincstatic.s3.amazonaws.com/atlas-uap/21st+Century+Phrase+List.pdf';

export const lexileTextAnalyzerContentCreatorLink =
    'https://metametricsinc.com/text-analyzer-content-creator/';

export const contactUsLink =
    'https://www2.metametricsinc.com/lexile-contact-us';
