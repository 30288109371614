import styled from 'styled-components';

const StyledFlexParent = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : 'space-between'};
    align-items: ${({ alignItems }) =>
        alignItems ? alignItems : 'flex-start'};
`;

export default StyledFlexParent;
