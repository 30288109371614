import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from '@i18n/format';
import {
    getSsoProviderLogoAltText,
    getStatePartnerLogoAltText
} from '@constants/partnerLogoAltText';
import {
    partnerLogoURL,
    statePartnerLogoURL
} from '@constants/partnerLogoURLs';
import {
    identityProviderBackToPortalURLs as idpPortalURLs,
    ssoProviderHasLogo,
    ssoProvidersArray
} from '@constants/identityProviders';
import { TOOL_DOMAIN } from '@constants/tools';
import { lqAcademy, support } from '@constants/externalLinks';
import baseRoutes from '@constants/routes';
import { useUser } from '@utilities/hooks/useUser';
import { pushTo } from '@utilities/navigation';
import { clickedLQAcademyQuickLink } from '@utilities/analytics/analyticsEvents';
import StarIcon from '@ui/icons/StarIcon';
import LexileLogo from '@ui/icons/LexileLogo';
import QuantileLogo from '@ui/icons/QuantileLogo';
import { drawerConstants, appBarHeight } from '@ui/navigation/navConstants';
import ToolList from '@ui/navigation/ToolList';
import {
    UserGroup,
    groups,
    useProvider,
    IdentityProvider
} from '@ui/layouts/Tier';
import ScreenReaderText from '@ui/messages/ScreenReaderText';
import LQAcademyIcon from '@ui/icons/products/LQAcademyIcon';
import { DrawerLink, ExternalDrawerLink } from './DrawerLink';
import { ExternalToolLink } from './ToolListItem';

const Layout = styled.div`
    height: calc(100vh - ${appBarHeight});
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 48px;

    .partner-logo {
        display: flex;
        justify-content: center;
        width: 100%;

        > img {
            max-width: ${drawerConstants.openWidth};
        }
    }

    ${ToolList} {
        margin-bottom: 48px;
    }

    div.footer {
        ${DrawerLink}, ${ExternalDrawerLink} {
            margin-bottom: 12px;
        }

        .logo-link {
            display: inline-block;
            margin-right: 12px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
`;

const QuickLinksHeader = styled.div`
    margin: 24px 0;
    display: flex;
    align-items: center;
    color: var(--theme-yellow1);

    h2 {
        text-transform: uppercase;
        font-size: 0.75rem;
        margin: 0 8px 0 0;
    }
`;

const LQAcademyLink = styled(ExternalToolLink)`
    margin-bottom: 24px;

    ${LQAcademyIcon} {
        width: 24px;
        height: 17px;
        color: var(--theme-white);
    }
`;

const PartnerLogo = () => {
    const user = useUser();
    const logoURL = ssoProviderHasLogo(user.identityProvider)
        ? partnerLogoURL(user.identityProvider)
        : statePartnerLogoURL(user.state);

    if (!logoURL) {
        return false;
    }

    const logoAltText = ssoProviderHasLogo(user.identityProvider)
        ? getSsoProviderLogoAltText(user.identityProvider)
        : getStatePartnerLogoAltText(user.state);

    return (
        <div className="partner-logo">
            <img src={logoURL} alt={logoAltText} />
        </div>
    );
};

const SSOBackToPortalLink = () => {
    const { providerName } = useProvider();

    if (!idpPortalURLs[providerName]) {
        return null;
    }

    const { text, url } = idpPortalURLs[providerName];
    return (
        text &&
        url && (
            <ExternalDrawerLink rel="noreferrer noopener" href={url}>
                {text}
            </ExternalDrawerLink>
        )
    );
};

@withRouter
class QuickLinks extends React.Component {
    render() {
        return (
            <Layout>
                <div>
                    <nav aria-labelledby="user-quick-links">
                        <UserGroup
                            group={[groups.STATE_PRO, groups.PARTNER_PRO]}
                        >
                            <PartnerLogo />
                        </UserGroup>
                        <QuickLinksHeader>
                            <h2 id="user-quick-links">
                                {i18n('common.quickLinks')}
                            </h2>
                            <StarIcon />
                        </QuickLinksHeader>
                        <LQAcademyLink
                            href={lqAcademy}
                            target="_blank"
                            rel="noreferrer noopener"
                            onClick={() => {
                                clickedLQAcademyQuickLink();
                                this.props.collapseSidebar &&
                                    this.props.collapseSidebar();
                            }}
                        >
                            <LQAcademyIcon />
                            {i18n('lqAcademy.title')}
                        </LQAcademyLink>

                        <ToolList
                            name={i18n('tools.k2')}
                            onClick={() => {
                                pushTo(baseRoutes.HOME_PAGE, {
                                    referrer: TOOL_DOMAIN.K2
                                });
                                this.props.collapseSidebar &&
                                    this.props.collapseSidebar();
                            }}
                            url={baseRoutes.HOME_PAGE}
                            domain={TOOL_DOMAIN.K2}
                            collapseSidebar={this.props.collapseSidebar}
                        />

                        <ToolList
                            name={i18n('lexile.tools')}
                            onClick={() => {
                                pushTo(baseRoutes.HOME_PAGE, {
                                    referrer: TOOL_DOMAIN.LEXILE
                                });
                                this.props.collapseSidebar &&
                                    this.props.collapseSidebar();
                            }}
                            url={baseRoutes.HOME_PAGE}
                            domain={TOOL_DOMAIN.LEXILE}
                            collapseSidebar={this.props.collapseSidebar}
                        />
                        <ToolList
                            name={i18n('quantile.tools')}
                            onClick={() => {
                                pushTo(baseRoutes.HOME_PAGE, {
                                    referrer: TOOL_DOMAIN.QUANTILE
                                });
                                this.props.collapseSidebar &&
                                    this.props.collapseSidebar();
                            }}
                            url={baseRoutes.HOME_PAGE}
                            domain={TOOL_DOMAIN.QUANTILE}
                            collapseSidebar={this.props.collapseSidebar}
                        />
                        <DrawerLink
                            to={baseRoutes.HOME_PAGE}
                            aria-current={null}
                            onClick={
                                this.props.collapseSidebar
                                    ? this.props.collapseSidebar
                                    : null
                            }
                        >
                            {i18n('tools.seeAll')}
                        </DrawerLink>
                    </nav>
                    <div className="footer" aria-label="Support">
                        <IdentityProvider provider={ssoProvidersArray}>
                            <SSOBackToPortalLink />
                        </IdentityProvider>
                        <DrawerLink
                            activeClassName="active"
                            to={baseRoutes.MEMBERSHIP_OPTIONS}
                            onClick={
                                this.props.collapseSidebar
                                    ? this.props.collapseSidebar
                                    : null
                            }
                        >
                            {i18n('account.membershipOptions')}
                        </DrawerLink>
                        <ExternalDrawerLink
                            rel="noreferrer noopener"
                            target="_blank"
                            href={support}
                            onClick={
                                this.props.collapseSidebar
                                    ? this.props.collapseSidebar
                                    : null
                            }
                        >
                            {i18n('common.support')}
                        </ExternalDrawerLink>
                        <a className="logo-link" href="https://lexile.com">
                            <ScreenReaderText>
                                {i18n('lexile.info')}
                            </ScreenReaderText>
                            <LexileLogo width={36} />
                        </a>
                        <a
                            className="logo-link"
                            href="https://www.quantiles.com/"
                        >
                            <ScreenReaderText>
                                {i18n('quantile.info')}
                            </ScreenReaderText>
                            <QuantileLogo width={36} />
                        </a>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default QuickLinks;
