import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { appBarHeight } from '@ui/navigation/navConstants';

const SkipLinkButton = styled.a`
    position: absolute;
    top: 0px;
    left: -10000px;
    height: 1px;
    width: 1px;
    overflow: hidden;
    display: flex;
    align-items: center;
    z-index: 101;

    &:focus {
        left: 0px;
        height: ${appBarHeight};
        width: unset;
        color: var(--theme-white);
        background-color: var(--theme-blue1);
        padding: 5px 20px;
    }
`;

const SkipLink = () => {
    const bodyElement = document.getElementById('skip-link');

    return ReactDOM.createPortal(
        <SkipLinkButton href="#content">Skip to content</SkipLinkButton>,
        bodyElement
    );
};

export default SkipLink;
