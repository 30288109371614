export const slice = begin => end => array => array.slice(begin, end);

export const at = index => array => array && array[index];

/**
 * Returns the first n elements of an array
 * @example
 * take(2)([1, 2, 3, 4, 5]) //returns [1, 2]
 */
export const take = slice(0);

/**
 * Return the first element of the array
 * @example
 * head([1, 2, 3]) //returns [1]
 */
export const head = take(1);

/**
 * Returns elements that are after a given index (inclusive)
 *
 * @example
 * takeAfter(2)([1, 2, 3, 4, 5, 6]) //returns [3, 4, 5, 6]
 */
export const takeAfter = startIndex => slice(startIndex)();

/**
 * Return the last element of the array
 * @example
 * tail([1, 2, 4]) //returns [4]
 */
export const tail = takeAfter(-1);
