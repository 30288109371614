import React from 'react';
import styled from 'styled-components';

const GraduationCapSimple = ({ className }) => {
    return (
        <svg
            className={className}
            width="100%"
            height="100%"
            viewBox="0 0 290 172"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
        >
            <path d="M0,54l147,47l143,-48l-143,-53l-147,54Z" fill="#fff" />
            <rect x="264" y="59" width="5" height="33" fill="#fff" />
            <ellipse cx="266.5" cy="92" rx="5.5" ry="6" fill="#fff" />
            <path
                d="M264.8,126l-6.8,0l8.5,-41l8.5,41l-6.8,0l-1.7,-4l-1.7,4Z"
                fill="#fff"
            />
            <path
                d="M58,86l0,53c5.963,35.403 144.313,50.027 177,1l-1,-53l-88,29l-88,-30"
                fill="#fff"
            />
        </svg>
    );
};

export default styled(GraduationCapSimple)``;
