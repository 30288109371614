import { prop } from 'ramda';
import { format } from 'date-fns';
import { Maybe } from 'toastezy';
import { makeEndpoint, tiered } from './httpRequest';
import { loadingManager } from '@features/global/LoadingManager';
import { toBiFunction } from '@utilities/dataTypes/functions';
import {
    pluck,
    getIn,
    insertMaybes,
    deleteProp
} from '@utilities/dataTypes/objects';
import { lexileMeasureToInt } from '@utilities/dataTypes/custom';

const NAME = process.env.REACT_APP_FAB_NAME;
const ENDPOINT = process.env.REACT_APP_FAB_URL;

const fabApi = makeEndpoint(NAME, ENDPOINT);

export const fabRoot = fabApi.configure();

const getInFilters = toBiFunction(getIn);

const formatIndicator = indicator =>
    Maybe.of(indicator).map(deleteProp('label'));

/**
 * This will return -100 when a value of BR/BR100L is entered for a spanish search.
 * -100 is a special indicator for the 'results' section where the slider for spanish goes to 'BR' (-100)
 *
 * BR can be entered in the range search page, and the value of BR100L can be selected through the slider
 */
const spanishBRRangeSearch = (value, spanishMode) => {
    if (spanishMode && (value === 'BR' || value === 'BR100L')) {
        return -100;
    }

    return value;
};

const formatFiltersForSearch = (filters, spanishMode) => {
    const lexileRange = insertMaybes({})({
        gte: Maybe.of(filters.lexileRange)
            .chain(pluck('start'))
            .map(start => spanishBRRangeSearch(start, spanishMode))
            .map(lexileMeasureToInt),
        lte: Maybe.of(filters.lexileRange)
            .chain(pluck('end'))
            .map(end => spanishBRRangeSearch(end, spanishMode))
            .map(lexileMeasureToInt)
            .map(end => {
                if (
                    (spanishMode && filters.lexileMeasure === '1500L') ||
                    filters.lexileMeasure === '1825L'
                ) {
                    return 5000;
                }

                return end;
            })
    });

    const indicatorFilters = insertMaybes({})({
        decoding_demand_percentile: formatIndicator(
            filters.decoding_demand_percentile
        ),
        semantic_demand_percentile: formatIndicator(
            filters.semantic_demand_percentile
        ),
        structure_demand_percentile: formatIndicator(
            filters.structure_demand_percentile
        ),
        syntactic_demand_percentile: formatIndicator(
            filters.syntactic_demand_percentile
        ),
        msl: formatIndicator(filters.msl),
        mlf: formatIndicator(filters.mlf)
    });

    let requestFilters = insertMaybes({
        language: spanishMode ? 'spanish' : 'english'
    })({
        categories: Maybe.fromEmpty(filters.categories?.map(prop('value'))),
        subcategories: Maybe.fromEmpty(filters.subCategories),
        lexile: Maybe.fromEmpty(lexileRange),
        lexile_codes: Maybe.fromEmpty(filters.lexileCodes),
        interests: Maybe.fromEmpty(filters.interests),
        book_type: Maybe(filters.bookType),
        foundational_reading: Maybe(filters.foundational_reading)
    });

    if (!spanishMode) {
        requestFilters = {
            ...requestFilters,
            ...indicatorFilters
        };
    }

    return insertMaybes({
        filters: requestFilters,
        sort_by: filters.sortBy,
        spanish_br_range_search:
            spanishMode &&
            (lexileRange?.gte === -100 || lexileRange?.lte === -100)
    })({
        field: getInFilters('quickSearch.value.field', filters),
        term: Maybe.of(
            getInFilters('quickSearch.value.searchStr', filters).orElse(
                filters.quickSearch
            )
        )
    });
};

export const findBooks = (filters = {}, spanishMode, page) =>
    tiered(fabApi.post)('/search', {
        ...formatFiltersForSearch(filters, spanishMode),
        results_per_page: 25,
        page
    });

export const findBookByIsbn = isbn => tiered(fabApi.get)(`/books/${isbn}`);

export const loadOtherBooksInSeries = (seriesName, excludeISBN) =>
    fabApi.get('/series', {
        name: seriesName,
        isbn: excludeISBN
    });

export const fetchSubcategoryMapping = () => fabApi.get('/categories');

export const loadFavoriteBooks = () => fabApi.get('/bookshelves/favorites');

export const addToFavorites = workId =>
    fabApi.post('/bookshelves/favorites', {
        works: [workId]
    });

export const removeFavorites = workIds =>
    fabApi.del('/bookshelves/favorites', {
        works: workIds
    });

// We always put json on the end of accept types
// in the case that a validation error is returned as json
const ACCEPT_TYPES = {
    pdf: 'application/pdf; version=1.0, application/json; version=1.0',
    zip: 'application/zip; version=1.0, application/json; version=1.0'
};

export const generateLabels = (work_ids, size, additional_options) => {
    const expectedResponseFormat = size === 'large' ? 'pdf' : 'zip';

    return loadingManager.load(() =>
        tiered(fabApi.download)(
            '/labels',
            {
                format: ACCEPT_TYPES[expectedResponseFormat],
                fileName: `Labels_Bookshelf_${format(
                    Date.now(),
                    'MM_dd_yyyy_hh_mm_ss'
                )}.${expectedResponseFormat}`
            },
            'POST',
            {
                work_ids,
                size,
                additional_options
            }
        )
    );
};
