import styled from 'styled-components';
import { branchStyles } from '@utilities/stylingUtils';

const InputLabel = styled.label`
    display: inline-block;
    font-size: 0.875rem;
    color: ${branchStyles(({ disabled }) => disabled)`
            var(--theme-gray3)
        `.else`
            var(--theme-less-dark-gray)
        `};
`;

export default InputLabel;
