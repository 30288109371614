import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import BasicCheckbox from '@ui/inputs/toggles/Checkbox';
import { checkbox } from './formActions';

const CheckboxField = ({ name, value, onChange, ...checkboxProps }) => {
    return (
        <Field name={name}>
            {({ field, form }) => (
                <BasicCheckbox
                    {...field}
                    {...checkboxProps}
                    value={value}
                    onChange={checkbox.onChange(form, name, value, onChange)}
                    checked={checkbox.checked(field, value)}
                />
            )}
        </Field>
    );
};

export default styled(CheckboxField)``;
