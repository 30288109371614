import { Lexile, SpanishLexile } from 'toastezy';

const between = (start, end) => measure =>
    start.lte(measure) && end.gte(measure);

const LexileRange = ([floor, ceil], isSpanish) => {
    const LexileType = isSpanish ? SpanishLexile : Lexile;
    floor = LexileType(floor);
    ceil = LexileType(ceil);

    return {
        //Prefer using LexileRange methods when possible
        start: floor,
        end: ceil,
        asValues: () => ({ start: floor.valueOf(), end: ceil.valueOf() }),
        asTuple: () => [floor.valueOf(), ceil.valueOf()],
        asFormattedValues: () => ({
            start: floor.toString(),
            end: ceil.toString()
        }),
        coerce: value =>
            floor.gt(value)
                ? floor.valueOf()
                : ceil.lt(value)
                ? ceil.valueOf()
                : value,
        containsMeasure: measure => {
            // BR300L doesn't exist in Spanish
            if (
                !LexileType(measure).isValid ||
                (isSpanish && Lexile(measure).isValid && Lexile(measure).lt(0))
            ) {
                return false;
            }

            return between(floor, ceil)(LexileType(measure));
        },
        mapStart: f => LexileRange([floor.map(f, floor), ceil], isSpanish),
        mapEnd: f => LexileRange([floor, ceil.map(f, ceil)], isSpanish),
        setStart: measure => LexileRange([measure, ceil], isSpanish),
        setEnd: measure => LexileRange([floor, measure], isSpanish),
        toString: () => `${floor.toString()} - ${ceil.toString()}`,
        withinBounds: bounds => {
            const range = LexileRange.fromRange(bounds, isSpanish);
            return range.containsMeasure(floor) && range.containsMeasure(ceil);
        }
    };
};

LexileRange.fromRange = ({ start, end, gte, lte }, isSpanish) =>
    LexileRange([start || gte, end || lte], isSpanish);

export default LexileRange;

export const textBounds = LexileRange([-650, 2000]);
export const studentBounds = LexileRange([-650, 1825]);
export const studentRangeBounds = LexileRange([-650, 1875]);
export const spanishBounds = LexileRange(['BR', 1500], true);
export const spanishRangeBounds = LexileRange(['BR', 1550], true);
