import React, { useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDialog } from 'donut-ui';
import { i18n } from '@i18n/format';
import ConfirmationDialog from '@ui/dialogs/ConfirmationDialog';
import { getMsUntilSessionExpiration } from '@api/session';
import { inactiveSessionQueryParam } from '@constants/session';
import routes from '@constants/routes';
import { manualLogoutTrigger, timeOutLogoutTrigger } from '@constants/session';
import { alert } from '@utilities/alerts';
import { isHubSessionEvent, setLogoutTrigger } from '@utilities/sessionStorage';
import { UserContext } from '@utilities/contexts';

const layout = css``;

// QA Line 60s
// let TIMEOUT_WARNING_DURATION = 60000;

// TODO: Prod Line: 10 seconds before id token expires,
// so approx 10 minutes before full session expires.
// Keeping timeframe within lifespan of idToken to
// reduce complexity of operating within time window
// between when idToken is expired, but refreshToken
// is still valid.
let TIMEOUT_WARNING_DURATION = 600000;

const SessionExpiration = () => {
    const dialogState = useDialog();
    const { logOut, refresh } = useContext(UserContext);

    const warningTimer = useRef(0);
    const expirationTimer = useRef(0);
    const expirationCheck = useRef(0);
    const sessionJustRefreshed = useRef(0);

    const endSession = signInRoute => {
        console.log('SessionExpiration.endsession');
        if (signInRoute.endsWith(inactiveSessionQueryParam)) {
            setLogoutTrigger(timeOutLogoutTrigger);
        } else {
            setLogoutTrigger(manualLogoutTrigger);
        }
        if (dialogState.isOpen) {
            logOut(signInRoute).then(dialogState.closeDialog);
        } else {
            logOut(signInRoute);
        }
    };

    const clearTimers = () => {
        clearTimeout(warningTimer?.current);
        warningTimer.current = 0;
        clearTimeout(expirationTimer?.current);
        expirationTimer.current = 0;
    };

    useEffect(() => {
        const modalClose = event => {
            if (
                isHubSessionEvent(event) &&
                (event.key.endsWith('idToken') ||
                    event.key.endsWith('LastAuthUser') ||
                    event.key.endsWith('accessToken'))
            ) {
                if (event.oldValue !== event.newValue && dialogState.isOpen) {
                    console.log('SessionExpiration.closeDialog');
                    dialogState.closeDialog();
                }
            }
        };
        window.removeEventListener('storage', modalClose);
        window.addEventListener('storage', modalClose);

        const cleanup = () => window.removeEventListener('storage', modalClose);

        return cleanup;
    }, [dialogState]);

    useEffect(() => {
        getMsUntilSessionExpiration().then(expiration => {
            if (expiration > expirationCheck.current) {
                sessionJustRefreshed.current = false;
            }
            if (expiration && !sessionJustRefreshed.current) {
                if (!warningTimer.current) {
                    let timeoutSafetyCheck = expiration + Date.now();
                    warningTimer.current = setTimeout(() => {
                        if (timeoutSafetyCheck - Date.now() > 0) {
                            dialogState.openDialog();
                        }
                    }, expiration - TIMEOUT_WARNING_DURATION);
                }

                if (!expirationTimer.current) {
                    expirationTimer.current = setTimeout(() => {
                        endSession(
                            `${routes.SIGN_IN}/?${inactiveSessionQueryParam}`
                        );
                        alert(i18n('account.staleSessionWarning'));
                    }, expiration);
                }
                expirationCheck.current = expiration;
            }
        });

        return () => clearTimers();
    });

    return (
        <ConfirmationDialog
            dialogState={dialogState}
            title={i18n('account.sessionExpiration')}
            onConfirm={() => {
                clearTimers();
                refresh();
                sessionJustRefreshed.current = true;
            }}
            onCancel={() => {
                clearTimers();
                endSession(routes.SIGN_IN);
            }}
            cancelLabel={i18n('account.logout')}
            confirmationLabel={i18n('account.sessionContinue')}
            forceDecision
        >
            <p>{i18n('account.sessionExpirationDescription')}</p>
        </ConfirmationDialog>
    );
};

export default styled(SessionExpiration)`
    ${layout}
`;
