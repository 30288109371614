import styled, { css } from 'styled-components';
import { truthyValue } from '@utilities/dataTypes/objects';

/**
 * Takes in a px number and returns rem string for use in css values.
 * Assumes base browser font size of 16px.
 *
 * @example pxToRem(32)
 * @returns 2.0000
 */
export const pxToRem = px => (px / 16).toFixed(4) + 'rem';

export const branded = (Component, prop, lexile) => styled(Component)`
    ${prop}: ${lexile
        ? 'var(--theme-green-lexile)'
        : 'var(--theme-blue-quantile)'};
`;

export const whenProvided = propName => (...args) => props =>
    truthyValue(propName)(props) ? css(...args) : '';

export const whenNotProvided = propName => (...args) => props =>
    !truthyValue(propName)(props) ? css(...args) : '';

//Holy functions!
export const branchStyles = predicate => (...trueCss) => ({
    else: (...falseCss) => props =>
        predicate(props) ? css(...trueCss) : css(...falseCss)
});

export const breakpoints = {
    largeDesktop: 1280,
    desktop: 1024,
    tablet: 768,
    phone: 576
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${breakpoints[label] / 16}em) {
            ${css(...args)}
        }
    `;
    return acc;
}, {});
