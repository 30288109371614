import React from 'react';

const DecodablePassagesIcon = props => {
    return (
        <svg
            {...props}
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4269 18.9894L0.788645 19C0.360763 19 0.0143826 18.6595 0.0143826 18.2401L0 0.770436C0 0.349863 0.346381 0.00939941 0.773064 0.00939941L12 0L15.1868 3L15.2 18.2283C15.2 18.6489 14.8536 18.9894 14.4269 18.9894ZM12 8.1735V16.8265H10.102V15.7362C9.42493 16.5141 8.45892 17 7.20397 17C4.91501 17 3 15.0103 3 12.5C3 9.98969 4.91501 7.99997 7.20397 7.99997C8.45892 7.99997 9.42493 8.48583 10.102 9.26379V8.1735H12ZM10.153 12.5C10.153 10.9932 8.983 9.81617 7.52691 9.81617C6.07082 9.81617 4.88102 10.9932 4.88102 12.5C4.88102 14.0067 6.06799 15.1838 7.52691 15.1838C8.98584 15.1838 10.153 14.0067 10.153 12.5ZM3.36962 4.90272C4.76344 5.56691 6.12772 5.94046 7.54309 5.9498C8.95955 5.95914 10.3616 5.60327 11.8304 4.90252L10.9692 3.09743C9.71512 3.69574 8.61178 3.9568 7.55628 3.94984C6.49968 3.94287 5.42542 3.66689 4.22999 3.09723L3.36962 4.90272Z"
            />
        </svg>
    );
};

export default DecodablePassagesIcon;
