import React from 'react';
import styled from 'styled-components';
import { i18n } from '@i18n/format';
import useSorting from '@utilities/hooks/useSorting';
import { isEmptyArray } from '@utilities/dataTypes/arrays';
import { Table, SortableHeader } from '@ui/layouts/Table';

const NoResults = styled.p`
    margin: 0 0.5rem 0.875rem;
`;

const StandardsTable = styled(Table)`
    margin-bottom: 0.875rem;

    th {
        white-space: nowrap;
    }
`;

const StateStandardResults = ({ standards, state, qscId }) => {
    const sorting = useSorting(standards, 'goal_code');
    const noResults = isEmptyArray(standards);

    return (
        <div>
            {noResults ? (
                <NoResults>
                    {i18n(
                        'mathSkillsDB.noStateStandards',
                        state,
                        `QSC${qscId}`
                    )}
                </NoResults>
            ) : (
                <StandardsTable>
                    <thead>
                        <tr>
                            <SortableHeader
                                sorting={sorting}
                                relatedProp="goal_code"
                            >
                                {i18n('mathSkillsDB.standardID')}
                            </SortableHeader>
                            <SortableHeader
                                sorting={sorting}
                                relatedProp="description"
                            >
                                {i18n('common.description')}
                            </SortableHeader>
                            <SortableHeader
                                sorting={sorting}
                                relatedProp="course"
                            >
                                {i18n('common.course')}
                            </SortableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {sorting.values.map(standard => (
                            <tr key={standard.id}>
                                <td>{standard.goal_code}</td>
                                <td>{standard.description}</td>
                                <td>{standard.course}</td>
                            </tr>
                        ))}
                    </tbody>
                </StandardsTable>
            )}
        </div>
    );
};

export default StateStandardResults;
