import React from 'react';
import styled from 'styled-components';
import { formatApiDate } from '@utilities/dataTypes/dates';

const Date = ({ className, timestamp, secondsTimestamp }) => {
    return (
        <span className={className}>
            {formatApiDate(secondsTimestamp ? timestamp * 1000 : timestamp)}
        </span>
    );
};

export default styled(Date)``;
