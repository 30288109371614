import React from 'react';
import styled, { css } from 'styled-components';
import { i18n } from '@i18n/format';
import { media } from '@utilities/stylingUtils';
import {
    WhatAreLexiles,
    WhatAreQuantiles
} from '@features/dashboard/home/whatAreMeasures/WhatAreMeasuresDialog';
import LanguageToggle from '@features/global/LanguageToggle';
import familyImage from '~/images/FamilyImage.png';

const layout = css`
    position: relative;
    display: flex;
    flex-direction: column;

    ${LanguageToggle} {
        align-self: flex-end;
        margin-top: 16px;
        margin-right: 16px;
    }

    .introduction-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 3rem;

        img {
            width: 65%;
            max-width: 450px;
            min-width: 300px;
        }

        .introduction-text {
            margin-left: 20px;
        }

        h1 {
            font-size: 2.5rem;
            margin: 12px 0;
        }

        h2 {
            font-size: 1.5rem;
            font-weight: normal;
        }

        li {
            font-size: 1.25rem;
            margin: 32px 0;
        }

        ${WhatAreLexiles}, ${WhatAreQuantiles} {
            display: block;
            font-size: 1.125rem;
            margin: 8px 0;
            text-transform: uppercase;

            :before {
                content: '> ';
            }
        }
    }

    .background-angle {
        height: 475px;
        width: 100%;

        background-color: var(--theme-gray4);
        opacity: 0.8;
        clip-path: polygon(0 0, 100% 0, 100% 50%, 0 90%);

        position: absolute;
        z-index: -1;
    }

    ${media.desktop`
         .introduction-content {
            flex-direction: column;
    
            .introduction-text {
                margin-left: 0;
            }
    
            h1 {
                font-size: 2rem;
                text-align: center;
            }
    
            h2 {
                font-size: 1.25rem;
                font-weight: normal;
                text-align: center;
            }
    
            li {
                font-size: 1rem;
                margin: 32px 0;
            }
    
            ${WhatAreLexiles}, ${WhatAreQuantiles}  {
                display: block;
                font-size: 1rem;
                margin: 8px 0;
            }
        }
    `}
`;

const FamilyIntroduction = ({ className }) => {
    return (
        <div className={className}>
            <div className="background-angle" />
            <LanguageToggle />
            <div className="introduction-content">
                <img src={familyImage} alt="" role="presentation" />
                <div className="introduction-text">
                    <h1>{i18n('home.welcomeFamily')}</h1>
                    <h2>{i18n('home.helpChildGrow')}</h2>
                    <ul>
                        <li>
                            {i18n('home.lexileDescribes')}
                            <WhatAreLexiles />
                        </li>
                        <li>
                            {i18n('home.quantileDescribes')}
                            <WhatAreQuantiles />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default styled(FamilyIntroduction)`
    ${layout}
`;
