import React from 'react';
import styled, { css } from 'styled-components';
import { i18nWithHTML } from '@i18n/format';
import { RegistrationLink } from '@ui/actions/Link';
import Tier, { tiers } from '@ui/layouts/Tier';

const layout = css`
    font-weight: bold;

    a {
        font-weight: bold;
    }
`;

const RegisterPrompt = ({ className, translatedToEs }) => (
    <Tier level={tiers.FREE}>
        <div className={className}>
            {i18nWithHTML(
                translatedToEs
                    ? 'mathAtHome.registerToSaveResources'
                    : 'common.registerToSaveResources',
                register => (
                    <RegistrationLink>{register}</RegistrationLink>
                )
            )}
        </div>
    </Tier>
);

export default styled(RegisterPrompt)`
    ${layout}
`;
