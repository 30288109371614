import React from 'react';
import NukaCarousel from 'nuka-carousel';
import styled from 'styled-components';
import { mapRange } from '@utilities/dataTypes/ranges';
import ScreenReaderText from '@ui/messages/ScreenReaderText';

const layout = styled.div`
    display: inline-block;
`;

const DotLayout = styled.ul`
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 8px;
    }

    button {
        border: 1px solid var(--theme-gray1);
        background: transparent;
        border-radius: 6px;
        cursor: pointer;
        height: 12px;
        width: 12px;
        padding: 0;
    }

    button[data-active='true'] {
        border: 1px solid var(--theme-blue4);
        background: var(--theme-blue4);
        height: 12px;
        width: 12px;
    }
`;

const PagingDots = ({ slideCount, goToSlide, currentSlide }) => {
    return (
        <DotLayout aria-label="Change slides">
            {mapRange(0, slideCount, 1, idx => (
                <li key={idx}>
                    <button
                        data-active={currentSlide === idx}
                        aria-disabled={currentSlide === idx}
                        onClick={() => goToSlide(idx)}
                        type="button"
                    >
                        <ScreenReaderText>{`Go to slide ${
                            idx + 1
                        }`}</ScreenReaderText>
                    </button>
                </li>
            ))}
        </DotLayout>
    );
};

const Carousel = ({ children, className, label, ...props }) => {
    return (
        <div
            className={className}
            role="group"
            aria-roledescription="carousel"
            aria-label={label}
        >
            <NukaCarousel
                wrapAround
                autoplay
                autoplayInterval={10000}
                renderCenterLeftControls={() => {}}
                renderCenterRightControls={() => {}}
                renderBottomCenterControls={PagingDots}
                cellSpacing={20}
                easing="easeCubicOut"
                dragging={false}
                swiping={false}
                {...props}
            >
                {children}
            </NukaCarousel>
        </div>
    );
};

export default styled(Carousel)`
    ${layout}
`;
