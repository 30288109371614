import React, { useState, useEffect } from 'react';
import { useDialog } from 'donut-ui';
import { loadUrgentMessages, markAsRead } from '@api/uap';
import { isEmptyArray } from '@utilities/dataTypes/arrays';
import messageTemplates from './messageTemplates';

const UrgentMessagesDialog = ({ message }) => {
    const Component = message && messageTemplates[message.eventId];

    const dialogState = useDialog({
        onClose: () => markAsRead(message.id),
        isOpen: true
    });

    return <Component message={message} dialogState={dialogState} />;
};

const UrgentMessages = () => {
    const [message, setMessage] = useState(null);

    useEffect(() => {
        loadUrgentMessages().then(messages => {
            if (!isEmptyArray(messages)) {
                setMessage(messages[0]);
            }
        });
    }, []);

    if (!message) {
        return null;
    }

    return <UrgentMessagesDialog message={message} />;
};

export default UrgentMessages;
