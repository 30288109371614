import React from 'react';
import styled, { css } from 'styled-components';
import { ScreenReaderText } from 'donut-ui';
import { i18n } from '@i18n/format';
import Calculator from '@ui/icons/Calculator';
import Protractor from '@ui/icons/Protractor';
import MathChalkboard from '@ui/icons/MathChalkboard';
import quantileArt from '~/images/Quantile_Popup.png';
import CareerReadiness from '@features/dashboard/home/whatAreMeasures/CareerReadiness';
import {
    contentStyles,
    layout
} from '@features/dashboard/home/whatAreMeasures/measuresArtStyles';
import {
    EasyQuantileSection,
    HardQuantileSection,
    OnTargetQuantileSection,
    QuantileSection
} from '@features/dashboard/home/whatAreMeasures/DifficultySections';

const quantileLayout = css`
    ${layout};
    ${contentStyles};

    .student-measure {
        bottom: 52px;
        right: 34%;
        transform: rotate(51deg);
    }

    ${QuantileSection.QSC} {
        display: flex;
        flex-direction: column;
        max-width: 170px;

        > :first-child {
            font-size: 1.125rem;
            font-weight: bold;
        }

        > :nth-child(2) {
            margin-bottom: 8px;
            text-transform: uppercase;
            font-size: 0.825rem;
        }
    }

    ${OnTargetQuantileSection} {
        ${QuantileSection.QSC} {
            display: inline-block;

            background-color: #d1edf6;
            max-width: 175px;
            text-align: center;

            margin-top: 10px;
            padding: 3px 8px;
            border-radius: 4px;

            > :first-child {
                font-size: 1rem;
                margin-right: 4px;
            }
        }
    }

    ${CareerReadiness.CareerCircle} {
        background-color: #0071bc;
        border: solid 8px #20bddc;
    }

    ${Protractor} {
        height: 70px;
        margin-bottom: 8px;
        margin-right: 8px;
    }

    ${MathChalkboard} {
        height: 38px;
    }

    ${Calculator} {
        height: 42px;
    }
`;

export const QuantilePopupArt = styled(({ className }) => (
    <>
        <ScreenReaderText>{i18n('home.quantileGraphicAlt')}</ScreenReaderText>
        <div className={className} aria-hidden={true}>
            <span className="title">{i18n('home.findMath')}</span>
            <CareerReadiness measure="1350Q" />
            <EasyQuantileSection
                measure="450Q"
                Icon={Calculator}
                qsc={i18n('home.quantileGraphic.divide')}
                difficulty={i18n('common.findABook.easier')}
            />
            <OnTargetQuantileSection
                measure="850Q"
                Icon={Protractor}
                qsc={i18n('home.quantileGraphic.measureAngle')}
                difficulty={i18n('home.onTarget')}
            />
            <HardQuantileSection
                measure="1250Q"
                Icon={MathChalkboard}
                qsc={i18n('home.quantileGraphic.identifyUndefined')}
                difficulty={i18n('common.findABook.harder')}
            />
            <img src={quantileArt} alt="" role="presentation" />
            <span className="student-measure">900Q</span>
        </div>
    </>
))`
    ${quantileLayout}
`;
