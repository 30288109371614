import styled from 'styled-components';

const BrandedCard = styled.div`
    display: inline-block;
    padding: 8px 16px 16px;
    border-top: 5px solid
        ${({ brand }) =>
            `var(--theme-brand, ${
                brand === 'lexile'
                    ? 'var(--theme-green-lexile)'
                    : 'var(--theme-blue-quantile)'
            })`};
    background-color: var(--theme-white);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
`;

export default BrandedCard;
