import { isNegative } from '@utilities/predicates';
import { take, takeAfter } from './subsets_arrays';
import { concat } from './merges_arrays';

export const filter = predicate => array =>
    array ? array.filter(predicate) : array;

/**
 * Remove a value or an index from an array.
 * If index of the value to remove is already known,
 * provide it so as to not perform another search through the array
 */
export const remove = (array = [], value, index) => {
    index = isNaN(index) ? array.indexOf(value) : index;
    if (isNegative(index)) {
        return array;
    }

    return concat(take(index)(array), takeAfter(index + 1)(array));
};

export const removeAll = (elements = []) => (targetArray = []) => {
    const targetSet = new Set(targetArray);
    elements.forEach(el => targetSet.delete(el));
    return [...targetSet];
};
