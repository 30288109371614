import React from 'react';
import styled, { css } from 'styled-components';
import NotFoundImage from '~/images/404page.png';
import { i18n } from '@i18n/format';
import Button from '@ui/actions/Button';
import routes from '@constants/routes';
import { pushTo } from '@utilities/navigation';

const layout = css`
    display: flex;
    align-items: center;
    padding-top: 80px;
    flex-direction: column;

    h2 {
        margin: 12px 0;
    }

    ${Button} {
        margin-top: 32px;
    }
`;

const NotFound = ({ className }) => (
    <div className={className}>
        <img src={NotFoundImage} alt="" />
        <h2>{i18n('common.sorry')}</h2>
        <span>{i18n('common.pageNotFound')}</span>
        <Button $studentFacing onClick={() => pushTo(routes.HOME_PAGE)}>
            {i18n('common.home')}
        </Button>
    </div>
);

export default styled(NotFound)`
    ${layout}
`;
