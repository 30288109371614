import React from 'react';

const Profile = ({ className }) => (
    <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8425 5.6125C14.8425 8.57413 12.6744 10.975 10 10.975C7.32556 10.975 5.1575 8.57413 5.1575 5.6125C5.1575 2.65087 7.32556 0.25 10 0.25C12.6744 0.25 14.8425 2.65087 14.8425 5.6125ZM19.75 17.8V16.89C19.75 14.5175 16.9875 13.0875 14.3875 11.9825C14.29 11.9175 14.225 11.885 14.1275 11.8525C13.9325 11.755 13.705 11.755 13.51 11.885C12.47 12.535 11.2675 12.925 10 12.925C8.7325 12.925 7.53 12.535 6.49 11.8525C6.295 11.7225 6.0675 11.7225 5.8725 11.82C5.775 11.885 5.71 11.9175 5.6125 11.95C3.0125 13.0875 0.25 14.5175 0.25 16.89V17.8C0.25 18.8725 1.1275 19.75 2.2 19.75H17.8C18.8725 19.75 19.75 18.8725 19.75 17.8Z"
            fill="white"
        />
    </svg>
);

export default Profile;
