import React from 'react';

const MMMIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 15"
            aria-hidden="true"
        >
            <path d="M2.69 15c-.41 0-.73-.19-.73-.43V.43C2 .19 2.28 0 2.69 0s.73.19.73.43v14.14c0 .24-.33.43-.73.43z" />
            <path d="M15.58 13.13H.42c-.23 0-.42-.34-.42-.75s.19-.75.42-.75h15.16c.23 0 .42.33.42.75s-.19.75-.42.75zM12.41 7.75L10.8 6l1.69-1.65a.74.74 0 0 0 0-1 .71.71 0 0 0-1 0L9.8 5 8.19 3.23A.73.73 0 0 0 7.68 3a.73.73 0 0 0-.54 1.25L8.76 6 7.07 7.63a.76.76 0 0 0-.23.52.73.73 0 0 0 .21.52.52.52 0 0 0 .11.1.71.71 0 0 0 .91-.07l1.69-1.65 1.61 1.72a.52.52 0 0 0 .11.1.7.7 0 0 0 .91-.08.76.76 0 0 0 .23-.51.74.74 0 0 0-.21-.53z" />
        </svg>
    );
};

export default MMMIcon;
