import React from 'react';
import styled from 'styled-components';
import { whenProvided } from 'donut-ui';
import { emojiEntity, containsEmojiEntity } from '@utilities/dataTypes/strings';

const emojiSymbols = {
    '&#10084;&#65039;': {
        symbol: '❤️',
        label: 'heart',
        color: 'var(--theme-red)'
    }
};

export const parseEmojiCode = str => {
    if (!containsEmojiEntity(str)) {
        return str;
    }
    const [, pre, emoji, post] = str.match(emojiEntity);

    return (
        <span>
            {pre}
            <Emoji {...emojiSymbols[emoji]} />
            {post}
        </span>
    );
};

const Emoji = ({ className, symbol, label }) => {
    return (
        <span className={className} aria-label={label}>
            {symbol}
        </span>
    );
};

export default styled(Emoji)`
    ${whenProvided('color')`
        color: ${props => props.color};
    `}
`;
