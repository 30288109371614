import {
    logoutTriggerStorageKey,
    sessionStorageKeyPrefix
} from '@constants/session';
import { getItem, setItem } from '@utilities/localStorage';

export const isHubSessionEvent = event =>
    event.key && event.key.startsWith(sessionStorageKeyPrefix);

export const getLogoutTrigger = () => getItem(logoutTriggerStorageKey);

export const setLogoutTrigger = trigger =>
    setItem(logoutTriggerStorageKey, trigger);
