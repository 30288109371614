import React from 'react';

const TeacherAssistantIcon = props => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 15"
            aria-hidden="true"
        >
            <path d="M10.23 4.64c.43 0 .67.21 1.06.57a3.19 3.19 0 0 0 .87.63 1.56 1.56 0 0 0 .57.11 2.86 2.86 0 0 0 1-.27 2.39 2.39 0 0 1 .91-.25 1.48 1.48 0 0 1 .49.1c1 .4 1.2 1.79 1 3-.37 2.11-2 4.3-4 4.3a2.76 2.76 0 0 1-.63-.07c-3-.63-3.24-3.58-3.24-4.47 0-1.93.85-3.44 1.59-3.58a1.22 1.22 0 0 1 .38-.07zm0-.76a2.53 2.53 0 0 0-.46 0c-2.58.51-3.79 8.42 1.64 9.56a4.32 4.32 0 0 0 .79.08c4.27 0 6.51-7.49 3.24-8.75a2.13 2.13 0 0 0-.74-.14c-.83 0-1.43.52-2 .52a.75.75 0 0 1-.29-.06c-.62-.23-1.03-1.21-2.18-1.21zM12.53 3.66A3.74 3.74 0 0 1 8.12.27s3.35-1.68 4.41 3.39z" />
            <path d="M8.13 8.48A3.59 3.59 0 0 0 9.6 5.37 3.43 3.43 0 0 0 6.5 2 3.39 3.39 0 0 0 3 5.53a3.59 3.59 0 0 0 1.47 3 6.73 6.73 0 0 0-4.5 6 .46.46 0 0 0 .48.47h11.73a.46.46 0 0 0 .45-.51 6.73 6.73 0 0 0-4.5-6.01zM12.77 4.61a.38.38 0 0 1-.36-.31 2.66 2.66 0 0 1 1.28-2.78.37.37 0 0 1 .51.14.39.39 0 0 1-.15.52 1.9 1.9 0 0 0-.91 2 .39.39 0 0 1-.29.45z" />
        </svg>
    );
};

export default TeacherAssistantIcon;
