import React from 'react';
import styled from 'styled-components';
import { i18n } from '@i18n/format';
import { whenProvided } from '@utilities/stylingUtils';
import CheckmarkIcon from '@ui/icons/CheckmarkIcon';
import LinkButton from '@ui/actions/LinkButton';

export const BaseOption = styled.li`
    position: relative;
    cursor: pointer;
    padding: 10px 36px;
    font-size: 0.875rem;

    ${whenProvided('highlighted')`
        background-color: var(--theme-gray4);
    `}
    ${whenProvided('disabled')`
        color: var(--theme-warm-black2);
        cursor: default;
    `}
`;

const NoResults = styled.li`
    cursor: default;
    padding: 10px 36px;
    font-size: 0.875rem;
    font-style: italic;
    color: var(--theme-gray3);
`;

export const NoResultsFound = ({ message, ...props }) => (
    <NoResults {...props}>
        {message ? message : i18n('common.noResultsFound')}
    </NoResults>
);

const SelectionControlOption = styled(BaseOption)`
    display: flex;
    justify-content: flex-end;
    cursor: default;
    padding: 10px 12px 10px 36px;
`;

const CheckmarkContainer = styled.span`
    position: absolute;
    left: 12px;
    color: var(--theme-blue4);
`;
export const SelectableOption = React.forwardRef((props, ref) => {
    const dataTestId = props['data-testid'];
    const {
        children,
        highlighted,
        selected,
        innerRef,
        onClick,
        onMouseMove,
        role,
        id,
        disabled
    } = props;

    return (
        <BaseOption
            aria-activedescendant={highlighted}
            highlighted={highlighted}
            onClick={!disabled ? onClick : undefined}
            onMouseMove={onMouseMove}
            role={role}
            aria-selected={selected ? true : false}
            data-testid={dataTestId}
            id={id}
            ref={ref || innerRef}
            disabled={disabled}
            aria-disabled={disabled}
        >
            {selected && (
                <CheckmarkContainer>
                    <CheckmarkIcon
                        ariaHidden="true"
                        width="15px"
                        title={i18n('common.optionSelected')}
                    />
                </CheckmarkContainer>
            )}
            <span>{children}</span>
        </BaseOption>
    );
});

export const ClearSelection = ({ onClick, translatedToEs }) => {
    return (
        <SelectionControlOption role="option">
            <LinkButton onClick={onClick} tabIndex="-1">
                {i18n(
                    translatedToEs
                        ? 'common.findABook.clearSelections'
                        : 'common.clearSelections'
                )}
            </LinkButton>
        </SelectionControlOption>
    );
};
