import React from 'react';

const ChevronUp = props => (
    <svg viewBox="0 0 13 8" fill="none" {...props}>
        <path
            d="M6.52455 7.30469C6.62277 7.30469 6.73326 7.25558 6.80692 7.18192L12.5279 1.46094C12.6016 1.38728 12.6507 1.27679 12.6507 1.17857C12.6507 1.08036 12.6016 0.969866 12.5279 0.896205L11.9141 0.282366C11.8404 0.208705 11.7299 0.159598 11.6317 0.159598C11.5335 0.159598 11.423 0.208705 11.3493 0.282366L6.52455 5.10714L1.69978 0.282366C1.62612 0.208705 1.51562 0.159598 1.41741 0.159598C1.30692 0.159598 1.20871 0.208705 1.13504 0.282366L0.521205 0.896205C0.447545 0.969866 0.398438 1.08036 0.398438 1.17857C0.398438 1.27679 0.447545 1.38728 0.521205 1.46094L6.24219 7.18192C6.31585 7.25558 6.42634 7.30469 6.52455 7.30469Z"
            fill="currentColor"
        />
    </svg>
);

export default ChevronUp;
