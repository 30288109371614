import { API as api } from '@aws-amplify/api';
import { jwt, userInfo } from './session';
import { isEmpty } from '@utilities/dataTypes/objects';
import { contains } from '@utilities/dataTypes/arrays';

const formatParams = key => params => {
    if (!params) {
        return;
    }

    if (typeof params === 'function') {
        return params();
    }

    return {
        [key]: params
    };
};

const asQueryString = formatParams('queryStringParameters');
const asBody = formatParams('body');

// Regex for getting fileName in Content-Disposition header:
// response: {'Content-Disposition': 'attachment; filename="huehuehue.gif"'}
// getDispositionHeaderFileName('attachment; filename="huehuehue.gif"') => huehuehue.gif
const getDispositionHeaderFileName = dispositionHeader => {
    const [, fileName] = dispositionHeader.match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i
    );
    return fileName;
};

const responseFileName = request => {
    const contentDispositionHeader = request.getResponseHeader(
        'Content-Disposition'
    );
    return (
        contentDispositionHeader &&
        getDispositionHeaderFileName(contentDispositionHeader)
    );
};

export const makeEndpoint = (endpointName, endpointURL) => ({
    configure: customHeader => ({
        name: endpointName,
        endpoint: endpointURL,
        custom_header: customHeader
            ? async () => {
                  const token = await jwt();
                  return {
                      ...customHeader(),
                      Authorization: token
                  };
              }
            : async () => {
                  const token = await jwt();
                  return {
                      Authorization: token,
                      Accept: 'application/json; version=1.0',
                      'Content-Type': 'application/json; version=1.0'
                  };
              }
    }),
    get: (route, params) => {
        return api.get(endpointName, route, asQueryString(params));
    },
    post: (route, params) => {
        return api.post(endpointName, route, asBody(params));
    },
    patch: (route, params) => {
        return api.patch(endpointName, route, asBody(params));
    },
    del: (route, params) => {
        return api.del(endpointName, route, asBody(params));
    },
    download: async (route, { format, fileName }, method = 'GET', body) => {
        const token = await jwt();
        //prettier-ignore
        return new Promise(function(resolve, reject) {
            var oReq = new XMLHttpRequest();
            oReq.open(method, `${endpointURL}${route}`, true);
            oReq.responseType = 'blob';
            oReq.setRequestHeader('Accept', format);
            oReq.setRequestHeader('Authorization', token);
            method === 'POST' &&
                oReq.setRequestHeader(
                    'Content-Type',
                    'application/json; version=1.0'
                );

            oReq.addEventListener('load', () => {
                // If backend allows us to get content disposition header,
                // look for filename there, otherwise use fileName param
                // 'Content-Disposition' header must be exposed by backend via CORS settings
                var blob = oReq.response;
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = responseFileName(oReq) || fileName;
                a.dispatchEvent(new MouseEvent('click'));
                resolve();
            });
            oReq.addEventListener('error', reject);
            oReq.addEventListener('abort', () => resolve());
            oReq.send(JSON.stringify(body));
        });
    }
});

const tier = user => {
    if (isEmpty(user)) {
        return 'free';
    }
    if (
        !contains(user.groups, 'FreeTrial') &&
        !contains(user.groups, 'StatePro') &&
        !contains(user.groups, 'Pro')
    ) {
        return 'basic';
    }
    return 'pro';
};

export const tiered = endpoint => (route, ...args) =>
    userInfo().then(user => endpoint(`/${tier(user)}${route}`, ...args));

export const versioned = (version = '1.0') => endpoint => (route, params) =>
    endpoint(route, () => {
        return {
            headers: {
                Accept: `application/json; version=${version}`,
                'Content-Type': `application/json; version=${version}`
            },
            ...params
        };
    });
