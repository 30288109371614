import { makeEndpoint } from './httpRequest';
import { isEmptyArray } from '@utilities/dataTypes/arrays';
import createStaticResource from '@utilities/staticResources';

const NAME = process.env.REACT_APP_NORMS_NAME;
const ENDPOINT = process.env.REACT_APP_NORMS_URL;

const normsApi = makeEndpoint(NAME, ENDPOINT);
export const gradeLevelChartsRoot = normsApi.configure();

export const lexileNorms = createStaticResource(() =>
    normsApi.get('/norms', {
        scale: 'lexile'
    })
);

export const quantileNorms = createStaticResource(() =>
    normsApi.get('/norms', {
        scale: 'quantile'
    })
);

const arrayQueryString = (name, array) =>
    isEmptyArray(array)
        ? ''
        : array.reduce(
              (queryStr, { value }) => `${queryStr}&${name}=${value}`,
              ''
          );

export const downloadChart = (scale, { periods, grades, range }) => {
    const percentiles = `&percentileStart=${range[0]}&percentileEnd=${range[1]}`;
    const periodQuery = arrayQueryString('periods', periods);
    const gradeQuery = arrayQueryString('grades', grades);

    return normsApi.download(
        `/norms?scale=${scale}${percentiles}${periodQuery}${gradeQuery}`,
        {
            format: 'application/pdf; version=1.0',
            fileName: 'gradeLevelCharts.pdf'
        }
    );
};
