import React from 'react';
import styled from 'styled-components';
import { visuallyHidden } from 'donut-ui';
import { i18n } from '@i18n/format';
import { TOOL_AUDIENCES, toolsByToolName } from '@constants/tools';
import FindABookToolCardLogo from '~/images/Lexile/LexileFindABook_Icon.png';
import DecodablePassagesLogo from '~/images/K-2/DecodablePassages_Icon_new.png';
import FADBToolCardLogo from '~/images/K-2/FADBToolCardIcon_new.png';
import LexileGrowthPlannerLogo from '~/images/Lexile/LexileGrowthPlanner_Icon.png';
import QuantileGrowthPlannerLogo from '~/images/Quantile/QuantileGrowthPlanner_Icon.png';
import TextAnalyzerLogo from '~/images/Lexile/LexileAnalyzerEnglish_Icon.png';
import LexileCareerDBLogo from '~/images/Lexile/LexileCareerDatabase_Icon.png';
import QuantileCareerDBLogo from '~/images/Quantile/QuantileCareerDatabase_Icon.png';
import LexileMMMLogo from '~/images/Lexile/LexileMeasuresManager_Icon.png';
import QuantileMMMLogo from '~/images/Quantile/QuantileMeasuresManager_Icon.png';
import WordListsLogo from '~/images/Lexile/LexileWordlists_Icon.png';
import MathSkillsDBLogo from '~/images/Quantile/QuantileMathSkillsDatabase_Icon.png';
import TeacherAssistantLogo from '~/images/Quantile/QuantileTeacherAssistant_Icon.png';
import MathAtHomeLogo from '~/images/Quantile/QuantileMathAtHome_Icon.png';
import LexileGradeLevelChartsLogo from '~/images/Lexile/LexileGradeLevelCharts_Icon.png';
import QuantileGradeLevelChartsLogo from '~/images/Quantile/QuantileGradeLevelCharts_Icon.png';
import HomeCarousel from './HomeCarousel';
import ToolList from './ToolList';
import ToolCard from './ToolCard';
import ResourceCard from './ResourceCard';
import ProfessionalLearning from './ProfessionalLearning';
import FamilyIntroduction from './FamilyIntroduction';
import { useToolFilter } from './toolTypeFilters';

const Layout = styled.div`
    margin-bottom: 32px;

    h1 {
        ${visuallyHidden};
    }

    section {
        margin: 2rem 3rem;
    }

    ${ProfessionalLearning} {
        margin: 0;
    }

    ${HomeCarousel} {
        margin: 2rem auto;
    }
`;

const AllTools = () => {
    const [filter] = useToolFilter();
    const parentTools = filter === TOOL_AUDIENCES.PARENTS;

    return (
        <Layout>
            <h1>{i18n('tools.home.all')}</h1>
            {!parentTools && <HomeCarousel />}
            {parentTools && <FamilyIntroduction />}
            <ToolList>
                <ToolCard
                    tool={toolsByToolName.findABook}
                    logo={FindABookToolCardLogo}
                    audience="forAll"
                    description={{
                        educators: i18n('findABook.descriptionEducators'),
                        parents: i18n('findABook.descriptionParents')
                    }}
                />
                <ToolCard
                    tool={toolsByToolName.findADecodableBook}
                    logo={FADBToolCardLogo}
                    audience="forEducatorsResearchers"
                    description={i18n('findADecodableBook.toolCardDescription')}
                />
                <ToolCard
                    tool={toolsByToolName.decodablePassages}
                    logo={DecodablePassagesLogo}
                    audience="forEducators"
                    description={i18n('decodablePassages.description')}
                />
                <ResourceCard
                    tool={toolsByToolName.phraseList}
                    title={i18n('phraseList.title')}
                    description={i18n('phraseList.description')}
                    newBanner
                />
                <ToolCard
                    tool={toolsByToolName.lexileNorms}
                    logo={LexileGradeLevelChartsLogo}
                    audience="forEducators"
                    description={i18n('norms.description')}
                />
                <ToolCard
                    tool={toolsByToolName.textAnalyzer}
                    logo={TextAnalyzerLogo}
                    audience="forEducators"
                    description={i18n('textAnalyzer.descriptionEducators')}
                />
                <ToolCard
                    tool={toolsByToolName.growthPlanner}
                    logo={LexileGrowthPlannerLogo}
                    audience="forEducatorsStudents"
                    description={i18n('growthPlanner.descriptionEducators')}
                />
                <ToolCard
                    tool={toolsByToolName.careerDatabaseReading}
                    logo={LexileCareerDBLogo}
                    audience="forEducators"
                    description={i18n('careerDB.descriptionEducatorsReading')}
                />
                <ToolCard
                    tool={toolsByToolName.lexileWordList}
                    logo={WordListsLogo}
                    audience="forEducators"
                    description={i18n('wordList.descriptionEducators')}
                />
                <ToolCard
                    tool={toolsByToolName.managingMultipleMeasures}
                    logo={LexileMMMLogo}
                    audience="forEducators"
                    description={i18n('mmm.descriptionEducators')}
                />
                <ToolCard
                    tool={toolsByToolName.quantileTeacherAssistant}
                    logo={TeacherAssistantLogo}
                    audience="forEducators"
                    description={i18n(
                        'quantileTeacherAssistant.descriptionEducators'
                    )}
                />
                <ToolCard
                    tool={toolsByToolName.quantileNorms}
                    logo={QuantileGradeLevelChartsLogo}
                    audience="forEducators"
                    description={i18n('norms.descriptionQuantile')}
                />
                <ToolCard
                    tool={toolsByToolName.mathAtHome}
                    logo={MathAtHomeLogo}
                    audience="forParentsStudents"
                    description={{
                        parents: i18n('mathAtHome.descriptionParents'),
                        students: i18n('mathAtHome.descriptionStudents')
                    }}
                />
                <ToolCard
                    tool={toolsByToolName.mathSkillsDatabase}
                    logo={MathSkillsDBLogo}
                    audience="forEducators"
                    description={i18n('mathSkillsDB.descriptionEducators')}
                />
                <ToolCard
                    tool={toolsByToolName.growthPlannerMath}
                    logo={QuantileGrowthPlannerLogo}
                    audience="forEducatorsStudents"
                    description={i18n('growthPlannerMath.descriptionEducators')}
                />
                <ToolCard
                    tool={toolsByToolName.careerDatabaseMath}
                    logo={QuantileCareerDBLogo}
                    audience="forEducators"
                    description={i18n('careerDB.descriptionEducatorsMath')}
                />
                <ToolCard
                    tool={toolsByToolName.managingMultipleMeasuresMath}
                    logo={QuantileMMMLogo}
                    audience="forEducators"
                    description={i18n('mmm.descriptionEducatorsMath')}
                />
            </ToolList>
            <ProfessionalLearning />
        </Layout>
    );
};

export default AllTools;
