import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import GlobalStyle from './GlobalStyle';
import MainLayout from '@ui/navigation/MainLayout';
import Loader from '@ui/Loader';
import UserAlert from '@ui/alerts/UserAlert';
import Tier, { IdentityProvider, tiers } from '@ui/layouts/Tier';
import { ssoProvidersArray } from '@constants/identityProviders';
import routes from '@constants/routes';
import SEO from '@features/global/SEO';
import SSOAcceptTermsDialog from '@features/global/SSOAcceptTermsDialog';
import { loadingManager } from '@features/global/LoadingManager';
import { resourceCenter } from '@features/quantile/ResourceStore';
import UrgentMessages from '@features/global/urgentMessages/UrgentMessages';
import CookieConsent from '@features/global/CookieConsent';
import { UserProvider } from '@utilities/providers/userProvider';
import { initTracking } from '@utilities/analytics/agent';
import { i18n } from '@i18n/format';

const App = () => (
    <UserProvider>
        <Provider
            loadingManager={loadingManager}
            resourceCenter={resourceCenter}
        >
            <React.Fragment>
                <GlobalStyle />
                <CookieConsent onSave={initTracking} />
                <Tier level={[tiers.BASIC, tiers.PRO]}>
                    <UrgentMessages />
                </Tier>
                <IdentityProvider provider={ssoProvidersArray}>
                    <SSOAcceptTermsDialog />
                </IdentityProvider>
                <SEO>
                    <meta name="description" content={i18n('seo.hub')} />
                </SEO>
                <UserAlert />
                <Loader />
                <Switch>
                    <Route
                        path={routes.HOME_PAGE}
                        render={() => <MainLayout />}
                    />
                </Switch>
            </React.Fragment>
        </Provider>
    </UserProvider>
);

export default App;
