import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import baseRoutes from '@constants/routes';
import { TOOL_AUDIENCES } from '~/constants/tools';
import { useToolFilter } from './toolTypeFilters';
import { redirectedFromFamilyLink } from '@utilities/analytics/analyticsEvents';

const FamilyHomeRedirect = () => {
    const [, setFilter] = useToolFilter();
    useEffect(() => {
        setFilter(TOOL_AUDIENCES.PARENTS);
        redirectedFromFamilyLink();
    });

    return <Redirect to={baseRoutes.HOME_PAGE} />;
};

export default FamilyHomeRedirect;
