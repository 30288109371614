import React from 'react';
import styled from 'styled-components';

const PlusIcon = ({ ariaLabel, className, focusable, role, title }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-label={ariaLabel}
            role={role}
            focusable={focusable}
        >
            {title && <title>{title}</title>}
            <path
                d="M6.35911 6.1682h3.67499c.1432 0 .3341-.14318.3341-.33409v-.66818c0-.19091-.1909-.33409-.3341-.33409H6.35911c-.09546 0-.19091-.09546-.19091-.19091V.965927c0-.143182-.14318-.334091-.33409-.334091h-.66818c-.19091 0-.33409.190909-.33409.334091V4.64093c0 .09545-.09546.19091-.19091.19091H.965927c-.143182 0-.334091.14318-.334091.33409v.66818c0 .19091.190909.33409.334091.33409H4.64093c.09545 0 .19091.09545.19091.19091v3.67499c0 .1432.14318.3341.33409.3341h.66818c.19091 0 .33409-.1909.33409-.3341V6.35911c0-.09546.09545-.19091.19091-.19091z"
                fill="currentColor"
            />
        </svg>
    );
};

export default styled(PlusIcon)``;
