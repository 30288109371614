import { propEquals } from '@utilities/dataTypes/objects';
import baseRoutes from '@constants/routes';
import { routes } from '@features/dashboard/account/accountRoutes';
import { i18n } from '@i18n/format';
import { indexBy, prop } from 'ramda';

export const TOOL_DOMAIN = {
    LEXILE: 'Lexile',
    QUANTILE: 'Quantile',
    K2: 'K2'
};

export const TOOL_AUDIENCES = {
    ALL: 'all',
    EDUCATORS: 'educators',
    PARENTS: 'parents',
    RESEARCHERS: 'researchers',
    STUDENTS: 'students'
};

export const findABook = {
    id: 'findABook',
    toolName: 'Find a Book',
    path: baseRoutes.FIND_A_BOOK,
    domain: TOOL_DOMAIN.LEXILE
};

export const findADecodableBook = {
    id: 'findADecodableBook',
    toolName: i18n('findADecodableBook'),
    path: baseRoutes.FIND_A_DECODABLE_BOOK,
    domain: TOOL_DOMAIN.K2
};

export const decodablePassages = {
    id: 'decodablePassages',
    toolName: i18n('decodablePassages.title'),
    path: baseRoutes.DECODABLE_PASSAGES,
    domain: TOOL_DOMAIN.K2
};

const phraseList = {
    id: 'phraseList',
    domain: TOOL_DOMAIN.K2
};

export const growthPlanner = {
    id: 'growthPlanner',
    toolName: 'Growth Planner',
    path: baseRoutes.GROWTH_PLANNER_LEXILE,
    domain: TOOL_DOMAIN.LEXILE
};

export const growthPlannerMath = {
    id: 'growthPlannerMath',
    toolName: 'Growth Planner',
    path: baseRoutes.GROWTH_PLANNER_QUANTILE,
    domain: TOOL_DOMAIN.QUANTILE
};

export const textAnalyzer = {
    id: 'textAnalyzer',
    toolName: 'Lexile® Text Analyzer',
    shortName: 'Text Analyzer',
    path: baseRoutes.TEXT_ANALYZER,
    domain: TOOL_DOMAIN.LEXILE
};

export const lexileNorms = {
    id: 'lexileNorms',
    toolName: 'Lexile® Grade Level Charts',
    shortName: 'Grade Level Charts',
    path: baseRoutes.NORMS_LEXILE,
    domain: TOOL_DOMAIN.LEXILE
};

export const quantileNorms = {
    id: 'quantileNorms',
    toolName: 'Quantile® Grade Level Charts',
    shortName: 'Grade Level Charts',
    path: baseRoutes.NORMS_QUANTILE,
    domain: TOOL_DOMAIN.QUANTILE
};

export const careerDatabaseReading = {
    id: 'careerDatabaseReading',
    toolName: 'Lexile® Career Database',
    shortName: 'Career Database',
    path: baseRoutes.CAREER_DATABASE_LEXILE,
    domain: TOOL_DOMAIN.LEXILE
};

export const careerDatabaseMath = {
    id: 'careerDatabaseMath',
    toolName: 'Quantile® Career Database',
    shortName: 'Career Database',
    path: baseRoutes.CAREER_DATABASE_QUANTILE,
    domain: TOOL_DOMAIN.QUANTILE
};

export const managingMultipleMeasures = {
    id: 'managingMultipleMeasures',
    toolName: 'Lexile® Measures Manager',
    shortName: 'Measures Manager',
    path: baseRoutes.MMM_LEXILE,
    domain: TOOL_DOMAIN.LEXILE
};

export const managingMultipleMeasuresMath = {
    id: 'managingMultipleMeasuresMath',
    toolName: 'Quantile® Measures Manager',
    shortName: 'Measures Manager',
    path: baseRoutes.MMM_QUANTILE,
    domain: TOOL_DOMAIN.QUANTILE
};

export const lexileWordList = {
    id: 'lexileWordList',
    toolName: 'WordLists',
    path: baseRoutes.WORDLISTS,
    domain: TOOL_DOMAIN.LEXILE
};

export const mathSkillsDatabase = {
    id: 'mathSkillsDatabase',
    toolName: 'Math Skills Database',
    path: baseRoutes.MATH_SKILLS_DATABASE,
    domain: TOOL_DOMAIN.QUANTILE
};

export const quantileTeacherAssistant = {
    id: 'quantileTeacherAssistant',
    toolName: i18n('quantileTeacherAssistant.productName'),
    shortName: i18n('quantileTeacherAssistant.shortProductName'),
    path: baseRoutes.QUANTILE_TEACHER_ASSISTANT,
    domain: TOOL_DOMAIN.QUANTILE
};

export const mathAtHome = {
    id: 'mathAtHome',
    toolName: 'Math@Home',
    path: baseRoutes.MATH_AT_HOME,
    domain: TOOL_DOMAIN.QUANTILE
};

const billing = {
    id: 'billing',
    path: baseRoutes.BILLING
};

const mailingLists = {
    id: 'mailingLists',
    path: routes.MAILING_LISTS
};

const membershipOptions = {
    id: 'membershipOptions',
    path: baseRoutes.MEMBERSHIP_OPTIONS
};

const membershipPlan = {
    id: 'membershipPlan',
    path: routes.MEMBERSHIP_PLAN
};

export const toolList = [
    findABook,
    findADecodableBook,
    decodablePassages,
    lexileNorms,
    textAnalyzer,
    growthPlanner,
    careerDatabaseReading,
    lexileWordList,
    managingMultipleMeasures,
    quantileTeacherAssistant,
    quantileNorms,
    mathAtHome,
    mathSkillsDatabase,
    growthPlannerMath,
    careerDatabaseMath,
    managingMultipleMeasuresMath,
    phraseList
];

export const lexileTools = toolList.filter(
    propEquals('domain', TOOL_DOMAIN.LEXILE)
);
export const quantileTools = toolList.filter(
    propEquals('domain', TOOL_DOMAIN.QUANTILE)
);

export const k2Tools = toolList.filter(propEquals('domain', TOOL_DOMAIN.K2));

export const toolsByAudience = {
    [TOOL_AUDIENCES.EDUCATORS]: {
        description: i18n('tools.toolsForEducators'),
        tools: [
            findABook,
            findADecodableBook,
            decodablePassages,
            lexileNorms,
            textAnalyzer,
            growthPlanner,
            careerDatabaseReading,
            lexileWordList,
            managingMultipleMeasures,
            quantileTeacherAssistant,
            quantileNorms,
            mathSkillsDatabase,
            growthPlannerMath,
            careerDatabaseMath,
            managingMultipleMeasuresMath
        ]
    },
    [TOOL_AUDIENCES.PARENTS]: {
        description: i18n('tools.home.toolsForParents'),
        tools: [findABook, mathAtHome]
    },
    [TOOL_AUDIENCES.STUDENTS]: {
        description: i18n('tools.toolsForStudents'),
        tools: [findABook, growthPlanner, mathAtHome, growthPlannerMath]
    },
    [TOOL_AUDIENCES.RESEARCHERS]: {
        description: i18n('tools.toolsForResearchers'),
        tools: [
            findABook,
            findADecodableBook,
            lexileNorms,
            textAnalyzer,
            growthPlanner,
            careerDatabaseReading,
            lexileWordList,
            quantileNorms,
            mathSkillsDatabase,
            growthPlannerMath,
            careerDatabaseMath
        ]
    }
};

export const accountTools = [
    billing,
    mailingLists,
    membershipOptions,
    membershipPlan
];

export const toolsByToolName = indexBy(prop('id'), [
    ...toolList,
    ...accountTools
]);
