/** These are fieldnames in the HTML autofill spec. These fields require us to pass a random string
 * to disable autofill instead of the standard `autoComplete='off'`. For more info on the autofill spec,
 * visit https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill-field
 */
export const autofillFieldnames = new Set([
    'address1',
    'address2',
    'city',
    'zip',
    'state',
    'country'
]);
