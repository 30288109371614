import { id } from './functions';
import { on } from '@utilities/dataTypes/functions';

export const mapRange = (start, end, step, fn) => {
    const result = [];

    for (let i = start; i < end; i += step) {
        result.push(fn(i, i / step));
    }

    return result;
};

export const valueToRange = (
    tStart = id,
    tEnd = id,
    { startLabel = 'gte', endLabel = 'lte' } = {}
) => value => ({
    [startLabel]: tStart(value),
    [endLabel]: tEnd(value)
});

export const rangeToText = start => end => `${start} - ${end}`;

export const onRange = on(rangeToText);
